import React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { CompaniesTable } from "./CompaniesTable";
import { getAllCompanies, deleteCompany, editCompany, addCompany } from "../../../actions/companies";
import CompaniesModal from "./CompaniesModal";
import { actionButtonsContainer, buttonStyle } from "../../Common/Styles";

class CompaniesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      showModal: false,
      currentCompany: null,
    };

    this.deleteCompany = this.deleteCompany.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.editCompany = this.editCompany.bind(this);
  }

  handleClick = function () {
    this.setState({
      showModal: !this.state.showModal,
      currentCompany: null,
    });
  };

  handleSave = function (isEdit, newCompany) {
    if (isEdit) {
      //adding _id
      newCompany._id = this.state.currentCompany._id;

      this.props.editCompany(newCompany).then(() => {
        this.setState({
          showModal: !this.state.showModal,
        });
      });
    } else {
      const comp = {
        legalName: newCompany.legalName,
        createdDate: Date.now(),
      };
      this.props.addCompany(comp).then((res) => {
        this.setState({
          showModal: !this.state.showModal,
        });
      });
    }
  };

  deleteCompany = function (params) {
    this.props.deleteCompany(params);
  };

  editCompany = function (currentCompany) {
    this.setState({ currentCompany, showModal: true });
  };

  componentDidMount() {
    this.props.getAllCompanies();
  }

  render() {
    return (
      <div className="companies-view">
        <div style={actionButtonsContainer}>
          <Button style={buttonStyle} variant="contained" onClick={this.handleClick}>
            Add Company
          </Button>
          <Button style={buttonStyle} onClick={this.exportToExcel} variant="contained">
            Export to Excel
          </Button>
          <CompaniesTable companies={this.props.companies} editCompany={this.editCompany} deleteCompany={this.deleteCompany} />
          <CompaniesModal
            fromState={this.state}
            showModal={this.state.showModal}
            handleSave={this.handleSave}
            handleClick={this.handleClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: state.companies.companyList,
});

export default connect(mapStateToProps, { getAllCompanies, deleteCompany, editCompany, addCompany })(CompaniesView);
