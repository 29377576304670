import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import companyReducer from "./companyReducer";
import customerReducer from "./customerReducer";
import helperReducer from "./helperReducer";
import objectReducer from "./objectReducer";
import contractsReducer from "./contractsReducer";
import settlementsReducer from "./settlementsReducer";
import modalContentReducer from "./modalContentReducer";
import tasksReducer from "./taskReducer";
import fileReducer from "./fileReducer";

export default combineReducers({
  errors: errorReducer,
  auth: authReducer,
  users: userReducer,
  companies: companyReducer,
  customers: customerReducer,
  helpers: helperReducer,
  objects: objectReducer,
  tasks: tasksReducer,
  contracts: contractsReducer,
  settlements: settlementsReducer,
  modals: modalContentReducer,
  files: fileReducer,
});
