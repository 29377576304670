import React from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";

const UsersModal = props => {
  let user = { ...props.fromState.currentUser };
  let newUser = {};

  return (
    <Modal dialogClassName="modal-90w" show={props.showModal} onHide={props.handleClick}>
      <Modal.Header closeButton>
        <Modal.Title>{user.username ? "Edit " + user.username : "Add new user"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} controlId="username">
            <Form.Label column sm="2">
              Username
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                onChange={e => (newUser[e.target.id] = e.target.value)}
                defaultValue={user.username ? user.username : ""}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="email">
            <Form.Label column sm="2">
              Email
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="email"
                onChange={e => (newUser[e.target.id] = e.target.value)}
                defaultValue={user.email ? user.email : ""}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="mobilePhone">
            <Form.Label column sm="2">
              Mobile Phone
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                onChange={e => (newUser[e.target.id] = e.target.value)}
                defaultValue={user.mobilePhone ? user.mobilePhone : ""}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="legalName">
            <Form.Label column sm="2">
              Legal Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                onChange={e => (newUser[e.target.id] = e.target.value)}
                defaultValue={user.legalName ? user.legalName : ""}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="firstName">
            <Form.Label column sm="2">
              First Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                onChange={e => (newUser[e.target.id] = e.target.value)}
                defaultValue={user.firstName ? user.firstName : ""}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="lastName">
            <Form.Label column sm="2">
              Last Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                onChange={e => (newUser[e.target.id] = e.target.value)}
                defaultValue={user.lastName ? user.lastName : ""}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="customerId">
            <Form.Label column sm="2">
              Company ID
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                onChange={e => (newUser[e.target.id] = e.target.value)}
                defaultValue={user.customerId ? user.customerId : ""}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="hash">
            <Form.Label column sm="2">
              {user.username ? "Reset " : ""}Password
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" onChange={e => (newUser[e.target.id] = e.target.value)} defaultValue="" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="role">
            <Form.Label column sm="2">
              Role
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="select"
                onChange={e => {
                  newUser[e.target.id] = e.target.value;
                }}
                defaultValue={user.username ? user.role : ""}
              >
                <option value="client">Client</option>
                <option value="employee">Employee</option>
                <option value="admin">Administrator</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClick}>
          Close
        </Button>
        <Button variant="primary" onClick={props.handleSave.bind(this, !!user.username, newUser)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UsersModal;
