import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { SETTLEMENTSDETAILS } from "../../CONSTANTS";
import NumberFormat from "react-number-format";
import i18n from "../../i18n";
import MenuItem from "@material-ui/core/MenuItem";

const inputStyles = {
  marginRight: 5,
  marginLeft: 5,
  minWidth: 190,
};

const longField = {
  marginRight: 5,
  marginLeft: 5,
  width: 900,
};

const blockLabel = {
  marginBottom: 0,
  width: "100%",
  fontWeight: "bold",
};

const chipStyle = {
  width: "100%",
};

export class SettlementsModalContent extends React.Component {
  constructor(props) {
    super(props);
    let content = SETTLEMENTSDETAILS.map((param) => {
      let val = Object.assign({}, { ...param });
      val.value = Object.keys(props.modalContent).length > 0 ? props.modalContent[param.id] : val.value;
      return val;
    });

    let isEditMode = Boolean(Object.keys(props.modalContent).length);
    if (isEditMode) {
      var object = this.props.objects.find((obj) => obj._id === this.props.modalContent.objectId);
    }

    // this.props.contracts.filter((contr) => obj.contracts.some((objContrId) => objContrId === contr.id))
    this.state = {
      objects: props.initSettlements,
      contracts: props.contracts,
      settlementsFields: content,
      matchedContracts: isEditMode
        ? this.props.contracts.filter((contr) => object.contracts.some((objContrId) => objContrId === contr._id))
        : [],
      selectedContract: isEditMode ? this.props.contracts.find((contr) => contr.contractNum === this.props.modalContent.contractNum) : null,
      selectedContractObjects: [],
      selectedObject: isEditMode ? this.props.objects.find((obj) => obj._id === this.props.modalContent.objectId) : "",
      isEditMode: isEditMode,
    };
    this.setObjectFromSelection = this.setObjectFromSelection.bind(this);
  }

  componentWillUnmount() {
    let erasedValues = Object.assign([], { ...this.state.settlementsFields });
    erasedValues = erasedValues.forEach((param) => {
      param.value = "";
      return param;
    });

    this.setState({
      settlementsFields: erasedValues,
    });
  }

  validateAndSave(field, event) {
    // TODO: add validation to input
    if (this.isNotValid(field.type, event)) {
      return;
    }

    if (!this.state.selectedContract) {
      return;
    }
    this.updateState(field, event);
    console.log(this.state.settlementsFields);
    this.saveData();
  }

  isNotValid(type, event) {
    if (type === "number" && event.target.value) {
      return !/^\d*(\.|,){0,1}\d{0,2}$/g.test(event.target.value);
    }

    return false;
  }

  saveData() {
    let preparedData = {};
    this.state.settlementsFields.forEach((e) => (preparedData[e.id] = e.value));
    if (!this.state.isEditMode) {
      preparedData.contractId = this.state.selectedContract._id;
      preparedData.customerId = this.state.selectedContract.customers[0];
      preparedData.objectId = this.state.selectedObject._id;
    }

    this.props.setData(preparedData);
  }

  updateState(field, event) {
    let stateCopy = [...this.state.settlementsFields];
    stateCopy[this.findElementIndexByID(stateCopy, field.id)].value =
      field.type === "number" ? event.target.value.replace(",", ".") : event.target.value;
    this.setState({
      settlementsFields: stateCopy,
    });
  }

  findElementIndexByID(arr, fieldId) {
    return arr.indexOf(arr.find((el) => el.id === fieldId));
  }

  handleAddedImage(image) {
    let temp = this.state.settlementsFields;
    temp[temp.findIndex((el) => el.id === "settlementsPhotos")].value.push(image.path);
    this.setState({
      settlementsFields: temp,
    });
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        allowLeadingZeros={true}
        format="##/##/####"
        placeholder="DD/MM/YYYY"
        mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
      />
    );
  }

  timeFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        allowLeadingZeros={true}
        format="##:##"
        placeholder="HH:MM"
        mask={["H", "H", "M", "M"]}
      />
    );
  }

  getOptions(headerItem) {
    if (headerItem.id === "objectModel") {
      let foundModelsForBrand = this.state.carBrands.find(
        (brandModels) => brandModels.brand === this.state.objectFields.find((field) => field.id === "objectBrand").value
      );
      return foundModelsForBrand ? foundModelsForBrand.models : [];
    } else if (headerItem.id === "objectBrand") {
      let brands = this.state.carBrands.map((br) => br.brand) || [];
      return brands;
    }

    return headerItem.options;
  }

  setObjectFromSelection(event, obj) {
    if (obj) {
      let contractData = this.props.contracts.filter((contr) => obj.contracts.some((objContrId) => objContrId === contr._id));
      // contractData.insurer = obj.insurer;
      // contractData.object = obj.objectBrand + " " + obj.objectModel + " " + obj.objectPlates;

      this.setState({
        matchedContracts: contractData,
        // selectedContract: contractData,
        selectedObject: obj,
        // settlementsFields: this.state.settlementsFields.map((item) => {
        //   item.value = contractData[item.id] || item.value;
        //   if (item.id === "insFranchise") item.value = contractData[item.id];
        //   return item;
        // }),
      });
    }
  }

  setValueFromSelect(event, selected) {
    // let stateCopyOfSettlementsFields = [...this.state.settlementsFields];
    let contractData = this.state.matchedContracts.filter(
      (cont) => cont.contractNum === event.target.value.split("_|_")[0] && cont.insType === event.target.value.split("_|_")[1]
    )[0];
    contractData.insurer = this.state.selectedObject.insurer;
    contractData.object =
      this.state.selectedObject.objectBrand + " " + this.state.selectedObject.objectModel + " " + this.state.selectedObject.objectPlates;
    let newSettlementFields = this.state.settlementsFields.map((item) => {
      item.value = contractData[item.id] || item.value;
      if (item.id === "insFranchise") item.value = contractData[item.id];
      return item;
    });

    if (contractData.customers.length === 0) {
      console.error("Selected contract does not have any customers. Please firstly attach a customer or select another contract");
    }

    this.setState({
      selectedContract: contractData,
      selectedObject: this.state.selectedObject,
      settlementsFields: newSettlementFields,
    });

    let preparedData = {};
    newSettlementFields.forEach((e) => (preparedData[e.id] = e.value));

    this.props.setData(preparedData);
  }

  render() {
    return (
      <>
        {!this.state.isEditMode && (
          <>
            <div style={blockLabel}>
              <Chip label={i18n.t("Select object")} style={chipStyle} />
            </div>
            <div style={{ display: "inline-block", width: "99%", margin: "10px 5px" }}>
              <Autocomplete
                id="combo-box-demo"
                options={this.props.objects.filter((obj) => obj.contracts.length > 0)}
                defaultValue={
                  this.props.modalContent ? this.props.objects.find((obj) => obj._id === this.props.modalContent.contractNum) : ""
                }
                getOptionLabel={(option) => {
                  if (option.objectPlates) {
                    return option.objectBrand + " " + option.objectModel + " " + option.objectPlates + " " + option.objectVIN;
                  } else if (option.objectAddress) {
                    return option.objectAddress;
                  } else {
                    return option.insured;
                  }
                }}
                onChange={this.setObjectFromSelection}
                renderInput={(params) => <TextField {...params} label={i18n.t("Select object")} variant="outlined" />}
              />
            </div>
          </>
        )}
        <div style={{ display: "inline-block", width: "99%", margin: "0px 5px 10px 5px" }}>
          <TextField
            label={i18n.t("Select contract")}
            select
            style={{ width: "100%" }}
            variant="outlined"
            value={this.state.selectedContract?.contractNum + "_|_" + this.state.selectedContract?.insType || ""}
            onChange={this.setValueFromSelect.bind(this)}
            margin="normal"
          >
            {this.state.matchedContracts.map((option, id) => (
              <MenuItem
                key={id}
                selected={
                  option.contractNum + option.insType === this.state.selectedContract?.contractNum + this.state.selectedContract?.insType
                }
                value={option.contractNum + "_|_" + option.insType}
              >
                {option.contractNum + " " + option.insType}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <br />
        <div style={blockLabel}>
          <Chip label={i18n.t("Add details")} style={chipStyle} />
        </div>
        {this.state.settlementsFields
          .filter((el) => el.id !== "settlementsPhotos")
          .map((headerItem, ind) => {
            if (headerItem.type === "date") {
              return (
                <TextField
                  label={headerItem.title}
                  style={inputStyles}
                  variant="outlined"
                  shrink="true"
                  disabled={headerItem.isDisabledInEdit || !this.state.selectedContract}
                  margin="normal"
                  key={ind}
                  value={headerItem.value || ""}
                  onChange={this.validateAndSave.bind(this, headerItem)}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom,
                  }}
                />
              );
            } else if (headerItem.type === "select") {
              return (
                <TextField
                  label={headerItem.title}
                  select
                  disabled={headerItem.isDisabledInEdit || !this.state.selectedContract}
                  style={inputStyles}
                  key={ind}
                  rowsMax="4"
                  shrink="true"
                  variant="outlined"
                  value={headerItem.value || ""}
                  onChange={this.validateAndSave.bind(this, headerItem)}
                  margin="normal"
                >
                  {headerItem.options.map((option, id) => (
                    <MenuItem key={id} selected={headerItem.value === option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              );
            } else if (headerItem.type === "time") {
              return (
                <TextField
                  label={headerItem.title}
                  style={inputStyles}
                  variant="outlined"
                  shrink="true"
                  disabled={headerItem.isDisabledInEdit || !this.state.selectedContract}
                  margin="normal"
                  key={ind}
                  value={headerItem.value || ""}
                  onChange={this.validateAndSave.bind(this, headerItem)}
                  InputProps={{
                    inputComponent: this.timeFormatCustom,
                  }}
                />
              );
            } else {
              return (
                <TextField
                  label={headerItem.title}
                  multiline
                  style={headerItem.id === "eventDescription" ? longField : inputStyles}
                  disabled={headerItem.isDisabledInEdit || !this.state.selectedContract}
                  key={ind}
                  rowsMax="4"
                  shrink="true"
                  placeholder={headerItem.placeholder}
                  variant="outlined"
                  value={headerItem.value || ""}
                  onChange={this.validateAndSave.bind(this, headerItem)}
                  margin="normal"
                />
              );
            }
          })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  initSettlements: state.settlements.settlementsList,
  contracts: state.contracts.contractsList,
  customers: state.contracts.customersList,
  objects: state.objects.objectsList,
  modalContent: state.modals.modalContent,
});

export default connect(mapStateToProps, {})(SettlementsModalContent);
