import React from "react";
import { Form } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authentication";
import { setLoading } from "../../actions/API_helpers";
import classnames from "classnames";
import TextField from "@material-ui/core/TextField";
import i18n from "../../i18n";
import { ClipLoader } from "react-spinners";

const loginForm = {
  margin: "auto",
  width: "25%",
  padding: "10px",
  textAlign: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const loginPage = {
  width: "100%",
  height: "100%",
  background: "radial-gradient(circle at center, #fff 15%, #033365 189%)",
};

const logo = {
  width: 200,
};

const loginButton = {
  width: "100%",
  backgroundColor: "#033362",
  color: "white",
};

export class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    this.props.setLoading(true);
    e.preventDefault();
    const user = {
      username: this.state.username,
      password: this.state.password,
    };
    this.props.loginUser(user);
  }

  // TODO: refactor (smth is not pretty here. Auth logic is also on App.js and PrivateRoute)
  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.isAuthenticated) {
      let basePage = this.props.auth.user.role === "client" ? "/contracts" : "/home";
      this.props.history.push(basePage);
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      let basePage = this.props.auth.user.role === "client" ? "/contracts" : "/home";
      this.props.history.push(basePage);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auth.isAuthenticated) {
      let basePage = this.props.auth.user.role === "client" ? "/contracts" : "/home";
      this.props.history.push(basePage);
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <>
        {this.props.loading && (
          <div className="loading">
            <ClipLoader color="#00BFFF" size={150} />
          </div>
        )}
        <div style={loginPage}>
          <div className="loginForm" style={loginForm}>
            <img src="https://bisukraine.com/templates/bis/images/logo_t.svg" style={logo} alt="logo" />
            <Form onSubmit={this.handleSubmit} noValidate>
              <TextField
                required
                id="username"
                className={classnames("", {
                  "is-invalid": errors.username,
                })}
                label={i18n.t("username")}
                defaultValue=""
                margin="normal"
                variant="outlined"
                name="username"
                fullWidth
                onChange={this.handleInputChange}
              />

              <TextField
                required
                className={classnames("", {
                  "is-invalid": errors.password,
                })}
                id="password"
                label={i18n.t("password")}
                defaultValue=""
                margin="normal"
                variant="outlined"
                name="password"
                type="password"
                fullWidth
                onChange={this.handleInputChange}
              />

              {this.state.errors.password && <p>{this.state.errors.password}</p>}
              {this.state.errors === "User not found" && <p>{this.state.errors}</p>}

              <Button variant="contained" style={loginButton} type="submit">
                {i18n.t("signin")}
              </Button>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.helpers.loading,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, setLoading })(Login);
