import { ADD_CONTRACT, GET_ALL_CONTRACTS, GET_SALES_REPORT, GET_ALL_CONTRACTS_RD, DELETE_CONTRACT, EDIT_CONTRACT } from "../actions/types";

const initialState = {
  contractsList: [],
  reportData: [],
  contractsReportData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CONTRACT:
      return {
        ...state,
        contractsList: state.contractsList.concat(action.payload),
      };
    case GET_ALL_CONTRACTS:
      return {
        ...state,
        contractsList: action.payload,
      };
    case GET_SALES_REPORT:
      return {
        ...state,
        reportData: action.payload,
      };
    case GET_ALL_CONTRACTS_RD:
      return {
        ...state,
        contractsReportData: action.payload,
      };
    case EDIT_CONTRACT:
      return {
        ...state,
        contractsList: state.contractsList.map((contract) => {
          if (contract._id === action.payload._id) {
            return { ...contract, ...action.payload };
          }
          return contract;
        }),
      };
    case DELETE_CONTRACT:
      return {
        ...state,
        contractsList: state.contractsList.filter((contr) => contr._id !== action.payload.id),
      };
    default:
      return state;
  }
}
