import { GET_ALL_USERS, DELETE_USER, EDIT_USER, ADD_USER } from "../actions/types";

const initialState = {
  usersList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        usersList: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        usersList: state.usersList.concat(action.payload),
      };
    case EDIT_USER:
      return {
        ...state,
        usersList: state.usersList.map((user) => {
          if (user.username === action.payload.username) {
            return { ...user, ...action.payload };
          }
          return user;
        }),
      };
    case DELETE_USER:
      return {
        ...state,
        usersList: state.usersList.filter((user) => user._id !== action.payload.id),
      };
    default:
      return state;
  }
}
