import { SET_MODAL_CONTENT } from '../actions/types';

const initialState = {
    modalContent: {}
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_MODAL_CONTENT:
            return {
                ...state,
                modalContent: action.payload
            }
        default: 
            return state;
    }
}