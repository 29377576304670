import { GET_ALL_OBJECTS, DELETE_OBJECT, EDIT_OBJECT, ADD_OBJECT } from "../actions/types";

const initialState = {
  objectsList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_OBJECTS:
      return {
        ...state,
        objectsList: action.payload.sort((a, b) => b.createdDate - a.createdDate),
      };
    case ADD_OBJECT:
      let exist = state.objectsList.find((el) => el._id === action.payload._id);
      return {
        ...state,
        objectsList: exist ? state.objectsList : state.objectsList.concat(action.payload),
      };
    case EDIT_OBJECT:
      return {
        ...state,
        objectsList: state.objectsList.map((obj) => {
          if (obj._id === action.payload._id) {
            return { ...obj, ...action.payload };
          }
          return obj;
        }),
      };
    case DELETE_OBJECT:
      return {
        ...state,
        objectsList: state.objectsList.filter((obj) => obj._id !== action.payload.id),
      };
    default:
      return state;
  }
}
