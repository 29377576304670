import React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import CustomersTable from "./CustomersTable";
import { addCustomer, getCustomers, editCustomer, deleteCustomer } from "../../actions/customers";

import { setLoading } from "../../actions/API_helpers";
import { addObject } from "../../actions/objects";
import { prepareCustomerAndFormatDates } from "../../helpers/dataTransformations";
import { actionButtonsContainer, containerStyle, buttonStyle } from "../Common/Styles";
import CustomersModal from "./CustomersModal";
import { withTranslation } from "react-i18next";

class CustomersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: null,
      // showSubModal: false,
      row: null,
      // subModalViewType: null,
      showModal: false,
    };
    this.saveCustomer = this.saveCustomer.bind(this);
    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleAddNewClick = function (target, row, context) {
    this.setState({
      showSubModal: !this.state.showSubModal,
      // subModalViewType: target,
      row,
    });
  };

  openModal = function () {
    this.setState({
      showModal: true,
    });
  };

  closeModal = function () {
    this.setState({
      showModal: false,
    });
  };

  // handleSubModalClick = function () {
  //     this.setState({
  //         showSubModal: !this.state.showSubModal,
  //         subModalViewType: null
  //     })
  // }

  saveCustomer(data, fromRow) {
    this.props.setLoading(true);
    if (fromRow) {
      //case when something added from subtables
      if (this.state.subModalViewType === "customer") {
        // <<<<<<< MOST PROBABLY THIS CAN BE DELETED
        let preparedCustomer = prepareCustomerAndFormatDates(data);

        //save object && update customer
        this.props.editCustomer(preparedCustomer).then(
          (res) => {
            this.props.getCustomers();
            this.setState({
              showModal: !this.state.showModal,
              subModalViewType: null,
            });
            console.log("SUCCESS!");
            this.props.setLoading(false);
          },
          (err) => {
            console.log("NO SUCCESS!", err);
            this.props.setLoading(false);
          }
        );
      }
    } else {
      //case when new data added from menu
      let preparedCustomer = prepareCustomerAndFormatDates(data);

      this.props.addCustomer(preparedCustomer).then(
        (res) => {
          this.props.getCustomers();
          this.setState({
            showModal: !this.state.showModal,
            // subModalViewType: null
          });
          console.log("SUCCESS!", res);
          this.props.setLoading(false);
        },
        (err) => {
          console.log("NO SUCCESS!", err);
        }
      );
    }
  }

  // componentDidMount() {
  //   this.props.getCustomers().then((customers) => {
  //     this.setState({
  //       customers,
  //     });
  //   });
  // }

  render() {
    return (
      <>
        <CustomersModal
          isShow={this.state.showModal}
          saveContent={this.saveCustomer}
          closeModal={this.closeModal}
          openModal={this.openModal}
        />

        <div style={containerStyle}>
          {this.props.auth.user.role !== "client" && (
            <div style={actionButtonsContainer}>
              <Button variant="contained" style={buttonStyle} onClick={this.openModal}>
                <i className="fas fa-plus-square"></i>
                {this.props.t("customersView.addCustomer")}
              </Button>
              {/* <Button variant="contained" style={buttonStyle} ><i className="fas fa-eraser"></i> Clear filters</Button> */}
              <Button variant="contained" style={buttonStyle} onClick={this.exportToExcel}>
                <i className="fas fa-file-excel"></i> {this.props.t("exportToExcel")}
              </Button>
            </div>
          )}
          <CustomersTable />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.helpers.loading,
  customers: state.customers.customersList,
});

export default connect(mapStateToProps, {
  addCustomer,
  getCustomers,
  editCustomer,
  addObject,
  setLoading,
  deleteCustomer,
})(withTranslation()(CustomersView));
