import axios from 'axios';
import { GET_ERRORS, GET_ALL_TASKS, DELETE_TASK, ADD_TASK, EDIT_TASK } from './types';
import { URL } from '../CONSTANTS';

export const getTasks = (user) => dispatch => {
    return axios.get(URL + '/api/task/gettasks', user)
        .then(res => {
            dispatch({
                type: GET_ALL_TASKS,
                payload: res.data
            });

            return res.data
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response ? err.response.data : null
            });
        });
}

export const editTask = (obj) => dispatch => {
    return axios.put(URL + '/api/task/update', { params: obj })
        .then(res => {
            dispatch({
                type: EDIT_TASK,
                payload: obj
            });

            return res.data
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response ? err.response.data : null
            });
        });
}

export const deleteTask = (id) => dispatch => {
    return axios.delete(URL + '/api/task/delete', { params: { "id": id } })
        .then(res => {
            dispatch({
                type: DELETE_TASK,
                payload: { id }
            });

            return res.data
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response ? err.response.data : null
            });
        });
}

export const addTask = (obj) => dispatch => {
    return axios.post(URL + '/api/task/addtask', obj)
        .then(res => {
            dispatch({
                type: ADD_TASK,
                payload: obj
            })
            return res.data
        })
        .catch(err => {
            dispatch({
              type: GET_ERRORS,
              payload: err.response ? err.response.data : null,
            });
        });
}