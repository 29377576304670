import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import matchSorter from "match-sorter";
import "react-table-6/react-table.css";
import { TASKDETAILS } from "../../CONSTANTS";
// import { fromDBDate } from '../../helpers/dataTransformations'
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { getTasks, editTask, deleteTask, addTask } from "../../actions/tasks";
import { setLoading } from "../../actions/API_helpers";
import TasksModal from "./TasksModal";
import { withTranslation } from "react-i18next";
import { addSlashesToString } from "../../helpers/dateTransformations";

export class TasksTable extends React.Component {
  constructor(props) {
    super();
    this.state = {
      row: null,
      showModal: false,
    };
    this.updateContent = this.updateContent.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleRowExpanded = this.handleRowExpanded.bind(this);
    this.addItems = this.addItems.bind(this);
  }

  updateContent(data) {
    this.props.setLoading(true);
    if (!data._id) {
      data._id = this.state.row._id;
    }

    this.props.editTask(data).then(
      (res) => {
        console.log("SUCCESS!", res);
        this.props.getTasks(this.props.auth.user);
        this.props.setLoading(false);
      },
      (err) => {
        console.log("SUCCESS!", err);
        this.props.setLoading(false);
      }
    );

    this.setState({
      showModal: false,
    });

    this.props.setSubModalContent({});
  }

  handleAddNewClick = function (target, row, context) {
    this.setState({
      row,
    });
  };

  addItems(data) {
    this.setState({ row: Object.assign({}, { ...data }) });
    this.updateContent(data);
  }

  handleRowExpanded(newExpanded, index, event) {
    if (this.state.expanded[index]) {
      this.setState({
        expanded: { [index]: false },
      });
    } else {
      this.setState({
        expanded: { [index]: true },
      });
    }
  }

  handleAddedImage(row) {
    this.setState({
      expanded: { [row.index]: true },
    });
  }

  closeModal() {
    let initial = {};
    TASKDETAILS.forEach((el) => (initial[el.id] = el.value));
    sessionStorage.setItem("taskDraft", JSON.stringify(initial));
    this.setState({
      showModal: false,
    });
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  deleteTask(data) {
    if (window.confirm("Are you sure you want to delete item?")) {
      this.props.deleteTask(data._id).then((res) => {
        //TODO: delete objectID from CUSTOMERS!

        this.props.getTasks(this.props.auth.user);
        this.setState({
          tasks: this.props.tasks,
        });
      });
    }
  }

  putCustomerDataToStoreAndOpenModal(task) {
    sessionStorage.setItem("taskDraft", JSON.stringify(task));
    this.openModal();
  }

  cloneTask = function (task) {
    task.creator = this.props.auth.user.username;
    let clonedTask = Object.assign({}, { ...task });
    this.addClonedLabelToProperties(clonedTask);
    this.deleteNotNeededProperties(clonedTask);
    this.props.addTask(clonedTask).then((res) => {
      this.props.getTasks(this.props.auth.user);
    });
  };

  addClonedLabelToProperties(clonedTask) {
    clonedTask.owner = "cloned_" + clonedTask.description;
    clonedTask.createdDate = new Date().getTime();
  }

  deleteNotNeededProperties(clonedTask) {
    delete clonedTask.id;
    delete clonedTask._id;
    delete clonedTask.__v;
  }

  render() {
    const filters = {
      startEnd: (filter, row) => row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
      includes: (filter, row) => {
        return String(row._original[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
      },
      dateIncludes: (filter, row) => {
        return String(row._original[filter.id]).includes(filter.value.replace(/\D/gm, ""));
      },
      matchSrtr: (filter, rows) => matchSorter(rows, filter.value, { keys: ["firstName"] }),
    };

    return (
      <>
        <TasksModal isShow={this.state.showModal} closeModal={this.closeModal} openModal={this.openModal} />
        <ReactTable
          data={this.props.tasks.sort((a, b) => {
            return b.createdDate - a.createdDate;
          })}
          filterable
          collapseOnDataChange={false}
          getTrProps={(state, rowInfo, instance) => {
            if (rowInfo) {
              if (rowInfo.row.isDone) {
                return {
                  style: {
                    background: "#b9f0b9",
                  },
                };
              }
            }
            return {};
          }}
          defaultFilterMethod={filters.includes}
          columns={[
            {
              Header: this.props.t("actions"),
              width: 150,
              filterable: false,
              accessor: "action",
              Cell: (row) => (
                <ButtonGroup size="small" variant="outlined" color="primary" aria-label="small outlined button group">
                  <Button
                    disabled={
                      this.props.auth.user.username !== row.original.creator &&
                      this.props.auth.user.role !== "admin" &&
                      this.props.auth.user.username !== row.original.responsibleUsername
                    }
                    onClick={this.putCustomerDataToStoreAndOpenModal.bind(this, row.original)}
                  >
                    <i className="far fa-edit fa-lg"></i>
                  </Button>
                  <Button
                    disabled={this.props.auth.user.username !== row.original.creator}
                    onClick={this.cloneTask.bind(this, row.original)}
                  >
                    <i className="far fa-copy fa-lg"></i>
                  </Button>
                  <Button
                    disabled={this.props.auth.user.username !== row.original.creator}
                    color="secondary"
                    onClick={this.deleteTask.bind(this, row.original)}
                  >
                    <i className="far fa-trash-alt fa-lg"></i>
                  </Button>
                </ButtonGroup>
              ),
            },
            {
              Header: this.props.t("tasksView.description"),
              width: 1200,
              accessor: "description",
            },
            {
              Header: this.props.t("tasksView.dueDate"),
              width: 100,
              accessor: "dueDate",
              filterMethod: filters.dateIncludes,
              Cell: (props) => <span>{props.original.dueDate ? addSlashesToString(props.original.dueDate) : null}</span>,
            },
            {
              Header: this.props.t("tasksView.responsible"),
              width: 120,
              accessor: "responsible",
            },
            {
              Header: this.props.t("tasksView.isDone"),
              accessor: "isDone",
              width: 100,
              Cell: (row) => {
                return row.original.isDone ? "Tak" : "Hi";
              },
            },
          ]}
          defaultPageSize={20}
          className="-striped -highlight"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  tasks: state.tasks.tasksList,
  loading: state.helpers.loading,
});

export default connect(mapStateToProps, {
  getTasks,
  editTask,
  deleteTask,
  addTask,
  setLoading,
})(withTranslation()(TasksTable));
