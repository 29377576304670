import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap";
import ThirdStep from "./Stepper/contractInfoStep";
import Button from "@material-ui/core/Button";
import { CONTRACTDETAILS } from "../../CONSTANTS";
import { useStore } from "react-redux";

//TODO: THIRD STEP HERE. REFACTOR TO REMOVE IT
const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    background: "#033365",
    color: "white",
    "&.Mui-disabled": {
      background: "lightgray",
    },
    "&:hover": {
      background: "rgba(4, 51, 98, 0.8)",
    },
  },
}));

function EditContractModal(props) {
  const [nextDisabled, setDisabled] = React.useState(false);
  const classes = useStyles();
  const store = useStore();
  let data = JSON.parse(sessionStorage.getItem("newContractDraft"));

  function isDisabled() {
    // return false;
    const storeState = store.getState();

    let draftContract = JSON.parse(sessionStorage.getItem("newContractDraft"));
    if (!draftContract) return;

    if (
      draftContract &&
      storeState.contracts.contractsList.some(
        (el) => el._id !== draftContract._id && el.contractNum === draftContract.contractNum && el.insType === draftContract.insType
      )
    ) {
      setDisabled(true);
      return;
    }

    const stepFields = CONTRACTDETAILS.map((el) => Object.assign({}, { ...el }));
    stepFields.forEach((f) => {
      f.mandatory = typeof f.mandatory === "function" ? f.mandatory(draftContract.insType, draftContract.type) : f.mandatory;
      return f;
    });
    let fieldsToCheck = Object.keys(draftContract).filter((el) => stepFields.some((sfel) => sfel.id === el && sfel.mandatory));
    setDisabled(fieldsToCheck.some((key) => draftContract[key] === ""));
  }

  console.log("editContractModal rendered");
  return (
    <>
      <Modal show={props.isShown} onHide={props.closeEditModal} size="lg" dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Edit contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ThirdStep isNextDisabled={isDisabled} contract={data} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" className={classes.button} onClick={props.closeEditModal}>
            Close
          </Button>
          <Button variant="contained" disabled={nextDisabled} className={classes.button} onClick={props.saveContent}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditContractModal;
