import { GET_ALL_TASKS, DELETE_TASK, EDIT_TASK, ADD_TASK } from "../actions/types";

const initialState = {
  tasksList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TASKS:
      return {
        ...state,
        tasksList: action.payload.sort((a, b) => b.createdDate - a.createdDate),
      };
    case ADD_TASK:
      return {
        ...state,
        tasksList: state.tasksList.concat(action.payload),
      };
    case EDIT_TASK:
      return {
        ...state,
        tasksList: state.tasksList.map((task) => {
          if (task._id === action.payload._id) {
            return { ...task, ...action.payload };
          }
          return task;
        }),
      };
    case DELETE_TASK:
      return {
        ...state,
        tasksList: state.tasksList.filter((task) => task._id !== action.payload.id),
      };
    default:
      return state;
  }
}
