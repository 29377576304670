import axios from "axios";
import { GET_ALL_CUSTOMERS, ADD_CUSTOMER, GET_ERRORS, EDIT_CUSTOMER, DELETE_CUSTOMER } from "./types";
import { URL } from "../CONSTANTS";

export const addCustomer = (customer) => (dispatch) => {
  return axios
    .post(URL + "/api/addcustomer", customer)
    .then((res) => {
      dispatch({
        type: ADD_CUSTOMER,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

export const getCustomers = (user) => (dispatch) => {
  return axios
    .get(URL + "/api/getcustomers", user)
    .then((res) => {
      dispatch({
        type: GET_ALL_CUSTOMERS,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

export const editCustomer = (cstmr) => (dispatch) => {
  return axios
    .put(URL + "/api/editcustomer", { params: cstmr })
    .then((res) => {
      dispatch({
        type: EDIT_CUSTOMER,
        payload: cstmr,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const deleteCustomer = (id) => (dispatch) => {
  return axios
    .delete(URL + "/api/deleteCustomer", { params: { id: id } })
    .then((res) => {
      dispatch({
        type: DELETE_CUSTOMER,
        payload: { id },
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const removeCustomerReference = (from, custRow, subTableRow) => (dispatch) => {
  return axios
    .put(URL + "/api/removereference", {
      params: {
        from,
        id: custRow._id,
        subId: subTableRow._id,
      },
    })
    .then((res) => {
      dispatch({
        type: EDIT_CUSTOMER,
        payload: custRow,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};
