import { GET_ALL_COMPANIES, DELETE_COMPANY, EDIT_COMPANY, ADD_COMPANY } from "../actions/types";

const initialState = {
  companyList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COMPANIES:
      return {
        ...state,
        companyList: action.payload,
      };
    case ADD_COMPANY:
      return {
        ...state,
        companyList: state.companyList.concat(action.payload),
      };
    case EDIT_COMPANY:
      return {
        ...state,
        companyList: state.companyList.map((company) => {
          if (company.legalName === action.payload.legalName) {
            return { ...company, ...action.payload };
          }
          return company;
        }),
      };
    case DELETE_COMPANY:
      return {
        ...state,
        companyList: state.companyList.filter((company) => company._id !== action.payload.id),
      };
    default:
      return state;
  }
}
