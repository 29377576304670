import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import ReactTable from "react-table-6";
import matchSorter from "match-sorter";
import "react-table-6/react-table.css";

export const Users = (props) => {
  const filters = {
    startEnd: (filter, row) => row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
    includes: (filter, row) => String(row[filter.id]).includes(filter.value),
    matchSrtr: (filter, rows) => matchSorter(rows, filter.value, { keys: ["firstName"] }),
  };
  return (
    <>
      <ReactTable
        data={props.users}
        filterable
        defaultFilterMethod={filters.includes}
        columns={[
          {
            Header: "Username",
            accessor: "username",
            filterMethod: filters.startEnd,
          },
          {
            Header: "First Name",
            id: "firstName",
            accessor: (d) => d.firstName,
            filterMethod: filters.matchSrtr,
            filterAll: true,
          },
          {
            Header: "Last Name",
            id: "lastName",
            accessor: (d) => d.lastName,
          },
          {
            Header: "Legal Name",
            id: "legalName",
            accessor: (d) => d.legalName,
          },
          {
            Header: "Company Id",
            id: "customerId",
            accessor: (d) => d.customerId,
          },
          {
            Header: "Email",
            id: "email",
            accessor: (d) => d.email,
          },
          {
            Header: "Mobile Phone",
            id: "mobilePhone",
            accessor: (d) => d.mobilePhone,
          },
          {
            Header: "Created",
            id: "createdDate",
            accessor: (d) => d.createdDate,
          },
          {
            Header: "Role",
            id: "role",
            accessor: (d) => d.role,
          },
          {
            Header: "Action",
            id: "action",
            accessor: (d) => d._id,
            Cell: (rowInfo) => {
              return (
                <ButtonGroup size="sm">
                  <Button variant="success" onClick={props.editUser.bind(this, rowInfo.original)}>
                    EDIT
                  </Button>
                  <Button variant="danger" onClick={props.deleteUser.bind(this, rowInfo.original._id)}>
                    DELETE
                  </Button>
                </ButtonGroup>
              );
            },
            Filter: <></>,
          },
        ]}
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </>
  );
};
