import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import matchSorter from "match-sorter";
import "react-table-6/react-table.css";
import { addCustomer, getCustomers, editCustomer, deleteCustomer, removeCustomerReference } from "../../actions/customers";
import { deleteFile } from "../../actions/files";
import { editContract, removeContractReference } from "../../actions/contracts";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import { setLoading, addLog } from "../../actions/API_helpers";
import { addObject } from "../../actions/objects";
import { buttonStyle } from "../Common/Styles";
import ObjectsSubTable from "../Subtables/ObjectsSubTable";
import SettlementsSubTable from "../Subtables/SettlementsSubTable";
import ContractsSubTable from "../Subtables/ContractsSubTable";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import CustomersModal from "./CustomersModal";
import { setSubModalContent } from "../../actions/modals";
import AttachItemModal from "../Subtables/AttachItemModal";
import { withTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import FilesDragNDrop from "../Common/DragAndDrop";
import CancelIcon from "@material-ui/icons/Cancel";
import { URL } from "../../CONSTANTS";

export class CustomersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folded: {
        col_2: true,
        col_3: true,
        col_4: true,
      },
      expanded: {},
      filtered: props.customers.length,
      attachItemModalViewType: null,
      row: null,
      showModal: false,
    };

    this.selectTable = React.createRef();

    this.updateContent = this.updateContent.bind(this);
    this.closeAttachItemModal = this.closeAttachItemModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.addItems = this.addItems.bind(this);
  }

  closeModal() {
    this.props.setSubModalContent({});
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  openModal(row) {
    this.setState({
      showModal: true,
      row,
    });
  }

  handleAddedImage(row) {
    this.setState({
      expanded: { [row.index]: true },
    });
  }

  deleteFile(obj, ind) {
    obj.customerPhotos.splice(ind, 1);
    this.props.editCustomer(obj).then((res) => {
      console.log("file deleted: " + res.data);
    });
  }

  handleAddNewClick = function (target, row, context) {
    this.setState({
      showAttachItemModal: !this.state.showAttachItemModal,
      attachItemModalViewType: target,
      row,
    });
  };

  closeAttachItemModal() {
    this.setState({
      showAttachItemModal: !this.state.showAttachItemModal,
      attachItemModalViewType: null,
    });
  }

  updateContent(data) {
    this.props.setLoading(true);

    if (!data._id) {
      data._id = this.state.row._id;
    }

    this.props.editCustomer(data).then(
      (res) => {
        console.log("SUCCESS!", res);
        this.props.getCustomers();
        this.props.setLoading(false);
      },
      (err) => {
        console.log("SUCCESS!", err);
        this.props.setLoading(false);
      }
    );

    this.setState({
      showModal: false,
      // customers: this.props.customers,
    });

    this.props.setSubModalContent({});
  }

  addItems(data) {
    this.setState({ row: Object.assign({}, { ...data }) });
    this.updateContent(data);
  }

  putCustomerDataToStoreAndOpenModal(customer) {
    this.props.setSubModalContent(customer);
    this.openModal(customer);
  }

  deleteCustomer = function (customer) {
    if (window.confirm("Are you sure you want to delete item?")) {
      this.props.deleteCustomer(customer._id);
    }
  };

  removeReference = function (from, custRow, subTableRow) {
    this.props.removeCustomerReference(from, custRow.original, subTableRow);
  };

  render() {
    const sortings = {
      dateSort: (a, b, desc) => {
        if (a && b) {
          if (desc) {
            return new Date(Number(a.substr(4)), Number(a.substr(2, 2)) - 1, Number(a.substr(0, 2))) <
              new Date(Number(b.substr(4)), Number(b.substr(2, 2)) - 1, Number(b.substr(0, 2)))
              ? -1
              : 1;
          }
          return new Date(Number(a.substr(4)), Number(a.substr(2, 2)) - 1, Number(a.substr(0, 2))) >
            new Date(Number(b.substr(4)), Number(b.substr(2, 2)) - 1, Number(b.substr(0, 2)))
            ? 1
            : -1;
        }
        return 0;
      },
    };
    const filters = {
      startEnd: (filter, row) => row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
      includes: (filter, row) => {
        return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
      },
      dateIncludes: (filter, row) => {
        return String(row._original[filter.id]).includes(filter.value.replace(/\D/gm, ""));
      },
      matchSrtr: (filter, rows) => matchSorter(rows, filter.value, { keys: ["firstName"] }),
      // dateFilter: (filter, rows) =>
    };

    return (
      <>
        {this.props.loading && (
          <div className="loading">
            <ClipLoader color="#00BFFF" size={150} />
          </div>
        )}
        <AttachItemModal
          fromRow={this.state.row}
          contentType={this.state.attachItemModalViewType}
          saveContent={this.addItems}
          closeModal={this.closeAttachItemModal}
          showAttachItemModal={this.state.showAttachItemModal}
        />
        <CustomersModal
          isShow={this.state.showModal}
          // updateContent={this.updateContent}
          closeModal={this.closeModal}
          openModal={this.openModal}
        />

        <ReactTable
          data={this.props.customers.sort((a, b) => b.createdDate - a.createdDate)}
          collapseOnDataChange={false}
          filterable
          ref={this.selectTable}
          onFilteredChange={() => {
            this.setState({ filtered: this.selectTable.current.getResolvedState().sortedData.length });
          }}
          defaultFilterMethod={filters.includes}
          columns={[
            {
              Header: this.state.filtered,
              width: 40,
              filterable: false,
              Cell: (row) => {
                return row.index + 1 + ".";
              },
            },
            {
              Header: this.props.t("actions"),
              width: 150,
              filterable: false,
              accessor: "action",
              Cell: (row) => (
                <ButtonGroup size="small" variant="outlined" color="primary" aria-label="small outlined button group">
                  <Button
                    disabled={this.props.auth.user.role === "client"}
                    onClick={this.putCustomerDataToStoreAndOpenModal.bind(this, row.original)}
                  >
                    <i className="far fa-edit fa-lg"></i>
                  </Button>
                  <Button
                    disabled={this.props.auth.user.role === "client"}
                    color="secondary"
                    onClick={this.deleteCustomer.bind(this, row.original)}
                  >
                    <i className="far fa-trash-alt fa-lg"></i>
                  </Button>
                </ButtonGroup>
              ),
            },
            {
              Header: this.props.t("customersView.name"),
              width: 300,
              accessor: "name",
            },
            {
              Header: this.props.t("clientInfoStep.type"),
              width: 170,
              accessor: "type",
            },
            {
              Header: this.props.t("clientInfoStep.address"),
              width: 400,
              accessor: "address",
            },
            {
              Header: this.props.t("clientInfoStep.phoneNumber"),
              width: 120,
              accessor: "phone",
            },
            {
              Header: this.props.t("clientInfoStep.email"),
              width: 250,
              accessor: "email",
            },
            {
              Header: this.props.t("clientInfoStep.dateOfBirth"),
              accessor: "dateOfBirth",
              filterMethod: filters.dateIncludes,
              sortMethod: sortings.dateSort,
              Cell: (props) => (
                <span>
                  {props.original.dateOfBirth
                    ? props.original.dateOfBirth.substr(0, 2) +
                      "/" +
                      props.original.dateOfBirth.substr(2, 2) +
                      "/" +
                      props.original.dateOfBirth.substr(4)
                    : null}
                </span>
              ),
            },
            {
              Header: this.props.t("clientInfoStep.inn"),
              accessor: "inn",
            },
            {
              Header: this.props.t("clientInfoStep.documentType"),
              accessor: "documentType",
            },
            {
              Header: this.props.t("clientInfoStep.documentNumber"),
              accessor: "documentNumber",
            },
            {
              Header: this.props.t("clientInfoStep.documentIssuer"),
              accessor: "documentIssuer",
            },
            {
              Header: this.props.t("clientInfoStep.documentIssueDate"),
              filterMethod: filters.dateIncludes,
              sortMethod: sortings.dateSort,
              accessor: "documentIssueDate",
              Cell: (props) => (
                <span>
                  {props.original.documentIssueDate
                    ? props.original.documentIssueDate?.substr(0, 2) +
                      "/" +
                      props.original.documentIssueDate?.substr(2, 2) +
                      "/" +
                      props.original.documentIssueDate?.substr(4)
                    : null}
                </span>
              ),
            },
            {
              Header: this.props.t("clientInfoStep.documentExpiryDate"),
              filterMethod: filters.dateIncludes,
              sortMethod: sortings.dateSort,
              accessor: "documentExpiryDate",
              Cell: (props) => (
                <span>
                  {props.original.documentIssueDate
                    ? props.original.documentIssueDate?.substr(0, 2) +
                      "/" +
                      props.original.documentIssueDate?.substr(2, 2) +
                      "/" +
                      props.original.documentIssueDate?.substr(4)
                    : null}
                </span>
              ),
            },
            {
              Header: this.props.t("clientInfoStep.gender"),
              accessor: "gender",
            },
            {
              Header: this.props.t("clientInfoStep.citizenship"),
              accessor: "citizenship",
            },
            {
              Header: this.props.t("clientInfoStep.comments"),
              accessor: "comments",
            },
            {
              Header: this.props.t("clientInfoStep.bankName"),
              accessor: "bankName",
            },
            {
              Header: this.props.t("clientInfoStep.edrpou"),
              accessor: "edrpou",
            },
            {
              Header: this.props.t("clientInfoStep.accountNumber"),
              accessor: "accountNumber",
            },
            {
              Header: this.props.t("clientInfoStep.mfo"),
              accessor: "mfo",
            },
            {
              Header: this.props.t("clientInfoStep.director"),
              accessor: "director",
            },
            {
              Header: this.props.t("clientInfoStep.manager"),
              accessor: "manager",
            },
          ]}
          defaultPageSize={20}
          className="-striped -highlight"
          SubComponent={(row) => {
            return (
              <div className="subtable-block">
                <Tab.Container id="vertical-nav" defaultActiveKey="first">
                  <Row style={{ height: "100%" }}>
                    <Col style={{ width: 300, flex: "none" }}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="first">{this.props.t("customersView.Objects")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">{this.props.t("customersView.Settlements")}</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link eventKey="third">{this.props.t("customersView.Contracts")}</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link eventKey="fourth">{this.props.t("contract.Documents")}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col className="subtable-right-panel">
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <ObjectsSubTable
                            objects={this.props.objects.filter((el) => row.original.objects.some((el2) => el2 === el._id))}
                            removeObject={this.removeReference.bind(this, "objects", row)}
                            row={row}
                          ></ObjectsSubTable>
                          {this.props.auth.user.role !== "client" && (
                            <div>
                              <Button
                                disabled={this.props.auth.user.role === "client"}
                                variant="contained"
                                onClick={this.handleAddNewClick.bind(this, "objects", row)}
                                style={buttonStyle}
                              >
                                <i className="fas fa-plus-square"></i>
                                {this.props.t("Add new")}
                              </Button>
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <SettlementsSubTable
                            settlements={this.props.settlements.filter((el) => row.original.settlements.some((el2) => el2 === el._id))}
                            row={row}
                          ></SettlementsSubTable>
                          {/* {
                                        <div>
                                          <Button variant="contained" onClick={this.handleAddNewClick.bind(this, 'settlement', row)} style={buttonStyle}><i className="fas fa-plus-square"></i>Add new</Button>
                                        </div>
                                      } */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <ContractsSubTable
                            removeContract={this.removeReference.bind(this, "contracts", row)}
                            contracts={this.props.contracts.filter((el) => row.original.contracts.some((el2) => el2 === el._id))}
                            row={row}
                          />
                          {this.props.auth.user.role !== "client" && (
                            <div>
                              <Button variant="contained" onClick={this.handleAddNewClick.bind(this, "contracts", row)} style={buttonStyle}>
                                <i className="fas fa-plus-square"></i>
                                {this.props.t("Add new")}
                              </Button>
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          <div style={{ position: "relative" }}>
                            <div
                              className="photos-container"
                              style={{
                                textAlign: "center",
                                display: "inline-block",
                                position: "absolute",
                                width: 400,
                                top: 0,
                              }}
                            >
                              <FilesDragNDrop
                                objectData={{
                                  pref: row.original.name,
                                  suf: row.original.inn,
                                  entity: { customer: row.original },
                                }}
                                docFolder={"customer"}
                                handleAddedImage={this.handleAddedImage.bind(this, row)}
                              ></FilesDragNDrop>
                            </div>
                            <div
                              style={{
                                position: "relative",
                                left: 400,
                              }}
                            >
                              <div style={{ display: "inline-block", position: "relative" }}>
                                {row.original.customerPhotos.sort().map((fileName, ind) => {
                                  let dir = {
                                    folder:
                                      "customer/" +
                                      row.original.name
                                        .split("")
                                        .filter((el) => ["'", '"', "+", "/", ".", "*"].every((badS) => badS !== el))
                                        .join("") +
                                      "_" +
                                      row.original.inn,
                                    fileName: fileName,
                                  };
                                  return (
                                    <div
                                      key={ind}
                                      className="filesUploaded"
                                      style={{
                                        margin: "10px 5px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: 150,
                                          height: 150,
                                          border: "1px solid lightgray",
                                          borderRadius: 9,
                                          textAlign: "right",
                                          position: "relative",
                                        }}
                                      >
                                        <div style={{ height: 25 }}>
                                          <p
                                            style={{
                                              overflowWrap: "anywhere",
                                              fontSize: "12px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              paddingLeft: 5,
                                              width: 120,
                                            }}
                                          >
                                            <a
                                              style={{
                                                color: "#033362",
                                                overflowWrap: "anywhere",
                                                fontSize: "12px",
                                              }}
                                              href={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {dir.fileName}
                                            </a>
                                          </p>
                                          <CancelIcon
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              right: 0,
                                              color: "#dc5a5a",
                                            }}
                                            onClick={this.deleteFile.bind(this, row.original, fileName)}
                                          />
                                        </div>
                                        {dir.fileName.substr(dir.fileName.length - 4) !== ".pdf" && (
                                          <div style={{ height: 100, textAlign: "center", background: "#e1e1e1", padding: "2px 0px" }}>
                                            <div
                                              style={{
                                                backgroundImage: 'url("' + URL + "/static/" + dir.folder + "/" + dir.fileName + '")',
                                                height: 110,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                              }}
                                            ></div>
                                          </div>
                                        )}
                                        {dir.fileName.substr(dir.fileName.length - 4) === ".pdf" && (
                                          <div style={{ position: "absolute", height: 125, overflow: "hidden" }}>
                                            <embed
                                              name="plugin"
                                              width="100%"
                                              src={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                              type="application/pdf"
                                            ></embed>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.helpers.loading,
  customers: state.customers.customersList,
  contracts: state.contracts.contractsList,
  objects: state.objects.objectsList,
  settlements: state.settlements.settlementsList,
});

export default connect(mapStateToProps, {
  addCustomer,
  getCustomers,
  editCustomer,
  removeCustomerReference,
  addObject,
  deleteFile,
  setLoading,
  deleteCustomer,
  editContract,
  setSubModalContent,
  addLog,
})(withTranslation()(CustomersTable));
