import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { URL } from "../../CONSTANTS";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

function CarsView() {
  const [brand, setBrand] = useState("");
  const [brandTwo, setBrandTwo] = useState("");
  const [model, setModel] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let mo = await fetch(URL + "/api/cars/getcars");
      let j = await mo.json();
      setBrands(j);
    }

    fetchData();
  }, []);

  const handleBrandChange = (event) => {
    setBrand(event.target.value);
  };
  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const refreshCars = async () => {
    let cars = await fetch(URL + "/api/cars/getcars");
    let carsJson = await cars.json();
    localStorage.setItem("carBrands", JSON.stringify(carsJson));
    setBrands(carsJson);
  };

  const addModel = () => {
    let newModels = [].concat(brands.find((br) => br.brand === brandTwo).models, [model]);
    let car = { brand: brandTwo, models: newModels };

    fetch(URL + "/api/cars/updatemodel", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(car),
    })
      .then((res) => {
        console.log(res);
        refreshCars();
      })
      .catch((err) => {
        console.log("error", err);
      });
    setModel("");
  };

  const addBrand = () => {
    let car = { brand: brand, model: [] };
    addCar(car);
    refreshCars();
    setBrand("");
  };

  const addCar = (car) => {
    fetch(URL + "/api/cars/addmodel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(car),
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <>
      <div>
        <Paper elevation={1} style={{ padding: "10px 10px" }}>
          <div>Add new brand</div>
          <FormControl>
            <TextField value={brand || ""} onChange={handleBrandChange} label="Brand" />
          </FormControl>
          <Button onClick={addBrand} variant="contained" color="primary">
            Add
          </Button>
        </Paper>
      </div>
      <br />
      <div style={{ position: "relative" }}>
        <Paper elevation={1} style={{ padding: "10px 10px" }}>
          <div>Add new models to existing brand</div>
          <Autocomplete
            options={brands}
            style={{ width: 300, display: "inline-block", marginRight: 10 }}
            getOptionLabel={(option) => option.brand}
            onInputChange={(event, newInputValue) => {
              setBrandTwo(newInputValue);
            }}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined" />;
            }}
          />
          <FormControl>
            <TextField value={model} onChange={handleModelChange} variant="outlined" label="Model" />
          </FormControl>
          <ButtonGroup
            variant="contained"
            color="primary"
            style={{ position: "absolute", left: 555, bottom: 20 }}
            aria-label="contained primary button group"
          >
            <Button onClick={addModel} variant="contained" color="primary">
              Add
            </Button>
          </ButtonGroup>
        </Paper>
      </div>
    </>
  );
}

export default CarsView;
