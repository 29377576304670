import axios from "axios";
import { GET_ALL_SETTLEMENTS, ADD_SETTLEMENT, DELETE_SETTLEMENT, EDIT_SETTLEMENT, GET_ERRORS } from "./types";
import { URL } from "../CONSTANTS";

export const getSettlements = (user) => (dispatch) => {
  return axios
    .get(URL + "/api/settlements/getsettlements", user)
    .then((res) => {
      dispatch({
        type: GET_ALL_SETTLEMENTS,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

export const addSettlement = (settlement) => (dispatch) => {
  return axios
    .post(URL + "/api/settlements/add", settlement)
    .then((res) => {
      dispatch({
        type: ADD_SETTLEMENT,
        payload: res,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

export const deleteSettlement = (id) => (dispatch) => {
  return axios
    .delete(URL + "/api/settlements/delete", { params: { id: id } })
    .then((res) => {
      dispatch({
        type: DELETE_SETTLEMENT,
        payload: { id },
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

export const editSettlement = (settlement) => (dispatch) => {
  return axios
    .put(URL + "/api/settlements/update", { params: settlement })
    .then((res) => {
      dispatch({
        type: EDIT_SETTLEMENT,
        payload: settlement,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};
