import axios from "axios";
import {
  GET_ALL_CONTRACTS,
  GET_SALES_REPORT,
  GET_ALL_CONTRACTS_RD,
  ADD_CONTRACT,
  ADD_CUSTOMER,
  ADD_OBJECT,
  DELETE_CONTRACT,
  EDIT_CONTRACT,
  GET_ERRORS,
} from "./types";
import { URL } from "../CONSTANTS";

export const getContracts = (user) => (dispatch) => {
  return axios
    .get(URL + "/api/contracts/getcontracts", user)
    .then((res) => {
      dispatch({
        type: GET_ALL_CONTRACTS,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const getReport = (user) => (dispatch) => {
  return axios
    .get(URL + "/api/contracts/getsalesreportdata", { params: { user: user } })
    .then((res) => {
      dispatch({
        type: GET_SALES_REPORT,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => console.log(err));
};
export const getContractsReport = (user) => (dispatch) => {
  return axios
    .get(URL + "/api/contracts/getcontractsreport", { params: { user: user } })
    .then((res) => {
      dispatch({
        type: GET_ALL_CONTRACTS_RD,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => console.log(err));
};

export const addContract = (contract) => (dispatch) => {
  return axios
    .post(URL + "/api/contracts/add", contract)
    .then((res) => {
      dispatch({
        type: ADD_CONTRACT,
        payload: res.data.contract,
      });
      dispatch({
        type: ADD_CUSTOMER,
        payload: res.data.customer,
      });
      dispatch({
        type: ADD_OBJECT,
        payload: res.data.object,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const cloneProlongContract = (contract) => (dispatch) => {
  return axios
    .post(URL + "/api/contracts/clone", contract)
    .then((res) => {
      dispatch({
        type: ADD_CONTRACT,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteContract = (id) => (dispatch) => {
  return axios
    .delete(URL + "/api/contracts/delete", { params: { id: id } })
    .then(() => {
      dispatch({
        type: DELETE_CONTRACT,
        payload: { id },
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const editContract = (contr) => (dispatch) => {
  return axios
    .put(URL + "/api/contracts/update", { params: contr })
    .then((res) => {
      dispatch({
        type: EDIT_CONTRACT,
        payload: contr,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const removeContractReference = (from, contrRow, subTableRow) => (dispatch) => {
  return axios
    .put(URL + "/api/contracts/removereference", {
      params: {
        from,
        id: contrRow._id,
        subId: subTableRow._id,
      },
    })
    .then((res) => {
      dispatch({
        type: EDIT_CONTRACT,
        payload: contrRow,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};
