import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import matchSorter from "match-sorter";
import "react-table-6/react-table.css";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FilesDragNDrop from "../Common/DragAndDrop";
import Button from "@material-ui/core/Button";
import { deleteSettlement, getSettlements, editSettlement } from "../../actions/settlements";
import { downloadFile, deleteFile } from "../../actions/files";
import { editContract, getContracts } from "../../actions/contracts";
import { getObjects, editObject } from "../../actions/objects";
import { editCustomer, getCustomers } from "../../actions/customers";
import { addLog, setLoading } from "../../actions/API_helpers";
import { URL } from "../../CONSTANTS";
import CancelIcon from "@material-ui/icons/Cancel";
import SettlementsModal from "./SettlementsModal";
import { setSubModalContent } from "../../actions/modals";
import { withTranslation } from "react-i18next";

export class SettlementsTable extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showSubModal: false,
      row: null,
      filtered: props.settlements.length,
      showModal: false,
    };
    this.selectTable = React.createRef();
    this.updateContent = this.updateContent.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    this.props.getSettlements();
    this.props.getCustomers();
    this.props.getObjects();
    this.props.getContracts();
  }

  updateContent(data) {
    this.props.setLoading(true);
    data._id = this.state.row._id;

    this.props.editSettlement(data).then(
      (res) => {
        console.log("SUCCESS!", res);
        this.props.getSettlements();
        this.props.setLoading(false);
      },
      (err) => {
        console.log("Error!", err);
        this.props.setLoading(false);
      }
    );

    this.setState({
      showModal: false,
    });

    this.props.setSubModalContent({});
  }

  handleAddedImage(row) {
    this.setState({
      expanded: { [row.index]: true },
    });
  }

  closeModal() {
    this.props.setSubModalContent({});
    this.setState({
      showModal: false,
    });
  }

  openModal(row) {
    this.setState({
      showModal: true,
      row,
    });
  }

  deleteSettlement(data) {
    if (window.confirm("Are you sure you want to delete?")) {
      this.props.deleteSettlement(data._id);
    }
  }

  downloadFile(dir) {
    window.open(URL + "/static/" + dir.folder + "/" + dir.fileName, "Window Title", "width=500, height=450");
  }

  deleteFile(stlmnt, fileName) {
    stlmnt.settlementsPhotos = stlmnt.settlementsPhotos.filter((file) => file !== fileName);
    this.props.deleteFile(stlmnt).then((res) => {
      console.log("file deleted: " + res);
    });
  }

  putCustomerDataToStoreAndOpenModal(object) {
    let obj = this.props.objects.find((o) => o._id === object.objectId);
    object.object = obj.objectBrand + " " + obj.objectModel + " " + obj.objectPlates;
    this.props.setSubModalContent(object);
    this.openModal(object);
  }

  cloneObject = function (object) {
    let clonedObject = Object.assign({}, { ...object });
    this.addClonedLabelToProperties(clonedObject);
    this.deleteNotNeededProperties(clonedObject);

    this.props.addObject(clonedObject).then((res) => {
      this.addNewObjectToCustomer(clonedObject, res);
      this.addNewObjectToContract(clonedObject, res);
      this.props.getObjects();
    });
  };

  addClonedLabelToProperties(clonedObject) {
    clonedObject.owner = "cloned_" + clonedObject.owner;
    clonedObject.objectVIN = "cloned_" + clonedObject.objectVIN;
    clonedObject.createdDate = new Date().getTime();
  }

  deleteNotNeededProperties(clonedObject) {
    //  delete clonedObject.contracts; AGREED WITH VITALIY TO KEEP IT
    //  delete clonedObject.customers; AGREED WITH VITALIY TO KEEP IT
    delete clonedObject.settlements;
    delete clonedObject.objectPhotos;
    delete clonedObject._id;
    delete clonedObject.__v;
  }

  addNewObjectToContract(clonedObject, res) {
    clonedObject.contracts.forEach((contractId) => {
      let contractToUpdate = this.props.contracts.filter((contr) => contr._id === contractId);
      contractToUpdate.forEach((contract) => {
        contract.objects.push(res._id);
        this.props.editContract(contract);
      });
    });
  }

  addNewObjectToCustomer(clonedObject, res) {
    clonedObject.customers.forEach((custId) => {
      let customerToUpdate = this.props.customers.filter((cust) => cust._id === custId);
      customerToUpdate.forEach((customer) => {
        customer.objects.push(res._id);
        this.props.editCustomer(customer);
      });
    });
  }

  render() {
    const filters = {
      startEnd: (filter, row) => row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
      includes: (filter, row) => {
        return String(row[filter.id]).includes(filter.value);
      },
      matchSrtr: (filter, rows) => matchSorter(rows, filter.value, { keys: ["firstName"] }),
    };

    return (
      <>
        <SettlementsModal
          isShow={this.state.showModal}
          updateContent={this.updateContent}
          closeModal={this.closeModal}
          openModal={this.openModal}
        />
        <ReactTable
          data={this.props.settlements
            .sort((a, b) => b.createdDate - a.createdDate)
            .map((s) => {
              let foundObj = this.props.objects.find((o) => o._id === s.objectId);
              s.objectVIN = foundObj ? foundObj.objectVIN : "";
              s.objectPlates = foundObj ? foundObj.objectPlates : "";
              return s;
            })}
          filterable
          collapseOnDataChange={false}
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo?.row?.status === "Сплачено/закрито") {
              return {
                style: {
                  color: "rgb(0, 134, 0)",
                },
              };
            } else if (rowInfo?.row?.status === "Відмова/закрито") {
              return {
                style: {
                  color: "red",
                },
              };
            } else {
              return {};
            }
          }}
          ref={this.selectTable}
          onFilteredChange={() => {
            this.setState({ filtered: this.selectTable.current.getResolvedState().sortedData.length });
          }}
          defaultFilterMethod={filters.includes}
          columns={[
            {
              Header: this.state.filtered + "",
              width: 40,
              filterable: false,
              Cell: (row) => {
                return row.index + 1 + ".";
              },
            },
            {
              Header: this.props.t("actions"),
              width: 200,
              accessor: "action",
              Cell: (row) => (
                <ButtonGroup size="small" variant="outlined" color="primary" aria-label="small outlined button group">
                  <Button
                    disabled={this.props.auth.user.role === "client"}
                    onClick={this.putCustomerDataToStoreAndOpenModal.bind(this, row.original)}
                  >
                    <i className="far fa-edit fa-lg"></i>
                  </Button>
                  {/* <Button onClick={this.cloneObject.bind(this, row.original)}>Clone</Button> */}
                  <Button
                    disabled={this.props.auth.user.role === "client"}
                    color="secondary"
                    onClick={this.deleteSettlement.bind(this, row.original)}
                  >
                    <i className="far fa-trash-alt fa-lg"></i>
                  </Button>
                </ButtonGroup>
              ),
            },
            {
              Header: this.props.t("settlementsView.contractNum"),
              width: 200,
              accessor: "contractNum",
            },
            {
              Header: this.props.t("settlementsView.objectVIN"),
              width: 200,
              accessor: "objectVIN",
              Cell: (props) => (
                <span>{this.props.objects.find((objFromProps) => objFromProps._id === props.original.objectId)?.objectVIN}</span>
              ),
            },
            {
              Header: this.props.t("settlementsView.objectPlates"),
              width: 200,
              accessor: "objectPlates",
              Cell: (props) => (
                <span>{this.props.objects.find((objFromProps) => objFromProps._id === props.original.objectId)?.objectPlates}</span>
              ),
            },
            {
              Header: this.props.t("settlementsView.insCompany"),
              width: 200,
              accessor: "insCompany",
            },
            {
              Header: this.props.t("settlementsView.insurer"),
              width: 300,
              accessor: "insurer",
            },
            {
              Header: this.props.t("settlementsView.contractStartDate"),
              width: 200,
              id: "contractStartDate",
              Cell: (props) => (
                <span>
                  {props.original.contractStartDate
                    ? props.original.contractStartDate.substr(0, 2) +
                      "/" +
                      props.original.contractStartDate.substr(2, 2) +
                      "/" +
                      props.original.contractStartDate.substr(4)
                    : null}
                </span>
              ),
            },
            {
              Header: this.props.t("settlementsView.contractEndDate"),
              width: 200,
              id: "contractEndDate",
              Cell: (props) => (
                <span>
                  {props.original.contractEndDate
                    ? props.original.contractEndDate.substr(0, 2) +
                      "/" +
                      props.original.contractEndDate.substr(2, 2) +
                      "/" +
                      props.original.contractEndDate.substr(4)
                    : null}
                </span>
              ),
            },
            {
              Header: this.props.t("settlementsView.incidentDate"),
              width: 200,
              id: "incidentDate",
              Cell: (props) => (
                <span>
                  {props.original.incidentDate
                    ? props.original.incidentDate.substr(0, 2) +
                      "/" +
                      props.original.incidentDate.substr(2, 2) +
                      "/" +
                      props.original.incidentDate.substr(4)
                    : null}
                </span>
              ),
            },
            {
              Header: this.props.t("settlementsView.incidentTime"),
              width: 200,
              accessor: "incidentTime",
            },
            {
              Header: this.props.t("settlementsView.incidentPlace"),
              width: 200,
              accessor: "incidentPlace",
            },
            {
              Header: this.props.t("settlementsView.manager"),
              width: 200,
              accessor: "manager",
            },
            {
              Header: this.props.t("settlementsView.status"),
              width: 200,
              accessor: "status",
            },
            {
              Header: this.props.t("settlementsView.eventType"),
              width: 200,
              accessor: "eventType",
            },
            {
              Header: this.props.t("settlementsView.eventDescription"),
              width: 200,
              accessor: "eventDescription",
            },
            {
              Header: this.props.t("settlementsView.damage"),
              width: 200,
              accessor: "damage",
            },
            {
              Header: this.props.t("settlementsView.thirdPersons"),
              width: 200,
              accessor: "thirdPersons",
            },
            {
              Header: this.props.t("settlementsView.caseNumber"),
              width: 200,
              accessor: "caseNumber",
            },
            {
              Header: this.props.t("settlementsView.comments"),
              width: 200,
              accessor: "comments",
            },
            {
              Header: this.props.t("settlementsView.insFranchise"),
              width: 200,
              accessor: "insFranchise",
            },
            {
              Header: this.props.t("settlementsView.incidentDate"),
              width: 200,
              id: "incidentDate",
              Cell: (props) => (
                <span>
                  {props.original.incidentDate
                    ? props.original.incidentDate.substr(0, 2) +
                      "/" +
                      props.original.incidentDate.substr(2, 2) +
                      "/" +
                      props.original.incidentDate.substr(4)
                    : null}
                </span>
              ),
            },
            {
              Header: this.props.t("settlementsView.guilty"),
              width: 200,
              accessor: "guilty",
            },
            {
              Header: this.props.t("settlementsView.isRegress"),
              width: 200,
              accessor: "isRegress",
            },
          ]}
          defaultPageSize={20}
          className="-striped -highlight"
          SubComponent={(row) => {
            return (
              <div style={{ height: "250px" }}>
                <Tab.Container id="vertical-nav" defaultActiveKey="first">
                  <Row style={{ height: "100%" }}>
                    <Col style={{ maxWidth: 300, height: "100%", marginTop: 5 }}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="first">Documents</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <FilesDragNDrop
                        objectData={{
                          pref: row.original.contractNum,
                          suf: "settlement",
                          // this.props.objects.find((el) => el.id === row.original.objectId).objectVIN,
                          entity: { settlement: row.original },
                        }}
                        docFolder={"settlement"}
                        handleAddedImage={this.handleAddedImage.bind(this, row)}
                      ></FilesDragNDrop>
                    </Col>
                    <Col>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          {/* <div style={{ position: "relative" }}> */}
                          {/* <div
                              className="photos-container"
                              style={{
                                textAlign: "center",
                                display: "inline-block",
                                position: "absolute",
                                width: 400,
                                top: 0,
                              }}
                            ></div> */}
                          <div>
                            <div style={{ display: "inline-block", position: "relative" }}>
                              {row.original.settlementsPhotos.sort().map((fileName, ind) => {
                                let dir = {
                                  folder:
                                    "settlement/" +
                                    row.original.contractNum
                                      .split("")
                                      .filter((el) => ["'", '"', "+", "/", ".", "*"].every((badS) => badS !== el))
                                      .join("") +
                                    "_settlement",
                                  fileName: fileName,
                                };
                                return (
                                  <div
                                    key={ind}
                                    className="filesUploaded"
                                    style={{
                                      margin: "10px 5px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: 150,
                                        height: 150,
                                        border: "1px solid lightgray",
                                        borderRadius: 9,
                                        textAlign: "right",
                                        position: "relative",
                                      }}
                                    >
                                      <div style={{ height: 25 }}>
                                        <p
                                          style={{
                                            overflowWrap: "anywhere",
                                            fontSize: "12px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            paddingLeft: 5,
                                            width: 120,
                                          }}
                                        >
                                          <a
                                            style={{
                                              color: "#033362",
                                              overflowWrap: "anywhere",
                                              fontSize: "12px",
                                            }}
                                            href={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {dir.fileName}
                                          </a>
                                        </p>
                                        <CancelIcon
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            color: "#dc5a5a",
                                          }}
                                          onClick={this.deleteFile.bind(this, row.original, fileName)}
                                        />
                                      </div>
                                      {dir.fileName.substr(dir.fileName.length - 4) !== ".pdf" && (
                                        <div style={{ height: 100, textAlign: "center", background: "#e1e1e1", padding: "2px 0px" }}>
                                          <div
                                            style={{
                                              backgroundImage:
                                                'url("' + URL + "/static/" + dir.folder + "/" + dir.fileName + '")',
                                              height: 110,
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                            }}
                                          ></div>
                                          {/* <img
                                            style={{
                                              width: "auto",
                                              height: "100%",
                                              borderRadius: 5,
                                              color: "#033362",
                                              margin: "0 auto",
                                            }}
                                            src={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                            target="_blank"
                                            alt="doc_image"
                                            rel="noopener noreferrer"
                                          ></img> */}
                                        </div>
                                      )}
                                      {dir.fileName.substr(dir.fileName.length - 4) === ".pdf" && (
                                        <div style={{ position: "absolute", height: 125, overflow: "hidden" }}>
                                          <embed
                                            name="plugin"
                                            width="100%"
                                            src={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                            type="application/pdf"
                                          ></embed>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.helpers.loading,
  customers: state.customers.customersList,
  contracts: state.contracts.contractsList,
  objects: state.objects.objectsList,
  settlements: state.settlements.settlementsList,
  fileList: state.files.fileList,
});

export default connect(mapStateToProps, {
  deleteSettlement,
  getSettlements,
  editSettlement,
  downloadFile,
  deleteFile,
  setSubModalContent,
  getContracts,
  getObjects,
  editObject,
  editContract,
  getCustomers,
  editCustomer,
  addLog,
  setLoading,
})(withTranslation()(SettlementsTable));
