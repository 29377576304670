import React from "react";
import ReactTable from "react-table-6";
import { CLIENTDETAILS } from "../../CONSTANTS";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import i18n from "../../i18n";
import CustomersModal from "../Customers/CustomersModal";
import { setSubModalContent } from "../../actions/modals";
import { connect } from "react-redux";
import { addSlashesToString } from "../../helpers/dateTransformations";

class CustomersSubTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      filteredCount: 0,
    };
  }

  removeLinkWithCustomer = (data) => {
    this.props.row.original.customers = this.props.row.original.customers.filter((el) => el !== data._id);
    this.props.removeCustomer(data);
  };

  setModalContentAndOpenForEdit = (fromRow) => {
    this.props.setSubModalContent(fromRow);

    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    let cols = [];
    CLIENTDETAILS.map((el) => cols.push({ Header: el.title, accessor: el.id }));

    this.formarDate(cols);

    cols.unshift({
      Header: i18n.t("actions"),
      filterable: false,
      accessor: "action",
      Cell: (row) => (
        <ButtonGroup size="small" variant="outlined" color="primary" aria-label="small outlined button group">
          <Button color="primary" onClick={this.setModalContentAndOpenForEdit.bind(this, row.original)}>
            <i className="far fa-edit fa-lg"></i>
          </Button>
          <Button color="secondary" onClick={this.removeLinkWithCustomer.bind(this, row.original)}>
            <i className="far fa-trash-alt fa-lg"></i>
          </Button>
          {/* TODO: REMOVE CUSTOMER FUNC IN OBJECTS/CUSTOMERS/SETTLEMENTS */}
        </ButtonGroup>
      ),
    });

    cols.unshift({
      Header: this.state.filteredCount,
      accessor: "number",
      width: 70,
      filterable: false,
      Cell: (row) => {
        return row.index + 1 + ".";
      },
    });
    return (
      <>
        <CustomersModal isShow={this.state.showModal} closeModal={this.closeModal}></CustomersModal>
        <ReactTable
          data={this.props.customers}
          filterable
          columns={cols}
          onFilteredChange={(f, c) => {
            this.setState({
              filteredCount: this.selectTable.getResolvedState().sortedData.length,
            });
          }}
          ref={(r) => {
            this.selectTable = r;
          }}
          defaultPageSize={5}
          className="-striped -highlight"
        />
      </>
    );
  }

  formarDate(cols) {
    let ind = cols.findIndex((el) => el.accessor === "dateOfBirth");
    let objToExtend = cols[ind];
    objToExtend.Cell = (props) => <span>{props.original.dateOfBirth ? addSlashesToString(props.original.dateOfBirth) : null}</span>;
  }
}

const mapStateToProps = (state) => ({
  modalContent: state.modals.modalContent,
  objects: state.objects.objectsList,
});

export default connect(mapStateToProps, { setSubModalContent })(CustomersSubTable);
