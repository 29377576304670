import React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import TasksTable from "./TasksTable";
import { addTask, getTasks, editTask, deleteTask } from "../../actions/tasks";
import { TASKDETAILS } from "../../CONSTANTS";
// import { prepareObject } from "../../helpers/dataTransformations";
import { actionButtonsContainer, containerStyle, buttonStyle } from "../Common/Styles";
import ClipLoader from "react-spinners/ClipLoader";
import { withTranslation } from "react-i18next";
import TasksModal from "./TasksModal";

export class TasksView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubModal: false,
      subModalViewType: null,
      showModal: false,
    };
    this.props.getTasks(this.props.auth.user);
    this.saveTask = this.saveTask.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  openModal = function () {
    let initial = {};
    TASKDETAILS.forEach((el) => (initial[el.id] = el.value));
    sessionStorage.setItem("taskDraft", JSON.stringify(initial));
    this.setState({
      showModal: true,
    });
  };

  closeModal = function () {
    let initial = {};
    TASKDETAILS.forEach((el) => (initial[el.id] = el.value));
    sessionStorage.setItem("taskDraft", JSON.stringify(initial));
    this.setState({
      showModal: false,
    });
  };

  saveTask(data) {
    data.creator = this.props.auth.user.username;
    this.props.addTask(data).then((res) => {
      this.props.getTasks(this.props.auth.user);
      this.setState({
        showModal: !this.state.showModal,
      });
    });
    let initial = {};
    TASKDETAILS.forEach((el) => (initial[el.id] = el.value));
    sessionStorage.setItem("taskDraft", JSON.stringify(initial));
  }

  // componentDidMount() {
  //   this.props.getObjects().then(objects => {
  //     this.setState({
  //       objects
  //     });
  //   });
  // }

  render() {
    return (
      <>
        {this.props.loading && (
          <div className="sweet-loading">
            <ClipLoader size={150} color={"#123abc"} loading={Boolean(this.props.loading)} />
          </div>
        )}
        <div>
          <TasksModal isShow={this.state.showModal} saveContent={this.saveTask} closeModal={this.closeModal} openModal={this.openModal} />
          <div style={containerStyle}>
            {this.props.auth.user.role !== "client" && (
              <div style={actionButtonsContainer}>
                <Button variant="contained" style={buttonStyle} id="addTaskButton" onClick={this.openModal}>
                  <i className="fas fa-plus-square"></i>
                  {this.props.t("addNew")}
                </Button>
                {/* <Button variant="contained" style={buttonStyle} onClick={this.handleCollapse}><i className={'fas fa-caret-square-' + (Object.keys(this.state.folded).length === 0 ? "left" : "right")}></i> {Object.keys(this.state.folded).length === 0 ? "Collapse" : "Expand" }</Button>                         */}
                {/* <Button variant="contained" style={buttonStyle} ><i className="fas fa-eraser"></i> Clear filters</Button> */}
                <Button variant="contained" style={buttonStyle} onClick={this.exportToExcel}>
                  <i className="fas fa-file-excel"></i> {this.props.t("exportToExcel")}
                </Button>
              </div>
            )}
            <TasksTable />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.helpers.loading,
});

export default connect(mapStateToProps, {
  addTask,
  getTasks,
  editTask,
  deleteTask,
})(withTranslation()(TasksView));
