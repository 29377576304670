export const emptyContract = {
  accountNumber: "",
  address: "",
  bankName: "",
  citizenship: "",
  documentIssueDate: "",
  documentIssuer: "",
  documentNumber: "",
  currency: "",
  documentType: "",
  comment: "",
  contractDate: "",
  contractAgent: "",
  contractEndDate: "",
  contractManager: "",
  contractNum: "",
  contractStartDate: "",
  contractStatus: "",
  contractSubagent: "",
  contractType: "",
  dateOfBirth: "",
  director: "",
  edrpou: "",
  email: "",
  gender: "",
  inn: "",
  insAmount: "",
  insCompany: "",
  insFranchise: "",
  insPremiya: "",
  insTariff: "",
  insType: "",
  insFranchiseType: "",
  insured: "",
  insurer: "",
  manager: "",
  mfo: "",
  name: "",
  objDate: "",
  objectBrand: "",
  objectDescription: "",
  objectAddress: "",
  objectDocIssueDate: "",
  objectDocIssuer: "",
  objectDocNumber: "",
  objectDocType: "",
  objectEmptyWeight: 0,
  objectEngineVolume: 0,
  objectFullWeight: 0,
  objectModel: "",
  objectPhotos: [],
  customers: [],
  objects: [],
  settlements: [],
  objectPlates: "",
  objectVIN: "",
  objectYom: 0,
  owner: "",
  paymentScheme: "",
  paymentParts: {
    first: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    second: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    third: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    fourth: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata0: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata1: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata2: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata3: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata4: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata5: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata6: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata7: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata8: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata9: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata10: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    doplata11: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
  },
  phone: "",
  type: "",
};
