import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

export const CompaniesTable = (props) => {
  const filters = {
    includes: (filter, row) => String(row[filter.id]).includes(filter.value),
  };
  return (
    <>
      <ReactTable
        data={props.companies}
        filterable
        defaultFilterMethod={filters.includes}
        columns={[
          {
            Header: "Legal Name",
            id: "legalName",
            accessor: (d) => d.legalName,
          },
          {
            Header: "Created",
            id: "createdDate",
            accessor: (d) => d.createdDate,
            Cell: (props) => {
              return new Date(props.value).toLocaleDateString();
            },
          },
          {
            Header: "Company ID",
            id: "_id",
            accessor: (d) => d._id,
          },
          {
            Header: "Action",
            id: "action",
            accessor: (d) => d._id,
            Cell: (rowInfo) => {
              console.log(rowInfo);
              return (
                <ButtonGroup size="sm">
                  <Button variant="success" onClick={props.editCompany.bind(this, rowInfo.original)}>
                    EDIT
                  </Button>
                  <Button variant="danger" onClick={props.deleteCompany.bind(this, rowInfo.original._id)}>
                    DELETE
                  </Button>
                </ButtonGroup>
              );
            },
            Filter: <></>,
          },
        ]}
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </>
  );
};
