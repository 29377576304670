import React from "react";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import { CONTRACTDETAILS } from "../../../CONSTANTS";
import NumberFormat from "react-number-format";
// import { fromDBDate, isDate, dateToManipulate } from '../../../helpers/dataTransformations';
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { green, red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";
import { Button } from "@material-ui/core";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

const GreenCheckbox = withStyles({
  checked: {
    "&$checked": {
      color: green[400],
    },
  },
  indeterminate: {
    "&$checked": {
      color: red[300],
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const inputStyles = {
  marginRight: 5,
  marginLeft: 5,
  minWidth: 160,
  width: "calc(92% / 5)",
};

class ContractInfoStep extends React.Component {
  constructor(props) {
    super(props);
    // TODO: ! IMPORTANT: RE-DO EDITING: ADD EXTRA MODAL (FOR EDITING ONLY) TO CONTRACT FOLDER AS IT IS DONE ON OBJECTS, CUSTOMERS
    this.state = {
      contractDetails: this.getContractDetails(),
    };
    props.isNextDisabled();
  }

  getContractDetails() {
    let draftContract = JSON.parse(sessionStorage.getItem("newContractDraft"));
    return CONTRACTDETAILS.map((param) => {
      let val = Object.assign({}, { ...param });
      val.value = draftContract ? draftContract[param.id] : val.value;
      return val;
    }).map((field) => {
      if (typeof field.value === "function") {
        field.value = field.value(this.props.contract);
      }
      return field;
    });
  }

  validateAndUpdate(field, event) {
    // TODO: add validation to input
    if (this.isNotValid(field.type, event)) {
      return;
    }
    this.updateState(field, event);

    this.props.isNextDisabled();

    if (field.id === "contractStartDate") {
      if (event.target.value.length === 8) {
        let stateCopy = Array.from(this.state.contractDetails);
        let day = Number(event.target.value.substr(0, 2));
        let month = Number(event.target.value.substr(2, 2) - 1);
        let year = Number(event.target.value.substr(4));

        // "4/3/2020"
        let newDate = new Date(year + 1, month, day - 1);
        let newDay = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
        let newMon = newDate.getMonth() < 9 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth() + 1;

        stateCopy.find((el) => el.id === "contractEndDate").value = "" + newDay + newMon + newDate.getFullYear();

        this.setState({
          contractDetails: stateCopy,
        });
        this.updateState(field, event);
      }
    }
    if (field.id === "paymentScheme" || field.id === "insPremiya" || field.id === "insAmount" || field.id === "insTariff") {
      let stateCopy = Array.from(this.state.contractDetails);
      if (field.id === "insAmount" || field.id === "insTariff") {
        let amount = stateCopy.find((el) => el.id === "insAmount").value || 0;
        let tarif = stateCopy.find((el) => el.id === "insTariff").value || 0;
        stateCopy.find((el) => el.id === "insPremiya").value = Number((amount * tarif) / 100).toFixed(2);
        this.setState({
          contractDetails: stateCopy,
        });
        this.updateState(field, event);
      }
      let quantityOfPayments = stateCopy.find((el) => el.id === "paymentScheme").value;
      quantityOfPayments = quantityOfPayments ? Number(quantityOfPayments.substr(0, 1)) : 1;

      const getNextPaymentDate = function (i) {
        let currMon = Number(stateCopy.find((el) => el.id === "contractDate").value.substr(2, 2));
        let year = Number(stateCopy.find((el) => el.id === "contractDate").value.substr(4, 4));
        let nextMon = currMon + (12 / quantityOfPayments) * (i - 1);
        let newDate = "";
        if (12 - nextMon < 0) {
          year++;
          currMon = (12 - nextMon) * -1;
          currMon = currMon < 10 ? "0" + currMon : currMon;
          newDate = stateCopy.find((el) => el.id === "contractDate").value.substr(0, 2) + currMon + year;
        } else {
          nextMon = nextMon < 10 ? "0" + nextMon : nextMon;
          newDate = stateCopy.find((el) => el.id === "contractDate").value.substr(0, 2) + nextMon + year;
        }
        return newDate;
      };

      let mapping = {
        1: "first",
        2: "second",
        3: "third",
        4: "fourth",
        5: "doplata0",
      };

      for (let i = 1; i <= 4; i++) {
        if (i <= quantityOfPayments) {
          let amount = stateCopy[this.findElementIndexByID(stateCopy, "insPremiya")].value;
          stateCopy[this.findElementIndexByID(stateCopy, "paymentParts")].value[mapping[i]].amount = amount / quantityOfPayments;
          stateCopy[this.findElementIndexByID(stateCopy, "paymentParts")].value[mapping[i]].date = getNextPaymentDate.call(this, i);
        } else {
          stateCopy[this.findElementIndexByID(stateCopy, "paymentParts")].value[mapping[i]].amount = "";
          stateCopy[this.findElementIndexByID(stateCopy, "paymentParts")].value[mapping[i]].date = null;
        }
      }

      for (let i = 1; i <= quantityOfPayments; i++) {
        let amount = stateCopy[this.findElementIndexByID(stateCopy, "insPremiya")].value;
        stateCopy[this.findElementIndexByID(stateCopy, "paymentParts")].value[mapping[i]].amount = amount / quantityOfPayments;
        stateCopy[this.findElementIndexByID(stateCopy, "paymentParts")].value[mapping[i]].date = getNextPaymentDate.call(this, i);
      }

      let _obj = JSON.parse(sessionStorage.getItem("newContractDraft"));
      stateCopy.forEach((el) => (_obj[el.id] = el.value));
      sessionStorage.setItem("newContractDraft", JSON.stringify(_obj));

      this.setState({
        contractDetails: stateCopy,
      });
      this.updateState(field, event);
    }
  }

  isNotValid(type, event) {
    if (type === "number" && event.target.value.length === 0) {
      return true;
    }

    if (type === "number" && event.target.value) {
      return !/^-{0,1}\d*(\.|,){0,1}\d{0,6}$/g.test(event.target.value);
    }

    return false;
  }

  checkBoxCliked(item) {
    let valueObj = this.state.contractDetails.find((el) => el.id === "paymentParts").value;
    valueObj[item].paid = !valueObj[item].paid;
    this.updateState({ id: "paymentParts" }, { target: { value: valueObj } });
  }

  saveChangeFromConnected(item, i, objFieldName, e) {
    if (e.target.value.slice(-1) !== "," && e.target.value.slice(-1) !== "." && e.target.value.slice(-1) !== "-" && isNaN(e.target.value)) {
      return;
    }
    if (e.target.value.slice(-1) === ",") {
      e.target.value = e.target.value.replace(",", ".");
    }

    let mapping = {
      1: "first",
      2: "second",
      3: "third",
      4: "fourth",
      5: "doplata0",
      6: "doplata1",
      7: "doplata2",
      8: "doplata3",
      9: "doplata4",
      10: "doplata5",
      11: "doplata6",
      12: "doplata7",
      13: "doplata8",
      14: "doplata9",
      15: "doplata10",
      16: "doplata11",
    };

    if (
      (objFieldName === "commissionP" || objFieldName === "amount") &&
      e.target.value.slice(-1) !== "." &&
      e.target.value.slice(-1) !== "-" &&
      e.target.value.length > 0
    ) {
      // save change of /date/
      this.calculateAndSetCommision(item.value[mapping[i]], mapping[i], objFieldName, Number(e.target.value));
    } else {
      let updatedState = this.state.contractDetails.map((field) => {
        if (field.id === "paymentParts") {
          field.value[mapping[i]][objFieldName] = e.target.value;
        }
        return field;
      });
      this.setState({
        contractDetails: updatedState,
      });

      let _obj = JSON.parse(sessionStorage.getItem("newContractDraft"));
      updatedState.forEach((el) => (_obj[el.id] = el.value));
      sessionStorage.setItem("newContractDraft", JSON.stringify(_obj));
    }

    // console.log(item, mapping[i], field);
  }

  calculateAndSetCommision(obj, paymentPart, objFieldName, value) {
    let calculatedCommmissionCurrency = this.calcuateCommissionValue(obj, paymentPart, objFieldName, value);
    calculatedCommmissionCurrency = this.applyCurrency(calculatedCommmissionCurrency);

    let updatedState = this.state.contractDetails.map((field) => {
      if (field.id === "paymentParts") {
        field.value[paymentPart].commissionC = Math.round(calculatedCommmissionCurrency * 100) / 100;
        field.value[paymentPart][objFieldName] = Number(value);
      }
      return field;
    });
    this.setState({
      contractDetails: updatedState,
    });
    let _obj = JSON.parse(sessionStorage.getItem("newContractDraft"));
    updatedState.forEach((el) => (_obj[el.id] = el.value));
    sessionStorage.setItem("newContractDraft", JSON.stringify(_obj));
  }

  applyCurrency(calculatedCommmissionCurrency) {
    if (
      this.state.contractDetails.find((el) => el.id === "currency").value === "USD" ||
      this.state.contractDetails.find((el) => el.id === "currency").value === "EUR"
    ) {
      calculatedCommmissionCurrency *= JSON.parse(localStorage.getItem("rates")).find(
        (el) => el.cc === this.state.contractDetails.find((el) => el.id === "currency").value
      ).rate;
    }
    return calculatedCommmissionCurrency;
  }

  calcuateCommissionValue(obj, paymentPart, field, value) {
    let calculatedCommmissionCurrency;
    if (field === "commissionP") {
      calculatedCommmissionCurrency = (Number(value) * obj.amount) / 100;
    } else {
      calculatedCommmissionCurrency = (Number(value) * obj.commissionP) / 100;
    }
    return calculatedCommmissionCurrency;
  }

  getContentOfConnected(item, e) {
    let quantityOfPayments = this.state.contractDetails.find((el) => el.id === "paymentScheme").value;
    quantityOfPayments = quantityOfPayments ? Number(quantityOfPayments.substr(0, 1)) : 1;
    let content = [];
    const mapping = {
      1: "first",
      2: "second",
      3: "third",
      4: "fourth",
      5: "doplata",
    };

    for (let i = 1; i <= quantityOfPayments; i++) {
      content.push(
        <div key={i + "complex"} style={{ display: "inline-block", width: "100%" }}>
          <div key={i} style={{ display: "inline-block", width: "100%" }}>
            <TextField
              label={i18n.t("contract.payment") + " " + i}
              key={i * 100}
              style={inputStyles}
              variant="outlined"
              value={this.state.contractDetails.find((el) => el.id === "paymentParts").value[mapping[i]].amount}
              onChange={this.saveChangeFromConnected.bind(this, item, i, "amount")}
              margin="normal"
            />
            <TextField
              label={i18n.t("contract.paymentDate")}
              variant="outlined"
              margin="normal"
              key={i * 101}
              style={inputStyles}
              value={this.state.contractDetails.find((el) => el.id === "paymentParts").value[mapping[i]].date}
              // value={getNextPaymentDate.call(this)}
              onChange={this.saveChangeFromConnected.bind(this, item, i, "date")}
              InputProps={{
                inputComponent: this.NumberFormatCustom,
              }}
            />
            <TextField
              label={i18n.t("contract.commissionPercentage")}
              key={i * 102}
              style={inputStyles}
              variant="outlined"
              value={this.state.contractDetails.find((el) => el.id === "paymentParts").value[mapping[i]].commissionP}
              onChange={this.saveChangeFromConnected.bind(this, item, i, "commissionP")}
              margin="normal"
            />
            <TextField
              label={i18n.t("contract.commissionCurrency")}
              key={i * 103}
              style={inputStyles}
              variant="outlined"
              value={this.state.contractDetails.find((el) => el.id === "paymentParts").value[mapping[i]].commissionC}
              onChange={this.saveChangeFromConnected.bind(this, item, i, "commissionC")}
              margin="normal"
            />
            <div
              key={i * 1011}
              style={{
                position: "relative",
                display: "inline-block",
                top: 11,
                width: 63,
                padding: "6px 10px",
                margin: "5px 5px",
                border: "1px solid lightgray",
                borderRadius: 4,
              }}
            >
              <GreenCheckbox
                onChange={this.checkBoxCliked.bind(this, mapping[i])}
                key={i * 1012}
                indeterminate={!this.state.contractDetails.find((el) => el.id === "paymentParts").value[mapping[i]].paid}
                checked
              />
              <span
                id="paymentDone"
                style={{
                  position: "absolute",
                  top: "-9px",
                  left: 8,
                  background: "white",
                  padding: "0px 5px",
                  fontSize: 12,
                  color: "gray",
                }}
              >
                {i18n.t("contract.paymentDone")}
              </span>
            </div>
          </div>
        </div>
      );
    }

    let doplataRows = [];

    // ADDING DOPLATA
    for (let i = 0; i < 12; i++) {
      doplataRows.push(
        <div key={"dop" + i} style={{ display: "inline-block", width: "100%" }}>
          <TextField
            label={i18n.t("contract.doplata") + " " + (i + 1)}
            style={inputStyles}
            variant="outlined"
            value={this.state.contractDetails.find((el) => el.id === "paymentParts").value["doplata" + i]?.amount}
            onChange={this.saveChangeFromConnected.bind(this, item, 5 + i, "amount")}
            margin="normal"
          />
          <TextField
            label={i18n.t("contract.paymentDate")}
            variant="outlined"
            margin="normal"
            style={inputStyles}
            value={this.state.contractDetails.find((el) => el.id === "paymentParts").value?.["doplata" + i]?.date}
            // value={getNextPaymentDate.call(this)}
            onChange={this.saveChangeFromConnected.bind(this, item, 5 + i, "date")}
            InputProps={{
              inputComponent: this.NumberFormatCustom,
            }}
          />
          <TextField
            label={i18n.t("contract.commissionPercentage")}
            style={inputStyles}
            variant="outlined"
            value={this.state.contractDetails.find((el) => el.id === "paymentParts").value?.["doplata" + i]?.commissionP}
            onChange={this.saveChangeFromConnected.bind(this, item, 5 + i, "commissionP")}
            margin="normal"
          />
          <TextField
            label={i18n.t("contract.commissionCurrency")}
            style={inputStyles}
            variant="outlined"
            value={this.state.contractDetails.find((el) => el.id === "paymentParts").value?.["doplata" + i]?.commissionC}
            onChange={this.saveChangeFromConnected.bind(this, item, 5 + i, "commissionC")}
            margin="normal"
          />
          <div
            style={{
              position: "relative",
              display: "inline-block",
              top: 11,
              width: 63,
              padding: "6px 10px",
              margin: "5px 5px",
              border: "1px solid lightgray",
              borderRadius: 4,
            }}
          >
            <GreenCheckbox
              onChange={this.checkBoxCliked.bind(this, "doplata" + i)}
              indeterminate={!this.state.contractDetails.find((el) => el.id === "paymentParts").value?.["doplata" + i]?.paid}
              checked
            />
            <span
              id="paymentDone"
              style={{
                position: "absolute",
                top: "-9px",
                left: 8,
                background: "white",
                padding: "0px 5px",
                fontSize: 12,
                color: "gray",
              }}
            >
              {i18n.t("contract.paymentDone")}
            </span>
          </div>
        </div>
      );
    }
    content.push(
      <Accordion>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="outlined" eventKey="0">
              {i18n.t("contract.doplata")}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{doplataRows}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );

    return content;
  }

  saveData() {
    let preparedData = {};
    this.state.contractDetails.forEach((e) => (preparedData[e.id] = e.value));
    let storageData = JSON.parse(sessionStorage.getItem("newContractDraft"));
    sessionStorage.setItem("newContractDraft", JSON.stringify({ ...storageData, ...preparedData }));
  }

  updateState(field, event) {
    let stateCopy = [...this.state.contractDetails];
    stateCopy[this.findElementIndexByID(stateCopy, field.id)].value =
      field.type === "number" ? event.target.value.replace(",", ".") : event.target.value;
    this.setState({
      contractDetails: stateCopy,
    });
    let ss = JSON.parse(sessionStorage.getItem("newContractDraft"));
    if (ss) {
      ss[field.id] = event.target.value;
      sessionStorage.setItem("newContractDraft", JSON.stringify(ss));
    }
  }

  findElementIndexByID(arr, fieldId) {
    return arr.indexOf(arr.find((el) => el.id === fieldId));
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        allowLeadingZeros={true}
        format="##/##/####"
        placeholder="DD/MM/YYYY"
        mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
      />
    );
  }

  render() {
    return (
      <>
        <Divider />
        {this.state.contractDetails.map((headerItem, ind) => {
          if (headerItem.type === "date") {
            return (
              <TextField
                label={headerItem.title}
                style={inputStyles}
                variant="outlined"
                margin="normal"
                key={ind}
                value={headerItem.value}
                onChange={this.validateAndUpdate.bind(this, headerItem)}
                InputProps={{
                  inputComponent: this.NumberFormatCustom,
                }}
              />
            );
          } else if (headerItem.type === "select") {
            return (
              <TextField
                label={headerItem.title}
                select
                style={inputStyles}
                key={ind}
                rowsMax="4"
                variant="outlined"
                value={headerItem.value}
                onChange={this.validateAndUpdate.bind(this, headerItem)}
                margin="normal"
              >
                {headerItem.options.map((option, id, ind) => (
                  <MenuItem key={ind + id} selected={headerItem.value === option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            );
          } else if (headerItem.type === "connected") {
            return (
              <div
                key={ind}
                style={{
                  position: "relative",
                  display: "inline-block",
                  top: 10,
                  padding: "6px 10px",
                  margin: "5px 5px 15px 5px",
                  width: "95%",
                  border: "1px solid lightgray",
                  borderRadius: 4,
                }}
              >
                {this.getContentOfConnected.call(this, headerItem)}
                <span
                  id="paymentDone"
                  style={{
                    position: "absolute",
                    top: "-9px",
                    left: 8,
                    background: "white",
                    padding: "0px 5px",
                    fontSize: 12,
                    color: "gray",
                  }}
                >
                  {i18n.t("contract.payments")}
                </span>
              </div>
            );
          } else {
            return (
              <TextField
                label={headerItem.title}
                multiline
                style={inputStyles}
                key={ind}
                rowsMax="4"
                placeholder={headerItem.placeholder}
                variant="outlined"
                value={headerItem.value}
                onChange={this.validateAndUpdate.bind(this, headerItem)}
                margin="normal"
              />
            );
          }
        })}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  rates: state.helpers.rates,
  modals: state.modals.modalContent,
});
export default connect(mapStateToProps, {})(ContractInfoStep);
