import React from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { getContracts, getReport, getContractsReport } from "../../actions/contracts";
import { setLoading } from "../../actions/API_helpers";
import { getObjects } from "../../actions/objects";
import { getCustomers } from "../../actions/customers";
import { getTasks } from "../../actions/tasks";
import { getSettlements } from "../../actions/settlements";
import { BarChart, Bar, LabelList, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import i18n from "../../i18n";
import Button from "@material-ui/core/Button";
import ContractsTableForHome from "./ContractsTableForHome";
import { Modal } from "react-bootstrap";
import { URL, HOME_PAGE_CONTRACTS_FILTERS } from "../../CONSTANTS";
import axios from "axios";

const homePage = {
  margin: 30,
  height: "100%",
};

const endingSoon = {
  float: "left",
  width: "49%",
  height: "100%",
};

const homeHeader = {
  textAlign: "center",
  marginBottom: 20,
};

const chartPlot = {
  float: "right",
  width: "50%",
  height: "100%",
};

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      monthly: [],
      currentSales: [],
      payments: { [HOME_PAGE_CONTRACTS_FILTERS.needsPayments]: [], [HOME_PAGE_CONTRACTS_FILTERS.notPaid]: [] },
      prolongations: {
        [HOME_PAGE_CONTRACTS_FILTERS.approachingEnd]: [],
        [HOME_PAGE_CONTRACTS_FILTERS.needsProlongation]: [],
        [HOME_PAGE_CONTRACTS_FILTERS.overdue]: [],
      },
      filter: null,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal = function () {
    // this.getContractsReport();
    sessionStorage.removeItem("key");
    sessionStorage.removeItem("isPayment");
    this.setState({
      isShown: false,
    });
    if (JSON.parse(sessionStorage.getItem("updateRequired"))) {
      this.loadData();
      sessionStorage.removeItem("updateRequired");
    }
  };

  loadData() {
    this.props.setLoading(true);
    this.props.getContracts();
    this.props.getReport(this.props.auth.user.name).then(
      (res) => {
        this.setState({
          monthly: res ? res.monthly : [],
          currentSales: res ? res.currentSales : [],
        });
        this.props.setLoading(false);
      },
      (err) => console.log(err)
    );

    this.props.getCustomers();
    this.props.getObjects();
    this.props.getTasks();
    this.getContractsReport();

    this.getCars();
  }

  getCars = function () {
    axios.get(URL + "/api/cars/getcars", {}).then((res) => {
      let carsJson = JSON.stringify(res.data);

      localStorage.setItem("carBrands", carsJson);
    });
  };

  openModal = function (key, isPayment) {
    sessionStorage.setItem("key", key);
    let type = isPayment ? "paymentRequiredContracts" : "prolongationRequiredContracts";
    sessionStorage.setItem("isPayment", JSON.stringify(type));
    this.setState({
      isShown: true,
    });
  };

  getContractsReport() {
    this.props.setLoading(true);
    this.props.getContractsReport(this.props.auth.user.name).then(
      (res) => {
        if (res) {
          this.setState({
            payments: res.paymentRequiredContracts,
            prolongations: res.prolongationRequiredContracts,
          });
        }
        this.props.setLoading(false);
      },
      (err) => {
        this.props.setLoading(false);
        console.log(err);
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  getDate(contract) {
    if (contract && contract.contractEndDate) {
      let string =
        contract.contractEndDate.substr(0, 2) + "/" + contract.contractEndDate.substr(2, 2) + "/" + contract.contractEndDate.substr(4);
      return string;
    }
    return "---";
  }

  render() {
    return (
      <>
        {this.props.loading && (
          <div className="sweet-loading">
            <ClipLoader size={150} color={"#123abc"} loading={Boolean(this.props.loading)} />
          </div>
        )}

        <Modal show={this.state.isShown} onHide={this.closeModal} size="lg" dialogClassName="modal-90w">
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <ContractsTableForHome filter={this.state.filter} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" onClick={this.closeModal}>
              {i18n.t("close")}
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={homePage}>
          <div style={endingSoon}>
            <div
              style={{
                height: "40%",
                borderRadius: 8,
                padding: 20,
                border: "3px solid #e6e6e6",
              }}
            >
              <h3 style={homeHeader}>{i18n.t("Contracts to be ended soon")}</h3>
              <br />
              <div style={{ width: "100%", textAlign: "left", padding: "0 50px", display: "grid" }}>
                <Button color="primary" onClick={this.openModal.bind(this, HOME_PAGE_CONTRACTS_FILTERS.approachingEnd, null)}>
                  <strong>
                    {i18n.t("Approaching the end")}: {this.state.prolongations[HOME_PAGE_CONTRACTS_FILTERS.approachingEnd].length}
                  </strong>
                </Button>
                <Button className="orange_button" onClick={this.openModal.bind(this, HOME_PAGE_CONTRACTS_FILTERS.needsProlongation, null)}>
                  <strong>
                    {i18n.t("Needs Prolongation")}: {this.state.prolongations[HOME_PAGE_CONTRACTS_FILTERS.needsProlongation].length}
                  </strong>
                </Button>
                <Button color="secondary" onClick={this.openModal.bind(this, HOME_PAGE_CONTRACTS_FILTERS.overdue, null)}>
                  <span>
                    <strong>
                      {i18n.t("Overdue")}: {this.state.prolongations[HOME_PAGE_CONTRACTS_FILTERS.overdue].length}
                    </strong>
                  </span>
                </Button>
              </div>
            </div>

            <br></br>
            {/* *********************** PAYMENTS SECTION ******************** */}
            <div
              style={{
                height: "40%",
                borderRadius: 8,
                padding: 20,
                border: "3px solid #e6e6e6",
              }}
            >
              <h3 style={homeHeader}>{i18n.t("Payments that need to be paid")}</h3>
              <br />
              <div style={{ width: "100%", textAlign: "left", padding: "0 50px", display: "grid" }}>
                <Button
                  className="orange_button"
                  disabled={this.state.payments[HOME_PAGE_CONTRACTS_FILTERS.needsPayments].length === 0}
                  onClick={this.openModal.bind(this, HOME_PAGE_CONTRACTS_FILTERS.needsPayments, true)}
                >
                  <strong>
                    {i18n.t("Needs Payment")}: {this.state.payments[HOME_PAGE_CONTRACTS_FILTERS.needsPayments].length}
                  </strong>
                </Button>
                <Button
                  disabled={this.state.payments[HOME_PAGE_CONTRACTS_FILTERS.notPaid].length === 0}
                  color="secondary"
                  onClick={this.openModal.bind(this, HOME_PAGE_CONTRACTS_FILTERS.notPaid, true)}
                >
                  <span>
                    <strong>
                      {i18n.t("Not paid")}: {this.state.payments[HOME_PAGE_CONTRACTS_FILTERS.notPaid].length}
                    </strong>
                  </span>
                </Button>
              </div>
            </div>
          </div>

          <div style={chartPlot}>
            <div
              style={{
                height: "40%",
                borderRadius: 8,
                padding: 20,
                border: "3px solid #e6e6e6",
              }}
            >
              <h3 style={homeHeader}>{i18n.t("Monthly sales results")}</h3>
              <div style={{ width: "100%", height: "100%" }}>
                <ResponsiveContainer width="100%" height="90%">
                  <BarChart
                    width={800}
                    height={300}
                    data={this.state.monthly}
                    margin={{
                      top: 50,
                      right: 30,
                      left: 25,
                      bottom: 5,
                    }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="month" barSize={30} fill="#033365">
                      <LabelList dataKey="month" position="top" offset="5" fill="#000" angle="0" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <br />
            <div
              style={{
                height: "40%",
                borderRadius: 8,
                padding: 20,
                border: "3px solid #e6e6e6",
              }}
            >
              <h3 style={homeHeader}>{i18n.t("Current month and year total")}</h3>
              <div style={{ width: "100%", height: "100%" }}>
                <ResponsiveContainer width="100%" height="90%">
                  <BarChart
                    layout="vertical"
                    width={900}
                    height={this.state.currentSales.length * 60}
                    data={this.state.currentSales}
                    margin={{
                      top: 5,
                      right: 100,
                      left: 25,
                      bottom: 5,
                    }}
                  >
                    <XAxis type="number" />
                    <YAxis dataKey="name" width={100} type="category" />
                    <Tooltip />
                    <Bar dataKey="result" barSize={25} fill="#033365">
                      <LabelList dataKey="result" position="right" offset="15" fill="#000" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  contracts: state.contracts.contractsList,
  customers: state.customers.customersList,
  loading: state.helpers.loading,
});

export default connect(mapStateToProps, {
  getReport,
  getObjects,
  getContracts,
  getContractsReport,
  getCustomers,
  getTasks,
  getSettlements,
  setLoading,
})(Home);
