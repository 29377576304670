import React from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";

const UsersModal = (props) => {
  let company = { ...props.fromState.currentCompany };
  let newCompany = {};

  return (
    <Modal dialogClassName="modal-90w" show={props.showModal} onHide={props.handleClick}>
      <Modal.Header closeButton>
        <Modal.Title>{company.legalName ? "Edit " + company.legalName : "Add new company"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} controlId="legalName">
            <Form.Label column sm="2">
              Legal Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                onChange={(e) => (newCompany[e.target.id] = e.target.value)}
                defaultValue={company.legalName ? company.legalName : ""}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClick}>
          Close
        </Button>
        <Button variant="primary" onClick={props.handleSave.bind(this, !!company.legalName, newCompany)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UsersModal;
