import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./helpers/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authentication";

import "./App.css";
import Login from "./pages/Login/Login";
import FullWidthTabs from "./pages/Admin/GeneralView";
import ContractsView from "./pages/Contracts/ContractsView";
import Customers from "./pages/Customers/CustomersView";
import Objects from "./pages/Objects/ObjectsView";
import Home from "./pages/Home/Home";
import Settlements from "./pages/Settlements/SettlementsView";
import PrivateRoute from "./pages/Common/PrivateRoute";
import Analitics from "./pages/Analytics/Analytics";
import Tasks from "./pages/Tasks/TasksView";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/";
  }
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/settings" component={FullWidthTabs} />
            <PrivateRoute exact path="/contracts" component={ContractsView} />
            <PrivateRoute exact path="/customers" component={Customers} />
            <PrivateRoute exact path="/objects" component={Objects} />
            <PrivateRoute exact path="/settlements" component={Settlements} />
            <PrivateRoute exact path="/analytics" component={Analitics} />
            <PrivateRoute exact path="/tasks" component={Tasks} />
            <Route exact path="/" component={Login} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
