import axios from "axios";
import { GET_FILE, UPLOAD_FILE, DOWNLOAD_FILE, DELETE_FILE, GET_ERRORS } from "./types";
import { URL } from "../CONSTANTS";

export const getFile = (row) => (dispatch) => {
  return axios
    .get(URL + "/api/getFile", { params: { d: row.original.objDate, o: row.original.owner } })
    .then((res) => {
      dispatch({
        type: GET_FILE,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

//REFACTOR 'params' object below. Document uploads should be universal regardless the page
export const uploadFile = (objectParams, fd) => (dispatch) => {
  return axios
    .post(URL + "/api/upload", fd, {
      headers: { "Content-Type": `multipart/form-data; boundary=${fd._boundary}` },
      params: {
        pref: objectParams.pref,
        suf: objectParams.suf,
        folder: objectParams.folder,
      },
    })
    .then((response) => {
      dispatch({
        type: UPLOAD_FILE,
        payload: response.data,
      });

      return response.data;
    })
    .catch((error) => console.log(error));
};

export const downloadFile = (dir) => (dispatch) => {
  return axios
    .get(URL + "/api/download", { params: { d: dir } })
    .then((response) => {
      dispatch({
        type: DOWNLOAD_FILE,
        payload: response.data,
      });
    })
    .catch((error) => console.log(error));
};

// REFACTOR payload and return
export const deleteFile = (obj) => (dispatch) => {
  return axios
    .put(URL + "/api/deleteFile", { params: obj })
    .then((res) => {
      dispatch({
        type: DELETE_FILE,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};
