import axios from "axios";
import { GET_ERRORS, GET_ALL_USERS, DELETE_USER, EDIT_USER, ADD_USER } from "./types";
import { URL } from "../CONSTANTS";

export const registerUser = (user) => (dispatch) => {
  return axios
    .post(URL + "/api/register", user)
    .then((res) => {
      dispatch({
        type: ADD_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getAllUsers = () => (dispatch) => {
  return axios
    .get(URL + "/api/getallusers")
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const editUser = (user) => (dispatch) => {
  return axios
    .put(URL + "/api/update", { params: user })
    .then((res) => {
      dispatch({
        type: EDIT_USER,
        payload: user,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const deleteUser = (id) => (dispatch) => {
  return axios
    .delete(URL + "/api/delete", { params: { id: id } })
    .then((res) => {
      dispatch({
        type: DELETE_USER,
        payload: { id },
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};
