export const isDate = function (itemName, val) {
  return itemName.includes("Date") || itemName.includes("date");
};

export const toDBDate = function (stringDate) {
  let dateArr = (stringDate.substr(0, 2) + "/" + stringDate.substr(3, 2) + "/" + stringDate.substr(6)).split("/");
  return new Date(dateArr[2], Number(dateArr[1]) - 1, dateArr[0]).getTime();
};

export const dateToPresent = function (stringDate) {
  return stringDate.substr(0, 2) + "/" + stringDate.substr(3, 2) + "/" + stringDate.substr(6);
};

// export const fromDBDate = function (date) {
//     if (date === -1 || !date) { return "missing" }
//     let year = new Date(date).getFullYear();
//     let month = new Date(date).getMonth() < 9 ? "0" + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1 ;
//     let day = new Date(date).getDate() < 9 ? "0" + new Date(date).getDate() : new Date(date).getDate();
//     const SEPARATOR = "/";
//     return day + SEPARATOR + month + SEPARATOR + year;
// }

// export const dateToManipulate = function (date) {
//     if (date === -1 || !date) { return "missing" }
//     let year = new Date(date).getFullYear();
//     let month = new Date(date).getMonth() < 9 ? "0" + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1 ;
//     let day = new Date(date).getDate() < 9 ? "0" + new Date(date).getDate() : new Date(date).getDate();
//     return "" + day + month + year;
// }

export const prepareContract = function (contract) {
  let preparedContract = {
    insType: null,
    insCompany: null,
    contractNum: null,
    contractStartDate: "",
    contractEndDate: "",
    contractDate: "",
    comment: "",
    currency: "",
    insAmount: null,
    insTariff: null,
    insFranchise: 0,
    insFranchiseType: "",
    insPremiya: 0,
    paymentScheme: null,
    paymentParts: {
      first: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      second: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      third: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      fourth: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata0: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata1: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata2: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata3: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata4: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata5: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata6: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata7: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata8: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata9: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata10: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
      doplata11: { amount: "", date: null, commissionP: "", commissionC: "", paid: false },
    },
    contractManager: "",
    contractAgent: "",
    contractSubagent: "",
    contractType: "",
    contractStatus: "",
    customers: [],
    objects: [],
    settlements: [],
    createdDate: new Date().getTime(),
  };

  for (let item in preparedContract) {
    if (contract[item]) {
      preparedContract[item] = contract[item] || preparedContract[item];
    }
  }

  return preparedContract;
};

export const prepareCustomerAndFormatDates = function (data) {
  let preparedCustomer = {
    name: null,
    type: null,
    address: null,
    phone: null,
    email: null,
    dateOfBirth: 0,
    inn: null,
    documentType: null,
    documentNumber: null,
    documentIssuer: null,
    documentIssueDate: null,
    gender: null,
    citizenship: null,
    bankName: null,
    edrpou: null,
    accountNumber: null,
    mfo: null,
    director: null,
    manager: null,
    customerPhotos: [],
    objects: [],
    contracts: [],
    settlements: [],
    createdDate: new Date().getTime(),
  };

  for (let item in preparedCustomer) {
    if (data[item]) {
      preparedCustomer[item] = data[item] || preparedCustomer[item];
      // if (isDate(item)) { preparedCustomer[item] = toDBDate(data[item]) }
    }
  }

  return preparedCustomer;
};

export const prepareObject = function (contract) {
  let preparedObject = {
    objDate: "",
    owner: "",
    objectDocType: "",
    objectDocNumber: "",
    objectDocIssuer: "",
    objectDocIssueDate: "",
    objectBrand: "",
    objectModel: "",
    objectDescription: "",
    objectPlates: "",
    objectVIN: "",
    objectYom: 0,
    objectFullWeight: 0,
    objectEmptyWeight: 0,
    objectEngineVolume: 0,
    objectPhotos: [],
    insurer: "",
    insured: "",
    status: "",
    customers: [],
    contracts: [],
    settlements: [],
    createdDate: new Date().getTime(),
  };

  for (let item in preparedObject) {
    preparedObject[item] = contract[item] || preparedObject[item];
    // if (isDate(item)) { preparedObject[item] =  toDBDate(contract[item]) }
  }

  return preparedObject;
};
