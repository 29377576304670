import React from "react";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="normal"
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          //   input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  let suggestionString = "";
  [
    suggestion?.name,
    suggestion?.contractNum,
    suggestion?.objectBrand,
    suggestion?.objectModel,
    suggestion?.objectPlates,
    suggestion?.objectVIN,
    suggestion?.objectAddress,
    suggestion?.insured,
  ].forEach((param) => {
    if (Boolean(param)) {
      suggestionString += param + " - ";
    }
  });

  suggestionString = suggestionString.substr(0, suggestionString.length - 3);
  const matches = match(suggestionString, query);
  const parts = parse(suggestionString, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, ind) => (
          <span key={ind} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value, suggestions) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const suggestionParam = [
          suggestion?.contractNum,
          suggestion?.name,
          suggestion?.objectPlates,
          suggestion?.objectAddress,
          suggestion?.objectVIN,
          suggestion?.insured,
        ];
        var keep = false;
        if (suggestionParam) {
          keep = count < 10 && suggestionParam.some((param) => param?.toLowerCase().includes(inputValue));
        }

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function getSuggestionValue(suggestion) {
  let suggestionString = "";
  [
    // suggestion?.contractNum,
    suggestion?.name,
    suggestion?.objectBrand,
    suggestion?.objectModel,
    suggestion?.objectPlates,
    suggestion?.objectVIN,
    suggestion?.objectAddress,
    suggestion?.insured,
  ].forEach((param) => {
    if (Boolean(param)) {
      suggestionString += param + " - ";
    }
  });

  suggestionString = suggestionString.substr(0, suggestionString.length - 3);

  return suggestionString;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: "relative",
    marginRight: 5,
    marginLeft: 5,
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  divider: {
    height: theme.spacing(2),
  },
}));

export default function Autosuggester(props) {
  const suggestions = props?.searchList?.length ? props.searchList : [];
  const classes = useStyles();
  const [state, setState] = React.useState({
    single: "",
    popper: "",
  });

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, suggestions));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSelection = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    suggestionValue = "";
    props.onSelection(suggestion);
  };

  const handleChange = (name) => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue,
    });
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    onSuggestionSelected: handleSelection,
    renderSuggestion,
  };

  return (
    <Autosuggest
      {...autosuggestProps}
      inputProps={{
        classes,
        id: "react-autosuggest",
        label: props.title,
        placeholder: "Start to type...",
        value: state.single,
        onChange: handleChange("single"),
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={(options) => (
        <Paper {...options.containerProps} square classes={{ root: "zIndex2" }}>
          {options.children}
        </Paper>
      )}
    />
  );
}
