import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import CustomersModalContent from "./CostomersModalContent";
import Button from "@material-ui/core/Button";
import { emptyContract } from "../Common/emptyContract";
import { CLIENTDETAILS } from "../../CONSTANTS";
import { setLoading } from "../../actions/API_helpers";
import { addCustomer, getCustomers, editCustomer, deleteCustomer } from "../../actions/customers";
import { setSubModalContent } from "../../actions/modals";
import { withTranslation } from "react-i18next";

export class CustomersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentContract: emptyContract,
      isSaveButtonDisabled: true,
    };
    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  isButtonDisabled = (obj) => {
    let clientFieldsConfigs = CLIENTDETAILS.map((el) => Object.assign({}, { ...el }));
    clientFieldsConfigs.forEach((f) => {
      f.mandatory = typeof f.mandatory === "function" ? f.mandatory(obj.insType, obj.type) : f.mandatory;
      return f;
    });
    let fieldsToCheck = Object.keys(obj).filter((el) => clientFieldsConfigs.some((sfel) => sfel.id === el && sfel.mandatory));
    return fieldsToCheck.some((key) => obj[key] === "");
  };

  handleInputChanges(updatedFields) {
    this.setState({
      isSaveButtonDisabled: this.isButtonDisabled(updatedFields),
      currentContract: updatedFields,
    });
  }

  saveAndClose(data, isEditMode, notCloning, event) {
    if (isEditMode) {
      this.props.setLoading(true);

      if (!data._id) {
        data._id = this.props.modalContent._id;
        data.objects = this.props.modalContent.objects;
        data.contracts = this.props.modalContent.contracts;
        data.settlements = this.props.modalContent.settlements;
      }

      this.props.editCustomer(data).then(
        (res) => {
          console.log("SUCCESS From MODAL", res);
          this.props.getCustomers();
          this.props.setLoading(false);
        },
        (err) => {
          console.log("NOT SUCCESS From MODAL!", err);
          this.props.setLoading(false);
        }
      );

      this.props.closeModal();

      this.props.setSubModalContent({});
    } else {
      this.props.saveContent(data);
    }

    this.setState({
      isSaveButtonDisabled: true,
      currentContract: emptyContract,
    });
  }

  closeModal() {
    this.setState({
      isSaveButtonDisabled: true,
      currentContract: emptyContract,
    });

    this.props.closeModal();
  }

  render() {
    const isEditing = Object.keys(this.props.modalContent).length ? true : false;
    const notCloning = true;
    return (
      <>
        <Modal show={this.props.isShow} onHide={this.closeModal} size="lg" dialogClassName="modal-90w">
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? "Edit customer" : "Add new customer"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomersModalContent contract={this.state.currentContract} setData={this.handleInputChanges} />
          </Modal.Body>
          {
            <Modal.Footer>
              <Button
                variant="contained"
                classes={{
                  root: "buttonStyle",
                  disabled: "disabledButtonStyle",
                }}
                onClick={this.closeModal}
              >
                Close
              </Button>
              <Button
                variant="contained"
                classes={{
                  root: "buttonStyle",
                  disabled: "disabledButtonStyle",
                }}
                disabled={this.state.isSaveButtonDisabled}
                onClick={this.saveAndClose.bind(this, this.state.currentContract, isEditing, notCloning)}
              >
                {isEditing ? "Save changes" : "Add"}
              </Button>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modalContent: state.modals.modalContent,
});

export default connect(mapStateToProps, { setLoading, addCustomer, getCustomers, editCustomer, deleteCustomer, setSubModalContent })(
  withTranslation()(CustomersModal)
);
