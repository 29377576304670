import React from "react";
import TextField from "@material-ui/core/TextField";
import Autosuggester from "../../../helpers/autoSuggester";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { OBJECTDETAILS } from "../../../CONSTANTS";
import NumberFormat from "react-number-format";
import Autocomplete from "@material-ui/lab/Autocomplete";

const inputStyles = {
  marginRight: 5,
  marginLeft: 5,
  minWidth: 160,
  width: "calc(92% / 5)",
};

const blockLabel = {
  marginBottom: 0,
  width: "100%",
  fontWeight: "bold",
};

const chipStyle = {
  width: "100%",
  background: "#033365",
  color: "white",
  borderRadius: 4,
};

class ObjectInfoStep extends React.Component {
  constructor(props) {
    super(props);
    this.setPredifinedValues();
    props.isNextDisabled();
    this.state = {
      objects: props.initObjects,
      carBrands: JSON.parse(localStorage.getItem("carBrands")) || [],
      objectFields: this.getObjectFields(),
    };
    this.setCustomerData = this.setCustomerData.bind(this);
  }

  setPredifinedValues() {
    let draftContract = JSON.parse(sessionStorage.getItem("newContractDraft"));
    draftContract.owner = draftContract.owner ? draftContract.owner : draftContract.name;
    draftContract.insurer = draftContract.insurer ? draftContract.insurer : draftContract.name;
    sessionStorage.setItem("newContractDraft", JSON.stringify(draftContract));
  }

  getObjectFields() {
    let draftContract = JSON.parse(sessionStorage.getItem("newContractDraft"));
    return OBJECTDETAILS.map((param) => {
      let val = Object.assign({}, { ...param });
      val.value = draftContract[param.id];
      return val;
    });
  }

  setCustomerData(clientData) {
    this.setState({
      objectFields: this.state.objectFields.map((item) => {
        item.value = clientData[item.id];
        return item;
      }),
    });

    let preparedData = {};
    this.state.objectFields.forEach((e) => (preparedData[e.id] = e.value));
    let storageData = JSON.parse(sessionStorage.getItem("newContractDraft"));
    sessionStorage.setItem("newContractDraft", JSON.stringify({ ...storageData, ...preparedData }));
  }

  validateAndSave(field, event) {
    // TODO: add validation to input
    if (this.isNotValid(field, event)) {
      return;
    }
    this.updateState(field, event);
    this.saveData();

    this.props.isNextDisabled();
  }

  isNotValid(field, event) {
    if (field.type === "number" && event.target.value) {
      return !/^\d*(\.|,){0,1}\d{0,2}$/g.test(event.target.value);
    }

    if (field.id === "objectVIN") {
      return !/^[a-zA-Z\d]{0,17}$/g.test(event.target.value);
    }

    return false;
  }

  getStyle = (item) => {
    let ss = JSON.parse(sessionStorage.getItem("newContractDraft"));
    if (ss.insType === "Майно" || ss.insType === "Відповідальність") {
      let visibleItems = ["objDate", "owner", "objectDescription", "insurer", "status"];
      // item.mandatory = visibleItems.includes(item.id) ? true : item.mandatory;
      return visibleItems.includes(item.id) ? inputStyles : { display: "none" };
    }

    if (ss.insType === "ДМС" || ss.insType === "НВ" || ss.insType === "ВЗК") {
      let visibleItems = ["objDate", "objectDescription", "insurer", "insured", "status"];
      // item.mandatory = visibleItems.includes(item.id) ? true : item.mandatory;
      return visibleItems.includes(item.id) ? inputStyles : { display: "none" };
    }

    if (item.id === "objectAddress" || item.id === "insured") {
      return { display: "none" };
    }

    return item.type === "select"
      ? { display: "inline-block", minWidth: 160, width: "calc(92% / 5)", margin: "16px 5px 8px 5px" }
      : inputStyles;
  };

  saveData() {
    let preparedData = {};
    this.state.objectFields.forEach((e) => (preparedData[e.id] = e.value));
    // this.props.setData({ ...JSON.parse(sessionStorage.getItem("newContractDraft")), ...preparedData });
  }

  updateState(field, event) {
    let stateCopy = [...this.state.objectFields];
    stateCopy[this.findElementIndexByID(stateCopy, field.id)].value =
      field.type === "number" ? event.target.value.replace(",", ".") : event.target.value;
    this.setState({
      objectFields: stateCopy,
    });

    let ss = JSON.parse(sessionStorage.getItem("newContractDraft"));
    if (ss) {
      ss[field.id] = event.target.value;
      sessionStorage.setItem("newContractDraft", JSON.stringify(ss));
    }
  }

  findElementIndexByID(arr, fieldId) {
    return arr.indexOf(arr.find((el) => el.id === fieldId));
  }

  handleAddedImage(image) {
    let temp = this.state.objectFields;
    temp[temp.findIndex((el) => el.id === "objectPhotos")].value.push(image.path);
    this.setState({
      objectFields: temp,
    });
  }

  getOptions(headerItem) {
    // TODO: ObjectsModalContent has the same code. Move to helpers from here and there.
    if (headerItem.id === "objectModel") {
      let foundModelsForBrand = this.state.carBrands.find(
        (brandModels) => brandModels.brand === this.state.objectFields.find((field) => field.id === "objectBrand").value
      );
      return foundModelsForBrand ? foundModelsForBrand.models : [];
    } else if (headerItem.id === "objectBrand") {
      let brands = this.state.carBrands.map((br) => br.brand) || [];
      return brands;
    }

    return headerItem.options;
  }

  getValue(headerItem) {
    return this.state.objectFields.find((field) => field.id === headerItem.id).value;
  }

  setAutocompleteItem = (headerItem, event, selectedBrand) => {
    this.updateState(headerItem, { target: { value: selectedBrand } });
  };

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        allowLeadingZeros={true}
        format="##/##/####"
        placeholder="DD/MM/YYYY"
        mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
      />
    );
  }

  render() {
    return (
      <>
        <div style={blockLabel}>
          <Chip label="Select from existing" style={chipStyle} />
        </div>
        <Autosuggester searchList={this.state.objects} title={"Select object"} onSelection={this.setCustomerData} />
        <br />
        <div style={blockLabel}>
          <Chip label="Add new" style={chipStyle} />
        </div>
        {this.state.objectFields
          .filter((el) => el.id !== "objectPhotos")
          .map((headerItem, ind) => {
            if (headerItem.type === "date") {
              return (
                <TextField
                  label={headerItem.title}
                  style={this.getStyle(headerItem)}
                  variant="outlined"
                  margin="normal"
                  key={ind}
                  value={headerItem.value}
                  onChange={this.validateAndSave.bind(this, headerItem)}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom,
                  }}
                />
              );
            } else if (headerItem.type === "select") {
              return (
                <div key={headerItem.id} style={this.getStyle(headerItem)}>
                  <Autocomplete
                    id={"auto-" + ind}
                    options={this.getOptions(headerItem)}
                    getOptionLabel={(option) => option}
                    value={this.getValue(headerItem)}
                    onChange={this.setAutocompleteItem.bind(this, headerItem)}
                    renderInput={(params) => {
                      return <TextField {...params} key={headerItem.id} label={"Select " + headerItem.title} variant="outlined" />;
                    }}
                  />
                </div>
              );
            } else {
              return (
                <TextField
                  label={headerItem.title}
                  multiline
                  style={this.getStyle(headerItem)}
                  key={ind}
                  rowsMax="4"
                  placeholder={headerItem.placeholder}
                  variant="outlined"
                  value={headerItem.value}
                  onChange={this.validateAndSave.bind(this, headerItem)}
                  margin="normal"
                />
              );
            }
          })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  initObjects: state.objects.objectsList,
  modals: state.modals.modalContent,
});

export default connect(mapStateToProps, {})(ObjectInfoStep);
