import React, { useState, useRef } from "react";
import ReactTable from "react-table-6";
import { SETTLEMENTSDETAILS } from "../../CONSTANTS";

const SettlementsSubTable = (props) => {
  const [filteredCount, setCount] = useState(props.settlements.length);
  const selectTable = useRef(null);
  let cols = [];
  SETTLEMENTSDETAILS.map((el) => cols.push({ Header: el.title, accessor: el.id }));

  cols.unshift({
    Header: filteredCount,
    width: 70,
    filterable: false,
    Cell: (row) => {
      return row.index + 1 + ".";
    },
  });

  return (
    <ReactTable
      data={props.settlements.filter((obj) => {
        return props.row.original.settlements.some((fromContract) => fromContract === obj._id);
      })}
      filterable
      columns={cols}
      onFilteredChange={() => {
        setCount(selectTable.current.getResolvedState().sortedData.length);
      }}
      ref={selectTable}
      defaultPageSize={5}
      className="-striped -highlight"
    />
  );
};

export default SettlementsSubTable;
