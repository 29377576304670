export const actionButtonsContainer = {
    padding: '0 10px',
    border: '1px solid lightgray',
}

export const containerStyle = {
    margin: '30px 5px'
}

export const buttonStyle = {
    background: 'rgb(3, 51, 98)',
    color: 'white',
    margin: '10px 10px 10px 0',
    width: 200
}