import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Autosuggester from "../../helpers/autoSuggester";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { CLIENTDETAILS } from "../../CONSTANTS";
import NumberFormat from "react-number-format";
import i18n from "../../i18n";

const inputStyles = {
  marginRight: 5,
  marginLeft: 5,
  minWidth: 150,
  width: "calc(92% / 5)",
};

const blockLabel = {
  marginBottom: 0,
  width: "100%",
  fontWeight: "bold",
};

const chipStyle = {
  width: "100%",
};

class CustomersModalContent extends React.Component {
  constructor(props) {
    super(props);
    let content = CLIENTDETAILS.map((param) => {
      let val = Object.assign({}, { ...param });
      val.value = props.modalContent[param.id] ? props.modalContent[param.id] : val.value;
      return val;
    });
    this.state = {
      customers: props.initCustomers,
      customerFields: content,
      isEditMode: Object.keys(props.modalContent).length,
    };
    this.setCustomerData = this.setCustomerData.bind(this);
  }
  componentWillUnmount() {
    let erasedValues = CLIENTDETAILS.map((param) => {
      param.value = "";
      return param;
    });

    this.setState({
      customerFields: erasedValues,
    });
  }

  setCustomerData(clientData) {
    this.setState({
      customerFields: this.state.customerFields.map((item) => {
        item.value = clientData[item.id];
        return item;
      }),
    });
    this.props.setData({ ...this.props.contract, ...clientData });
  }

  getStyle = (item) => {
    if (this.state.customerFields.find((f) => f.id === "type").value === "фізична особа") {
      if (item.id === "edrpou" || item.id === "bankName" || item.id === "mfo" || item.id === "accountNumber" || item.id === "director") {
        return { display: "none" };
      }
    }
    if (this.state.customerFields.find((f) => f.id === "type").value === "фізична особа підприємець" && item.id === "director") {
      return { display: "none" };
    }
    if (this.state.customerFields.find((f) => f.id === "type").value === "юридична особа") {
      if (
        item.id === "gender" ||
        item.id === "documentType" ||
        item.id === "documentNumber" ||
        item.id === "documentIssuer" ||
        item.id === "documentIssueDate" ||
        item.id === "documentExpiryDate" ||
        item.id === "citizenship"
      ) {
        return { display: "none" };
      }
    }
    return inputStyles;
  };

  validateAndSave(field, event) {
    // TODO: add validation to input
    if (this.isNotValid(field.type, event)) {
      return;
    }
    this.updateState(field, event);
    this.saveData();
  }

  isNotValid(type, event) {
    if (type === "number" && event.target.value) {
      return !/^\d*(\.|,){0,1}\d{0,2}$/g.test(event.target.value);
    }

    return false;
  }

  saveData() {
    let preparedData = {};
    this.state.customerFields.forEach((e) => (preparedData[e.id] = e.value));
    this.props.setData({ ...this.props.contract, ...preparedData });
  }

  updateState(field, event) {
    let stateCopy = [...this.state.customerFields];
    stateCopy[this.findElementIndexByID(stateCopy, field.id)].value =
      field.type === "number" ? event.target.value.replace(",", ".") : event.target.value;
    this.setState({
      customerFields: stateCopy,
    });
  }

  findElementIndexByID(arr, fieldId) {
    return arr.indexOf(arr.find((el) => el.id === fieldId));
  }

  handleAddedImage(image) {
    let temp = this.state.customerFields;
    temp[temp.findIndex((el) => el.id === "objectPhotos")].value.push(image.path);
    this.setState({
      customerFields: temp,
    });
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        allowLeadingZeros={true}
        format="##/##/####"
        placeholder="DD/MM/YYYY"
        mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
      />
    );
  }

  render() {
    return (
      <>
        {!this.state.isEditMode && (
          <div className="autosuggester-block">
            <div style={blockLabel}>
              <Chip label="Select from existing" style={chipStyle} />
            </div>
            <Autosuggester searchList={this.state.customers} title={i18n.t("Select from existing")} onSelection={this.setCustomerData} />
            <br />
            <div style={blockLabel}>
              <Chip label="Add new" style={chipStyle} />
            </div>
          </div>
        )}
        {this.state.customerFields
          .filter((el) => el.id !== "objectPhotos")
          .map((headerItem, ind) => {
            if (headerItem.type === "date") {
              return (
                <TextField
                  label={headerItem.title}
                  style={this.getStyle(headerItem)}
                  variant="outlined"
                  margin="normal"
                  key={ind}
                  value={headerItem.value}
                  onChange={this.validateAndSave.bind(this, headerItem)}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom,
                  }}
                />
              );
            } else if (headerItem.type === "select") {
              return (
                <TextField
                  label={headerItem.title}
                  select
                  style={this.getStyle(headerItem)}
                  key={ind}
                  rowsMax="4"
                  variant="outlined"
                  value={headerItem.value}
                  onChange={this.validateAndSave.bind(this, headerItem)}
                  margin="normal"
                >
                  {headerItem.options.map((option, id) => (
                    <MenuItem key={id} selected={headerItem.value === option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              );
            } else {
              return (
                <TextField
                  label={headerItem.title}
                  multiline
                  style={this.getStyle(headerItem)}
                  key={ind}
                  rowsMax="4"
                  placeholder={headerItem.placeholder}
                  variant="outlined"
                  value={headerItem.value}
                  onChange={this.validateAndSave.bind(this, headerItem)}
                  margin="normal"
                />
              );
            }
          })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  initCustomers: state.customers.customersList,
  modalContent: state.modals.modalContent,
});

export default connect(mapStateToProps, {})(CustomersModalContent);
