import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import TasksModalContent from "./TasksModalContent";
import Button from "@material-ui/core/Button";
import { setLoading } from "../../actions/API_helpers";
import { addTask, getTasks, editTask, deleteTask } from "../../actions/tasks";

export class TasksModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaveButtonDisabled: false,
      currentTask: JSON.parse(sessionStorage.getItem("taskDraft")),
    };
    this.closeModal = this.closeModal.bind(this);
  }

  saveAndClose(isEditMode, notCloning, event) {
    let data = JSON.parse(sessionStorage.getItem("taskDraft"));
    if (isEditMode) {
      this.props.setLoading(true);
      data.isDone = data.isDone.length >= 3 ? true : false;

      this.props.editTask(data).then(
        (res) => {
          console.log("SUCCESS from Modal!", res);
          this.props.getTasks(this.props.auth.user);
          this.props.setLoading(false);
        },
        (err) => {
          console.log("Error from Modal!", err);
          this.props.setLoading(false);
        }
      );

      this.props.closeModal();
    } else {
      // Hi = 2 ; Tak = 3
      data.isDone = data.isDone.length >= 3 ? true : false;
      this.props.saveContent(data);
    }
  }

  closeModal() {
    this.props.closeModal();
  }

  render() {
    const isEditing = JSON.parse(sessionStorage.getItem("taskDraft"))?.description ? true : false;
    const notCloning = true;

    return (
      <>
        <Modal show={this.props.isShow} onHide={this.closeModal} size="lg" dialogClassName="modal-90w">
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? "Edit task" : "Add new task"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TasksModalContent />
          </Modal.Body>
          {
            <Modal.Footer>
              <Button
                variant="contained"
                classes={{
                  root: "buttonStyle",
                  disabled: "disabledButtonStyle",
                }}
                onClick={this.closeModal}
              >
                Close
              </Button>
              <Button
                variant="contained"
                classes={{
                  root: "buttonStyle",
                  disabled: "disabledButtonStyle",
                }}
                disabled={this.state.isSaveButtonDisabled}
                onClick={this.saveAndClose.bind(this, isEditing, notCloning)}
              >
                {isEditing ? "Save changes" : "Add"}
              </Button>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  tasks: state.tasks.tasksList,
});

export default connect(mapStateToProps, { setLoading, addTask, getTasks, editTask, deleteTask })(TasksModal);
