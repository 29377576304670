import React from "react";
import { connect } from "react-redux";
// import { fromDBDate, isDate, dateToManipulate } from '../../../helpers/dataTransformations';
import { withTranslation } from "react-i18next";
import FilesDragNDrop from "../../Common/DragAndDrop";
import CancelIcon from "@material-ui/icons/Cancel";
import { URL } from "../../../CONSTANTS";

class DocumentsUploadStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cuDocs: [],
      coDocs: [],
      oDocs: [],
    };
  }

  deleteFile(ind, docOwner, e) {
    this.state[docOwner].splice(ind, 1);
    this.setState({
      cuDocs: this.state[docOwner],
    });
  }

  handleAddedImage = (img, docType) => {
    this.state[docType].push(img);
    this.setState({
      cuDocs: this.state.cuDocs,
      coDocs: this.state.coDocs,
      oDocs: this.state.oDocs,
    });
  };

  render() {
    const ss = JSON.parse(sessionStorage.getItem("newContractDraft"));
    return (
      <>
        <div className="row">
          <div className="col-md-4 doc-upload">
            <div className="doc-upload-title">Client Documents</div>
            <div className="doc-upload-dropzone">
              <div>
                <FilesDragNDrop
                  objectData={{ pref: ss.name, suf: ss.inn, entity: this.props.entities }}
                  handleAddedImage={this.handleAddedImage}
                  docFolder={"customer"}
                ></FilesDragNDrop>
              </div>
            </div>
            <div className="doc-upload-uploaded-files">
              {this.state.cuDocs.map((fileName, ind) => {
                let dir = { folder: "customer/" + ss.name + "_" + ss.inn, fileName: fileName };
                return (
                  <div
                    key={ind}
                    className="filesUploaded"
                    style={{
                      margin: "10px 0",
                    }}
                  >
                    <a
                      style={{
                        color: "#033362",
                        padding: "5px 30px 5px 5px",
                        borderRadius: 10,
                        border: "1px solid lightgray",
                      }}
                      href={URL + "/static/" + dir.folder + "/" + dir.fileName}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {dir.fileName}
                    </a>
                    <CancelIcon
                      style={{
                        position: "relative",
                        top: -2,
                        width: 20,
                        right: 25,
                        color: "#dc5a5a",
                      }}
                      onClick={this.deleteFile.bind(this, ind, "cuDocs")}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-4 doc-upload">
            <div className="doc-upload-title">Contract Documents</div>
            <div className="doc-upload-dropzone">
              <FilesDragNDrop
                objectData={{ pref: ss.contractNum, suf: ss.insType, entity: this.props.entities }}
                handleAddedImage={this.handleAddedImage}
                docFolder={"contract"}
              ></FilesDragNDrop>
            </div>
            <div className="doc-upload-uploaded-files">
              {this.state.coDocs.map((fileName, ind) => {
                let dir = { folder: "contract/" + ss.contractNum + "_" + ss.insType, fileName: fileName };
                return (
                  <div
                    key={ind}
                    className="filesUploaded"
                    style={{
                      margin: "10px 0",
                    }}
                  >
                    <a
                      style={{
                        color: "#033362",
                        padding: "5px 30px 5px 5px",
                        borderRadius: 10,
                        border: "1px solid lightgray",
                      }}
                      href={URL + "/static/" + dir.folder + "/" + dir.fileName}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {dir.fileName}
                    </a>
                    <CancelIcon
                      style={{
                        position: "relative",
                        top: -2,
                        width: 20,
                        right: 25,
                        color: "#dc5a5a",
                      }}
                      onClick={this.deleteFile.bind(this, ind, "coDocs")}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-4 doc-upload doc-upload-mid">
            <div className="doc-upload-title">Object Documents</div>
            <div className="doc-upload-dropzone">
              <FilesDragNDrop
                objectData={{ pref: ss.owner, suf: ss.objDate, entity: this.props.entities }}
                handleAddedImage={this.handleAddedImage}
                docFolder={"object"}
              ></FilesDragNDrop>
            </div>
            <div className="doc-upload-uploaded-files">
              {this.state.oDocs.map((fileName, ind) => {
                let dir = { folder: "object/" + ss.owner + "_" + ss.objDate, fileName: fileName };
                return (
                  <div
                    key={ind}
                    className="filesUploaded"
                    style={{
                      margin: "10px 0",
                    }}
                  >
                    <a
                      style={{
                        color: "#033362",
                        padding: "5px 30px 5px 5px",
                        borderRadius: 10,
                        border: "1px solid lightgray",
                      }}
                      href={URL + "/static/" + dir.folder + "/" + dir.fileName}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {dir.fileName}
                    </a>
                    <CancelIcon
                      style={{
                        position: "relative",
                        top: -2,
                        width: 20,
                        right: 25,
                        color: "#dc5a5a",
                      }}
                      onClick={this.deleteFile.bind(this, ind, "oDocs")}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  initCustomers: state.customers.customersList,
  contracts: state.contracts.contractsList,
  modals: state.modals.modalContent,
});

export default connect(mapStateToProps, {})(withTranslation()(DocumentsUploadStep));
