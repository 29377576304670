import React from "react";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { TASKDETAILS } from "../../CONSTANTS";
import NumberFormat from "react-number-format";
import Autocomplete from "@material-ui/lab/Autocomplete";

const inputStyles = {
  marginRight: 5,
  marginLeft: 5,
  minWidth: 150,
  width: "calc(92% / 5)",
};

const blockLabel = {
  marginBottom: 0,
  width: "100%",
  fontWeight: "bold",
};

const chipStyle = {
  width: "100%",
};

export class TaskModalContent extends React.Component {
  constructor(props) {
    super(props);
    let content = TASKDETAILS.map((param) => {
      let val = Object.assign({}, { ...param });
      val.value = JSON.parse(sessionStorage.getItem("taskDraft"))[param.id];
      return val;
    });
    this.state = {
      tasks: props.initTasks,
      taskFields: content,
      isEditMode: Object.keys(props.modalContent).length,
    };
  }

  componentWillUnmount() {
    let erasedValues = Object.assign([], { ...this.state.taskFields });
    erasedValues = erasedValues.forEach((param) => {
      param.value = "";
      return param;
    });

    this.setState({
      taskFields: erasedValues,
    });
  }

  validateAndSave(field, event) {
    // TODO: add validation to input
    if (this.isNotValid(field.type, event)) {
      return;
    }
    this.saveData(field, event);
  }

  isNotValid(type, event) {
    if (type === "number" && event.target.value) {
      return !/^\d[\d.]*$/g.test(event.target.value);
    }

    return false;
  }

  getValue(headerItem) {
    let ssData = JSON.parse(sessionStorage.getItem("taskDraft"));
    if (headerItem.id === "isDone") {
      return ssData[headerItem.id]?.length === 3 ? headerItem.options[0] : headerItem.options[1];
    }
    return ssData[headerItem.id];
  }

  saveData(field, event) {
    let updated = Object.assign({ ...JSON.parse(sessionStorage.getItem("taskDraft")) }, { [field.id]: event.target.value });
    sessionStorage.setItem("taskDraft", JSON.stringify(updated));
    this.forceUpdate();
  }

  findElementIndexByID(arr, fieldId) {
    return arr.indexOf(arr.find((el) => el.id === fieldId));
  }

  setAutocompleteItem = (headerItem, event, selectedValue) => {
    this.saveData(headerItem, { target: { value: selectedValue } });
  };

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        allowLeadingZeros={true}
        format="##/##/####"
        placeholder="DD/MM/YYYY"
        mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
      />
    );
  }

  render() {
    return (
      <>
        {!this.state.isEditMode && (
          <div className="autosuggester-block">
            <div style={blockLabel}>
              <Chip label="Add new" style={chipStyle} />
            </div>
          </div>
        )}
        {this.state.taskFields.map((headerItem, ind) => {
          if (headerItem.type === "date") {
            return (
              <TextField
                label={headerItem.title}
                style={inputStyles}
                variant="outlined"
                margin="normal"
                key={ind}
                value={this.getValue(headerItem)}
                onChange={this.validateAndSave.bind(this, headerItem)}
                InputProps={{
                  inputComponent: this.NumberFormatCustom,
                }}
              />
            );
          } else if (headerItem.type === "select") {
            return (
              <div key={headerItem.id + 1} style={{ display: "inline-block", width: 200, margin: "16px 5px 8px 5px" }}>
                <Autocomplete
                  id={headerItem.id + ind}
                  key={headerItem.id}
                  value={this.getValue(headerItem)}
                  options={headerItem.options}
                  getOptionLabel={(option) => option}
                  onChange={this.setAutocompleteItem.bind(this, headerItem)}
                  renderInput={(params) => {
                    return <TextField {...params} key={headerItem.id} label={headerItem.title} variant="outlined" />;
                  }}
                />
              </div>
            );
          } else {
            return (
              <TextField
                label={headerItem.title}
                multiline
                style={inputStyles}
                key={ind}
                rowsMax="4"
                placeholder={headerItem.placeholder}
                variant="outlined"
                value={this.getValue(headerItem)}
                onChange={this.validateAndSave.bind(this, headerItem)}
                margin="normal"
              />
            );
          }
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  initTasks: state.tasks.tasksList,
  modalContent: state.modals.modalContent,
});

export default connect(mapStateToProps, {})(TaskModalContent);
