import i18n from "./i18n";
const { REACT_APP_ENV } = process.env;

export const URL = REACT_APP_ENV === "production" ? "http://212.109.44.43:5000" : "http://localhost:5000";

export const HOME_PAGE_CONTRACTS_FILTERS = {
  // when update here, also update in server/contracts/reporting
  approachingEnd: 21,
  needsProlongation: 14,
  needsPayments: 14,
  notPaid: 0,
  overdue: 2,
};

export const CONTRACTDETAILS = [
  {
    title: i18n.t("contract.type"),
    id: "insType",
    placeholder: "",
    type: "select",
    options: ["КАСКО", "ОСЦПВ", "ДСЦПВ", "Зелена Картка", "НВ на транспорті", "ПВ", "Майно", "Відповідальність", "ДМС", "НВ", "ВЗК", "ДСБ"],
    mandatory: true,
  },
  {
    title: i18n.t("contract.company"),
    id: "insCompany",
    placeholder: "",
    type: "select",
    options: [
      'ПрАТ "СК "УНІКА"',
      'ПАТ "СК "Євроінс Україна"',
      'ПрАТ "СК "ПЗУ Україна"',
      'АТ "СК "АРКС"',
      'ТДВ "Альянс Україна"',
      'ПрАТ "СК "Колоннейд Україна"',
	  'ПрАТ "СК "ВУСО"',
      'АТ СК "ІНГО"',
	  'ТДВ СК "АЛЬФА-ГАРАНТ"',
      'ПрАТ "СК"Трансмагістраль"',
      'ПрАТ "СК "Українська страхова група"',
      'ПрАТ "УАСК АСКА"',
      'ПрАТ "Європейський страховий альянс"',
      'ПрАТ "СК "Альфа Страхування"',
      'ПрАТ "СК "Універсальна"',
      'ПрАТ "СК "Арсенал Страхування"',
      'ПрАТ "УСК "Княжа Вієнна Іншуранс Груп"',
      'ПрАТ "Європейське туристичне страхування"',
      'ПрАТ "СК "ПРОВІДНА"',
      'ПрАТ "СГ "ТАС"',
    ],
    mandatory: true,
  },
  {
    title: i18n.t("contract.contractNum"),
    id: "contractNum",
    placeholder: "",
    type: "string",
    mandatory: true,
  },
  {
    title: i18n.t("contract.contractStartDate"),
    id: "contractStartDate",
    placeholder: "",
    type: "date",
    mandatory: true,
  },
  {
    title: i18n.t("contract.contractEndDate"),
    id: "contractEndDate",
    placeholder: "",
    type: "date",
    mandatory: true,
  },
  {
    title: i18n.t("contract.contractDate"),
    id: "contractDate",
    placeholder: "",
    type: "date",
    mandatory: true,
  },
  {
    title: i18n.t("contract.comment"),
    id: "comment",
    placeholder: "",
    type: "string",
    mandatory: false,
  },
  {
    title: i18n.t("contract.insAmount"),
    id: "insAmount",
    placeholder: "1234",
    type: "number",
    mandatory: true,
  },
  {
    title: i18n.t("contract.currency"),
    id: "currency",
    placeholder: "UAH",
    type: "select",
    options: ["UAH", "USD", "EUR"],
    mandatory: true,
  },
  {
    title: i18n.t("contract.insTariff"),
    id: "insTariff",
    placeholder: "12",
    type: "number",
    mandatory: false,
  },
  {
    title: i18n.t("contract.insFranchiseType"),
    id: "insFranchiseType",
    placeholder: "5",
    type: "select",
    options: ["%", "UAH", "USD", "EUR"],
    mandatory: true,
  },
  {
    title: i18n.t("contract.insFranchise"),
    id: "insFranchise",
    placeholder: "5",
    type: "string",
    mandatory: true,
  },
  {
    title: i18n.t("contract.insPremiya"),
    id: "insPremiya",
    placeholder: "123",
    type: "number",
    mandatory: true,
  },
  {
    title: i18n.t("contract.paymentScheme"),
    id: "paymentScheme",
    placeholder: "1-н платіж",
    type: "select",
    options: ["1-н платіж", "2-а платежі", "3-и платежі", "4-и платежі"],
    mandatory: true,
  },
  {
    title: i18n.t("contract.paymentParts"),
    id: "paymentParts",
    placeholder: "",
    type: "connected",
    mandatory: true,
  },
  {
    title: i18n.t("contract.contractManager"),
    id: "contractManager",
    placeholder: "",
    type: "select",
    options: ["Абельмас Н.П.", "Авраменко Е.", "Тепляшина О.В.", "Кузнєцов В.А.", "Самар К.С.", "Парфенюк В.Л.", "Крещук Т.А.", "Богачова А.Я.", "Тихоненко Т.О.", "Лузан Р.П."],
    mandatory: true,
  },
  {
    title: i18n.t("contract.contractAgent"),
    id: "contractAgent",
    placeholder: "",
    type: "select",
    options: [
      "",
      "Амельчиць Я.С.",
      "Андреєв Д.",
      "Балкевич М.В.",
      "Василенко А.М.",
      "Куріко В.В.",
      "Левик Р.О.",
      "Ломакіна О.",
      "Попова Ю.А.",
      "Попович А.",
      "Потьомкіна С.М.",
      "Савін А.",
      "Самелюк І.Л.",
      "Солодова Ю.М.",
      "СТО Роял Сервис",
      "Томінець В.М.",
      "Цуканова О.В",
    ],
    mandatory: false,
  },
  {
    title: i18n.t("contract.contractSubagent"),
    id: "contractSubagent",
    placeholder: "",
    type: "string",
    mandatory: false,
  },
  {
    title: i18n.t("contract.contractType"),
    id: "contractType",
    placeholder: "",
    type: "select",
    options: ["L", "P"],
    mandatory: true,
  },
  {
    title: i18n.t("contract.contractStatus"),
    id: "contractStatus",
    placeholder: "",
    type: "select",
    options: ["діючий", "недіючий", "прострочений", "пролонгований", "розторгнутий"],
    mandatory: true,
  },
];

export const OBJECTDETAILS = [
  {
    title: i18n.t("objectsView.objDate"),
    id: "objDate",
    placeholder: "",
    type: "date",
    mandatory: true,
  },
  {
    title: i18n.t("objectsView.owner"),
    id: "owner",
    placeholder: "",
    type: "string",
    mandatory: function (insType) {
      if (insType === "Майно" || insType === "Відповідальність") {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectDocType"),
    id: "objectDocType",
    placeholder: "",
    type: "string",
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectDocNumber"),
    id: "objectDocNumber",
    placeholder: "",
    type: "string",
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectDocIssuer"),
    id: "objectDocIssuer",
    placeholder: "",
    type: "string",
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectDocIssueDate"),
    id: "objectDocIssueDate",
    placeholder: "",
    type: "date",
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectBrand"),
    id: "objectBrand",
    placeholder: "",
    type: "select",
    options: [],
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectModel"),
    id: "objectModel",
    placeholder: "",
    type: "select",
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectDescription"),
    id: "objectDescription",
    placeholder: "",
    type: "string",
    mandatory: false,
  },
  {
    title: i18n.t("objectsView.objectPlates"),
    id: "objectPlates",
    placeholder: "",
    type: "string",
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectVIN"),
    id: "objectVIN",
    placeholder: "",
    type: "string",
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectYom"),
    id: "objectYom",
    placeholder: "2010",
    type: "number",
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.objectFullWeight"),
    id: "objectFullWeight",
    placeholder: "2000",
    type: "number",
    mandatory: false,
  },
  {
    title: i18n.t("objectsView.objectEmptyWeight"),
    id: "objectEmptyWeight",
    placeholder: "2000",
    type: "number",
    mandatory: false,
  },
  {
    title: i18n.t("objectsView.objectEngineVolume"),
    id: "objectEngineVolume",
    placeholder: "2449",
    type: "number",
    mandatory: function (insType) {
      if (
        insType === "КАСКО" ||
        insType === "НВ на транспорті" ||
        insType === "ОСЦПВ" ||
        insType === "ДСЦПВ" ||
        insType === "Зелена Картка" ||
        insType === "ПВ"
      ) {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.insurer"),
    id: "insurer",
    placeholder: "",
    type: "string",
    mandatory: true,
  },
  {
    title: i18n.t("objectsView.insured"),
    id: "insured",
    placeholder: "",
    type: "string",
    mandatory: function (insType) {
      if (insType === "ДМС" || insType === "НВ" || insType === "ВЗК") {
        return true;
      }
      return false;
    },
  },
  {
    title: i18n.t("objectsView.status"),
    id: "status",
    placeholder: "Активний",
    type: "select",
    options: ["Активний", "Не активний"],
    mandatory: true,
  },
];

export const CLIENTDETAILS = [
  {
    title: i18n.t("clientInfoStep.clientName"),
    id: "name",
    placeholder: "",
    type: "string",
    mandatory: true,
  },
  {
    title: i18n.t("clientInfoStep.type"),
    id: "type",
    placeholder: "",
    type: "select",
    options: ["фізична особа", "юридична особа", "фізична особа підприємець"],
    mandatory: true,
  },
  {
    title: i18n.t("clientInfoStep.address"),
    id: "address",
    placeholder: "",
    type: "string",
    mandatory: true,
  },
  {
    title: i18n.t("clientInfoStep.phoneNumber"),
    id: "phone",
    placeholder: "",
    type: "number",
    mandatory: true,
  },
  {
    title: i18n.t("clientInfoStep.email"),
    id: "email",
    placeholder: "",
    type: "email",
    mandatory: true,
  },
  {
    title: i18n.t("clientInfoStep.dateOfBirth"),
    id: "dateOfBirth",
    placeholder: "",
    type: "date",
    mandatory: true,
  },
  {
    title: i18n.t("clientInfoStep.inn"),
    id: "inn",
    placeholder: "",
    type: "number",
    mandatory: true,
  },
  {
    title: i18n.t("clientInfoStep.documentType"),
    id: "documentType",
    placeholder: "",
    type: "select",
    options: ["Паспорт", "Паспорт закордонний", "Посвідчення водія", "Пенсійне посвідчення", "Чорнобильське", "Посвідка на проживання"],
    mandatory: function getMandatory(insType, clientType) {
      if (clientType) {
        if (clientType === "юридична особа") {
          return false;
        }

        return true;
      }

      return getMandatory;
    },
  },
  {
    title: i18n.t("clientInfoStep.documentNumber"),
    id: "documentNumber",
    placeholder: "",
    type: "string",
    mandatory: function getMandatory(insType, clientType) {
      if (clientType) {
        if (clientType === "юридична особа") {
          return false;
        }

        return true;
      }

      return getMandatory;
    },
  },
  {
    title: i18n.t("clientInfoStep.documentIssuer"),
    id: "documentIssuer",
    placeholder: "",
    type: "string",
    mandatory: function getMandatory(insType, clientType) {
      if (clientType) {
        if (clientType === "юридична особа") {
          return false;
        }

        return true;
      }

      return getMandatory;
    },
  },
  {
    title: i18n.t("clientInfoStep.documentIssueDate"),
    id: "documentIssueDate",
    placeholder: "",
    type: "date",
    mandatory: function getMandatory(insType, clientType) {
      if (clientType) {
        if (clientType === "юридична особа") {
          return false;
        }

        return true;
      }

      return getMandatory;
    },
  },
  {
    title: i18n.t("clientInfoStep.documentExpiryDate"),
    id: "documentExpiryDate",
    placeholder: "",
    type: "date",
    mandatory: false,
  },
  {
    title: i18n.t("clientInfoStep.gender"),
    id: "gender",
    placeholder: "",
    type: "select",
    options: [i18n.t("clientInfoStep.male"), i18n.t("clientInfoStep.female"), "-", ""],
    mandatory: false,
  },
  {
    title: i18n.t("clientInfoStep.citizenship"),
    id: "citizenship",
    placeholder: "",
    type: "string",
    mandatory: false,
  },
  {
    title: i18n.t("clientInfoStep.comments"),
    id: "comments",
    placeholder: "",
    type: "string",
    mandatory: false,
  },
  {
    title: i18n.t("clientInfoStep.bankName"),
    id: "bankName",
    placeholder: "",
    type: "string",
    mandatory: function getMandatory(insType, clientType) {
      if (clientType) {
        if (clientType === "фізична особа") {
          return false;
        }

        return true;
      }

      return getMandatory;
    },
  },
  {
    title: i18n.t("clientInfoStep.edrpou"),
    id: "edrpou",
    placeholder: "",
    type: "number",
    mandatory: function getMandatory(insType, clientType) {
      if (clientType) {
        if (clientType === "фізична особа") {
          return false;
        }

        return true;
      }

      return getMandatory;
    },
  },
  {
    title: i18n.t("clientInfoStep.accountNumber"),
    id: "accountNumber",
    placeholder: "",
    type: "string",
    mandatory: function getMandatory(insType, clientType) {
      if (clientType) {
        if (clientType === "фізична особа") {
          return false;
        }

        return true;
      }

      return getMandatory;
    },
  },
  {
    title: i18n.t("clientInfoStep.mfo"),
    id: "mfo",
    placeholder: "",
    type: "number",
    mandatory: function getMandatory(insType, clientType) {
      if (clientType) {
        if (clientType === "фізична особа") {
          return false;
        }

        return true;
      }

      return getMandatory;
    },
  },
  {
    title: i18n.t("clientInfoStep.director"),
    id: "director",
    placeholder: "",
    type: "string",
    mandatory: function getMandatory(insType, clientType) {
      if (clientType) {
        if (clientType === "фізична особа") {
          return false;
        }

        return true;
      }

      return getMandatory;
    },
  },
  {
    title: i18n.t("clientInfoStep.manager"),
    id: "manager",
    placeholder: "",
    type: "select",
    options: ["Абельмас Н.П.", "Авраменко Е.", "Кузнєцов В.А.", "Тепляшина О.В.", "Парфенюк В.Л.", "Самар К.С.", "Крещук Т.А.", "Тихоненко Т.О.", "Лузан Р.П."],
    mandatory: true,
  },
];

export const SETTLEMENTSDETAILS = [
  {
    title: i18n.t("settlementsView.contractNum"),
    id: "contractNum",
    placeholder: "",
    type: "string",
    mandatory: true,
    isDisabledInEdit: true,
  },
  {
    title: i18n.t("settlementsView.object"),
    id: "object",
    placeholder: "",
    type: "string",
    mandatory: false,
    isDisabledInEdit: true,
  },
  {
    title: i18n.t("settlementsView.insCompany"),
    id: "insCompany",
    placeholder: "",
    type: "string",
    mandatory: true,
    isDisabledInEdit: true,
  },
  {
    title: i18n.t("settlementsView.insurer"),
    id: "insurer",
    placeholder: "",
    type: "string",
    mandatory: true,
    isDisabledInEdit: true,
  },
  {
    title: i18n.t("settlementsView.contractEndDate"),
    id: "contractEndDate",
    placeholder: "",
    type: "date",
    mandatory: true,
    isDisabledInEdit: true,
  },
  {
    title: i18n.t("settlementsView.contractStartDate"),
    id: "contractStartDate",
    placeholder: "",
    type: "date",
    mandatory: true,
    isDisabledInEdit: true,
  },
  {
    title: i18n.t("settlementsView.incidentDate"),
    id: "incidentDate",
    placeholder: "",
    type: "date",
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.incidentTime"),
    id: "incidentTime",
    placeholder: "",
    type: "time",
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.incidentPlace"),
    id: "incidentPlace",
    placeholder: "",
    type: "string",
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.manager"),
    id: "manager",
    placeholder: "",
    type: "select",
    options: ["Абельмас Н.П.", "Авраменко Е.", "Кузнєцов В.А.", "Тепляшина О.В.", "Самар К.С.", "Парфенюк В.Л.", "Крещук Т.А.", "Тихоненко Т.О.", "Лузан Р.П."],
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.status"),
    id: "status",
    placeholder: "",
    type: "select",
    options: [
      i18n.t("settlementsView.collecting"),
      i18n.t("settlementsView.review"),
      i18n.t("settlementsView.agreed_not_paid"),
      i18n.t("settlementsView.paid"),
      i18n.t("settlementsView.refused"),
    ],
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.eventType"),
    id: "eventType",
    placeholder: "",
    type: "select",
    options: [
      i18n.t("settlementsView.dtp"),
      i18n.t("settlementsView.total"),
      i18n.t("settlementsView.glass"),
      i18n.t("settlementsView.theft"),
      i18n.t("settlementsView.third"),
      i18n.t("settlementsView.disaster"),
      i18n.t("settlementsView.other"),
    ],
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.damage"),
    id: "damage",
    placeholder: "",
    type: "string",
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.eventDescription"),
    id: "eventDescription",
    placeholder: "",
    type: "string",
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.thirdPersons"),
    id: "thirdPersons",
    placeholder: "",
    type: "string",
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.caseNumber"),
    id: "caseNumber",
    placeholder: "",
    type: "string",
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.comments"),
    id: "comments",
    placeholder: "",
    type: "string",
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.insFranchise"),
    id: "insFranchise",
    placeholder: "",
    type: "number",
    mandatory: true,
    isDisabledInEdit: true,
  },
  {
    title: i18n.t("settlementsView.paymentDate"),
    id: "paymentDate",
    placeholder: "",
    type: "date",
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.guilty"),
    id: "guilty",
    placeholder: "",
    type: "select",
    options: [i18n.t("yes"), i18n.t("no"), i18n.t("settlementsView.both")],
    mandatory: true,
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("settlementsView.isRegress"),
    id: "isRegress",
    placeholder: "",
    type: "select",
    options: [i18n.t("yes"), i18n.t("no")],
    mandatory: true,
    isDisabledInEdit: false,
  },
];

export const TASKDETAILS = [
  {
    title: i18n.t("tasksView.responsible"),
    id: "responsible",
    placeholder: "",
    type: "select",
    options: ["Кузнєцов В.А.", "Авраменко Е.", "Тепляшина О.В.", "Самар К.С.", "Крещук Т.А.", "Парфенюк В.Л.", "Тихоненко Т.О.", "Лузан Р.П."],
    mandatory: true,
    value: "",
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("tasksView.dueDate"),
    id: "dueDate",
    placeholder: "",
    type: "date",
    mandatory: true,
    value: "",
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("tasksView.description"),
    id: "description",
    placeholder: "",
    type: "string",
    mandatory: true,
    value: "",
    isDisabledInEdit: false,
  },
  {
    title: i18n.t("tasksView.isDone"),
    id: "isDone",
    placeholder: "",
    type: "select",
    options: [i18n.t("yes"), i18n.t("no")],
    mandatory: true,
    value: "",
    isDisabledInEdit: false,
  },
];
