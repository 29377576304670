export const stringToDate = (value) => {
  if (!value) return null;
  var year = value.substr(4, 4);
  var month = Number(value.substr(2, 2)) - 1;
  var day = value.substr(0, 2);

  return new Date(year, month, day);
};

export const addSlashesToString = (value) => {
  if (!value) return null;
  var splitted = value.split("");
  splitted.splice(2, 0, "/");
  splitted.splice(5, 0, "/");
  return splitted.join("");
};
