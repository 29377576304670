import axios from 'axios';
import { GET_EXRATE, LOADING, LOGGING, GET_ERRORS } from './types';
import { URL } from '../CONSTANTS';

export const getExRate = () => dispatch => {
    return axios.get(URL + '/api/getexrate')
        .then(res => {
            dispatch({
                type: GET_EXRATE,
                payload: res.data
            });
            return res.data
        })
        .catch(err => {
            console.log(err)
        });
}

export const addLog = (data) => dispatch => {
    return axios.post(URL + '/api/logs/add', data)
        .then(res => {
            dispatch({
                type: LOGGING,
                log: res.data
            })

            return res.data
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response ? err.response.data : err
            });
        });
}

export const setLoading = (on) => dispatch => {
    dispatch({
        type: LOADING,
        payload: on ? on : false
    })
}