import React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import ObjectsTable from "./ObjectsTable";
// import { mockedData } from '../customers'
import { addObject, getObjects, editObject, deleteObject } from "../../actions/objects";
import ObjectsModal from "./ObjectsModal";
import { prepareObject } from "../../helpers/dataTransformations";
import { actionButtonsContainer, containerStyle, buttonStyle } from "../Common/Styles";
import { withTranslation } from "react-i18next";

export class ObjectsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubModal: false,
      subModalViewType: null,
      showModal: false,
    };
    this.props.getObjects();
    this.saveObject = this.saveObject.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  openModal = function () {
    this.setState({
      showModal: true,
    });
  };

  closeModal = function () {
    this.setState({
      showModal: false,
    });
  };

  saveObject(data) {
    let preparedObject = prepareObject(data);

    this.props.addObject(preparedObject).then((res) => {
      this.props.getObjects();
      this.setState({
        showModal: !this.state.showModal,
      });
    });
  }

  render() {
    return (
      <>
        <div>
          <ObjectsModal
            isShow={this.state.showModal}
            saveContent={this.saveObject}
            closeModal={this.closeModal}
            openModal={this.openModal}
          />
          <div style={containerStyle}>
            {this.props.auth.user.role !== "client" && (
              <div style={actionButtonsContainer}>
                <Button variant="contained" style={buttonStyle} id="addObjectButton" onClick={this.openModal}>
                  <i className="fas fa-plus-square"></i>
                  {this.props.t("addNew")}
                </Button>
                {/* <Button variant="contained" style={buttonStyle} onClick={this.handleCollapse}><i className={'fas fa-caret-square-' + (Object.keys(this.state.folded).length === 0 ? "left" : "right")}></i> {Object.keys(this.state.folded).length === 0 ? "Collapse" : "Expand" }</Button>                         */}
                {/* <Button variant="contained" style={buttonStyle} ><i className="fas fa-eraser"></i> Clear filters</Button> */}
                <Button variant="contained" style={buttonStyle} onClick={this.exportToExcel}>
                  <i className="fas fa-file-excel"></i> {this.props.t("exportToExcel")}
                </Button>
              </div>
            )}
            <ObjectsTable />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  customers: state.customers.customersList,
  contracts: state.contracts.contractsList,
  objects: state.objects.objectsList,
  settlements: state.settlements.settlementsList,
  loading: state.helpers.loading,
});

export default connect(mapStateToProps, {
  addObject,
  getObjects,
  editObject,
  deleteObject,
})(withTranslation()(ObjectsView));
