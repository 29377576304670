import React from 'react'
import { Redirect, Route } from 'react-router-dom'
// import { ClipLoader } from "react-spinners";
import { connect } from 'react-redux'
import Header from './Header'
// import jwt_decode from 'jwt-decode';
import { logoutUser } from '../../actions/authentication';
// import store from '../store';

const PrivateRoute = ({ component: Component, ...rest }) => {

    class AuthContainter extends React.Component {
        constructor(props) {
            super(props);
            this.handleRender = this.handleRender.bind(this);
            this.activeSession = this.activeSession.bind(this);
        }

        activeSession() {
            if (!localStorage.getItem('jwtToken')) return false
            if (!this.props.auth.isAuthenticated || !this.props.auth?.user.exp) return false
            if (this.props.auth.user.exp * 1000 < Date.now()) return false

            return true
        } 
    
        handleRender(props) {
            // if (this.props.loading) {
            //     return (
            //         <div className="loading">
            //             <ClipLoader
            //                 color="#00BFFF"
            //                 size={150}
            //             />
            //         </div>
            //     );
            // }

            if (this.activeSession()) {
                return <Component {...props} />;
            } else {
                this.props.logoutUser();
                return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
            }
        }

        render() {
            return (
            <>
                <Header />
                <Route {...rest} render={this.handleRender} />
            </>    
            )
        }
    }

    function mapStateToProps(state) {
        return {
            auth: state.auth,
            loading: state.helpers.loading
        }
    }

    const AuthCont = connect(mapStateToProps, {logoutUser})(AuthContainter);
    return <AuthCont />
}

export default PrivateRoute