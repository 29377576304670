import React, { useState, useRef } from "react";
import ReactTable from "react-table-6";
import i18n from "../../i18n";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { addSlashesToString } from "../../helpers/dateTransformations";

const ContractsSubTable = (props) => {
  const [filteredCount, setCount] = useState(props.contracts.length);
  const selectTable = useRef(null);

  let removeContract = (data) => {
    props.row.original.contracts = props.row.original.contracts.filter((el) => el !== data._id);
    props.removeContract(data);
  };

  let cols = [
    {
      Header: filteredCount,
      filterable: false,
      Cell: (row) => {
        return row.index + 1 + ".";
      },
    },
    {
      Header: i18n.t("contract.type"),
      accessor: "insType",
    },
    {
      Header: i18n.t("contract.company"),
      width: 270,
      id: "insCompany",
      accessor: (d) => d.insCompany,
    },
    {
      Header: i18n.t("contract.contractNum"),
      width: 150,
      id: "contractNum",
      accessor: (d) => d.contractNum,
    },
    {
      Header: i18n.t("contract.contractStartDate"),
      id: "contractStartDate",
      width: 130,
      Cell: (props) => <span>{props.original.contractStartDate ? addSlashesToString(props.original.contractStartDate) : null}</span>,
    },
    {
      Header: i18n.t("contract.contractEndDate"),
      id: "contractEndDate",
      width: 130,
      Cell: (props) => <span>{props.original.contractEndDate ? addSlashesToString(props.original.contractEndDate) : null}</span>,
    },
    {
      Header: i18n.t("contract.name"),
      id: "name",
      width: 300,
      accessor: (d) => d.name,
    },
    {
      Header: i18n.t("contract.insAmount"),
      id: "insAmount",
      width: 150,
      accessor: (d) => d.insAmount,
    },
    {
      Header: i18n.t("contract.insPremiya"),
      id: "insPremiya",
      width: 150,
      accessor: (d) => d.insPremiya,
    },
    {
      Header: i18n.t("contract.currency"),
      id: "currency",
      accessor: (d) => d.currency,
    },
    {
      Header: i18n.t("contract.insTariff"),
      id: "insTariff",
      width: 150,
      accessor: (d) => d.insTariff,
    },
    {
      Header: i18n.t("contract.insFranchise"),
      id: "insFranchise",
      accessor: (d) => d.insFranchise,
    },
    {
      Header: i18n.t("contract.insFranchiseType"),
      id: "insFranchiseType",
      width: 150,
      accessor: (d) => d.insFranchiseType, //% or UAH
    },
    {
      Header: i18n.t("contract.paymentScheme"), //1,2,3,4, doplata
      id: "paymentScheme",
      width: 150,
      accessor: (d) => d.paymentScheme,
    },
    {
      Header: i18n.t("contract.paymentAmount"),
      id: "paymentParts",
      width: 150,
      // show: props.auth.user.role !== "client",
      accessor: (d) => d.paymentParts,
      Cell: (props) => (
        <div>
          <span>
            {Object.keys(props.original.paymentParts).reduce((acc, value, ind, arr) => {
              if (props.original.paymentParts[value].paid) {
                acc = acc + props.original.paymentParts[value].amount;
              }
              return acc;
            }, 0)}
          </span>
        </div>
      ),
    },
    {
      Header: i18n.t("contract.commissionPercentage"),
      id: "paymentParts",
      width: 150,
      // show: props.auth.user.role !== "client",
      accessor: (d) => d.paymentParts,
      Cell: (props) => (
        <div>
          <span>{props.original.paymentParts.first.commissionP}</span>
        </div>
      ),
    },
    {
      Header: i18n.t("contract.commissionCurrency"),
      id: "paymentParts",
      width: 150,
      // show: props.auth.user.role !== "client",
      accessor: (d) => d.paymentParts,
      Cell: (props) => (
        <div>
          <span>
            {Object.keys(props.original.paymentParts).reduce((acc, value, ind, arr) => {
              if (props.original.paymentParts[value].paid) {
                acc = acc + props.original.paymentParts[value].commissionC;
              }
              return acc;
            }, 0)}
          </span>
        </div>
      ),
    },
    {
      Header: i18n.t("contract.contractManager"),
      id: "contractManager",
      width: 200,
      accessor: (d) => d.contractManager,
    },
    {
      Header: i18n.t("contract.contractAgent"),
      id: "contractAgent",
      accessor: (d) => d.contractAgent,
    },
    {
      Header: i18n.t("contract.contractSubagent"),
      id: "contractSubagent",
      accessor: (d) => d.contractSubagent,
    },
    {
      Header: i18n.t("contract.contractType"),
      id: "contractType",
      width: 200,
      accessor: (d) => d.contractType,
    },
    {
      Header: i18n.t("contract.contractStatus"),
      width: 200,
      id: "contractStatus",
      accessor: (d) => d.contractStatus,
    },
  ];

  cols.unshift({
    Header: i18n.t("actions"),
    filterable: false,
    width: 50,
    accessor: "action",
    Cell: (row) => (
      <ButtonGroup size="small" variant="outlined" color="primary" aria-label="small outlined button group">
        {/* <Button color="primary" onClick={this.setModalContentAndOpenForEdit.bind(this, row.original)}>
          <i className="far fa-edit fa-lg"></i>
        </Button> */}
        <Button color="secondary" onClick={removeContract.bind(this, row.original)}>
          <i className="far fa-trash-alt fa-lg"></i>
        </Button>
      </ButtonGroup>
    ),
  });

  return (
    <ReactTable
      data={props.contracts}
      filterable
      columns={cols}
      onFilteredChange={() => {
        setCount(selectTable.current.getResolvedState().sortedData.length);
      }}
      ref={selectTable}
      defaultPageSize={5}
      className="-striped -highlight"
    />
  );
};

export default ContractsSubTable;
