import React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import ContractsTable from "./ContractsTable";
import { setSubModalContent } from "../../actions/modals";
import { setLoading } from "../../actions/API_helpers";
import { getContracts, addContract, editContract } from "../../actions/contracts";
import { addCustomer, editCustomer, getCustomers } from "../../actions/customers";
import { addObject, getObjects, editObject } from "../../actions/objects";
import { getAllCompanies } from "../../actions/companies";
import ContractModal from "./ContractModal";
import { withTranslation, Trans } from "react-i18next";
import { emptyContract } from "../Common/emptyContract";
import { excelSaver } from "../../helpers/excelSaver";
import { actionButtonsContainer, containerStyle, buttonStyle } from "../Common/Styles";

class ContractsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      showModal: false,
      filteredContracts: this.props.contracts,
      subModalViewType: null,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setFilteredContracts = this.setFilteredContracts.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
    this.saveContent = this.saveContent.bind(this);
    if (props.contracts.length === 0) {
      props.setLoading(true);
      props.getContracts().then((res) => {
        this.setState({
          filteredContracts: res,
        });
        props.setLoading(false);
      });
    }

    if (this.props.customers.length === 0) {
      this.props.getCustomers();
    }
    if (this.props.objects.length === 0) {
      this.props.getObjects();
    }
    if (this.props.companies.length === 0) {
      this.props.getAllCompanies();
    }
  }

  saveContent = function (contract, row) {
    this.props.setLoading(true);
    let preparedContract = Object.assign({}, { ...contract });
    preparedContract.createdDate = Date.now();
    if (row) {
      //case when something added from subtables
      if (this.state.subModalViewType === "contract") {
        // <<<<<<< MOST PROBABLY THIS CAN BE DELETED
        //save object && update Contract
        this.props.editContract(preparedContract).then(
          (res) => {
            this.props.getContracts();
            this.setState({
              showSubModal: !this.state.showSubModal,
              subModalViewType: null,
            });
            console.log("SUCCESS!");
            this.props.setLoading(false);
          },
          (err) => {
            console.log("NO SUCCESS!", err);
            this.props.setLoading(false);
          }
        );
      }
    } else {
      //case when new data added from menu
      return this.props.addContract(preparedContract).then((res) => {
        this.props.setLoading(false);
        return res;
      });
    }
  };

  closeModal = function () {
    this.setState({
      showModal: false,
      // contract: emptyContract,
    });
    sessionStorage.removeItem("newContractDraft");
  };

  openModal = function () {
    let contract = this.setPredefinedValues(emptyContract);
    sessionStorage.setItem("newContractDraft", JSON.stringify(contract));
    this.setState({
      showModal: true,
    });
  };

  setPredefinedValues(contract) {
    let _contract = Object.assign({}, { ...contract });
    _contract.manager = this.props.auth.user.name;
    _contract.contractManager = this.props.auth.user.name;
    _contract.contractStartDate =
      "" +
      (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()) +
      (new Date().getMonth() < 9 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) +
      new Date().getFullYear();
    _contract.contractEndDate =
      "" +
      (new Date().getDate() < 11 ? "0" + (new Date().getDate() - 1) : new Date().getDate() - 1) +
      (new Date().getMonth() < 9 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) +
      (new Date().getFullYear() + 1);
    _contract.contractDate =
      "" +
      (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()) +
      (new Date().getMonth() < 9 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) +
      new Date().getFullYear();
    _contract.paymentScheme = "1-н платіж";
    _contract.currency = "UAH";
    _contract.contractStatus = "діючий";
    _contract.status = "Активний";
    _contract.objectDocType = "технічний паспорт";
    _contract.objDate =
      "" +
      (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()) +
      (new Date().getMonth() < 9 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) +
      new Date().getFullYear();
    _contract.citizenship = "українець";
    return _contract;
  }

  setFilteredContracts = function (filteredContracts) {
    this.setState({
      filteredContracts: filteredContracts,
    });
  };

  exportToExcel = function () {
    excelSaver(this.state.filteredContracts, "report_" + Date.now() + ".xls");
  };

  handleInputChange = function (e) {
    this.setState({
      currentUser: { ...this.state.currentUser, [e.target.id]: e.target.value },
    });
  };

  isSameObjects = function (obj1, obj2) {
    if (obj2 === undefined) return false;
    //Loop through properties in object 1
    for (var p in obj1) {
      //Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
        console.log(p);
        return false;
      }

      switch (typeof obj1[p]) {
        //Deep compare objects
        case "object":
          if (!this.isSameObjects.call(this, obj1[p], obj2[p])) {
            return false;
          }
          break;
        //Compare function code
        case "function":
          if (typeof obj2[p] == "undefined" || (p !== "compare" && obj1[p].toString() !== obj2[p].toString())) {
            console.log(p);
            return false;
          }
          break;
        //Compare values
        default:
          if (obj1[p] !== obj2[p]) {
            console.log(p);
            return false;
          }
      }
    }

    return true;
  };

  // shouldComponentUpdate(nextProps, nextState) {
  // let propsDiff = this.isSameObjects.call(this, nextProps, this.props);
  // let stateDiff = this.isSameObjects.call(this, nextState, this.state);

  // console.log("propsDiff: ", propsDiff);
  // console.log("stateDiff: ", stateDiff);
  // }

  render() {
    return (
      <>
        {this.props.loading && (
          <div className="loading">
            <ClipLoader color="#00BFFF" size={150} />
          </div>
        )}

        <ContractModal isShow={this.state.showModal} saveContent={this.saveContent} closeModal={this.closeModal} />
        <div style={containerStyle}>
          {this.props.auth.user.role !== "client" && (
            <div style={actionButtonsContainer}>
              <Button variant="contained" style={buttonStyle} onClick={this.openModal}>
                <i className="fas fa-plus-square"></i>
                <Trans i18nKey="addNew">Add contract</Trans>
              </Button>
              <Button variant="contained" style={buttonStyle} onClick={this.exportToExcel}>
                <i className="fas fa-file-excel"></i> <Trans i18nKey="exportToExcel">Export to Excel</Trans>
              </Button>
            </div>
          )}
          <ContractsTable data={this.props.contracts} getFiltered={this.setFilteredContracts} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.helpers.loading,
  contracts: state.contracts.contractsList,
  objects: state.objects.objectsList,
  customers: state.customers.customersList,
  companies: state.companies.companyList,
  settlements: state.settlements.settlementsList,
});

export default connect(mapStateToProps, {
  getContracts,
  getObjects,
  addContract,
  editContract,
  getAllCompanies,
  addCustomer,
  getCustomers,
  addObject,
  setLoading,
  setSubModalContent,
  editCustomer,
  editObject,
})(withTranslation()(ContractsView));
