import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table-6";
import matchSorter from "match-sorter";
import "react-table-6/react-table.css";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import CustomersSubTable from "../Subtables/CustomersSubTable";
import ObjectsSubTable from "../Subtables/ObjectsSubTable";
import SettlementsSubTable from "../Subtables/SettlementsSubTable";
import { buttonStyle } from "../Common/Styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { getContracts, deleteContract, editContract, cloneProlongContract, removeContractReference } from "../../actions/contracts";
import { editCustomer } from "../../actions/customers";
import { editObject } from "../../actions/objects";
import { setLoading } from "../../actions/API_helpers";
import EditContractModal from "./EditContractModal";
import AttachItemModal from "../Subtables/AttachItemModal";
// import { dateToPresent } from '../../helpers/dataTransformations'
import { setSubModalContent } from "../../actions/modals";
import { useTranslation } from "react-i18next";
import { URL } from "../../CONSTANTS";
import FilesDragNDrop from "../Common/DragAndDrop";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import { emptyContract } from "../Common/emptyContract";

function ContractsTable({ data, getFiltered }) {
  let selectTable = React.createRef();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const contracts = data;
  const objects = useSelector((state) => state.objects.objectsList);
  const customers = useSelector((state) => state.customers.customersList);
  const settlements = useSelector((state) => state.settlements.settlementsList);

  useEffect(() => {
    setFiltered(contracts.length);
  }, [contracts, dispatch]);

  // const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [filtered, setFiltered] = useState(contracts.length);
  const [showAttachItemModal, setShowAttachItemModal] = useState(false);
  const [attachItemModalViewType, setAttachItemModalViewType] = useState(null);
  const [row, setRow] = useState(null);

  // function componentDidMount() {
  //   if (props.contracts.length === 0) {
  //     dispatch(setLoading(true));
  //     dispatch(getContracts()).then(
  //       (res) => {
  //         console.log(res);
  //         if (res) {
  //           setFiltered(res.length);
  //         }

  //         dispatch(setLoading(false));
  //       },
  //       (err) => {
  //         console.log(err);
  //         dispatch(setLoading(false));
  //       }
  //     );
  //   }
  // }

  function deleteFile(contract, ind) {
    contract.contractPhotos.splice(ind, 1);
    dispatch(editContract(contract)).then((res) => {
      console.log("file deleted: " + res);
    });
  }

  function updateContract(data) {
    dispatch(setLoading(true));

    dispatch(editContract(data)).then(
      (res) => {
        dispatch(getContracts());
      },
      (err) => {
        console.log("failed!", err);
      }
    );

    setShowAttachItemModal(false);

    // TODO: is this one needed?
    dispatch(setSubModalContent({}));
  }

  function updateObjects(data) {
    let objectsToIterate = objects.filter((obj) => data.objects.some((obj2) => obj2 === obj._id));

    objectsToIterate.forEach((obj) => {
      //add contractId to objects if object.contracts does not contain id of current contract
      if (!obj.contracts.some((obj) => obj === data._id)) {
        obj.contracts.push(data._id);
        dispatch(editObject(obj)).then(
          (res) => {
            console.log("Object added", res);
          },
          (err) => {
            console.log("Object not added", err);
          }
        );
      }
    });
  }

  function addItems(data) {
    updateContract(data);

    updateObjects(data);
    // TODO: add updateCustomers()
    // updateSettlements()
  }

  function handleAddNewClick(target, row, event) {
    setShowAttachItemModal(!showAttachItemModal);
    setAttachItemModalViewType(target);
    setRow(row);
  }

  function closeAttachItemModal() {
    setShowAttachItemModal(!showAttachItemModal);
    setAttachItemModalViewType(null);
    dispatch(setLoading(false));
  }

  function closeEditModal() {
    sessionStorage.removeItem("newContractDraft");
    setShowEditModal(false);
  }

  function openEditModal(row) {
    setShowEditModal(true);
    setRow(row);
  }

  function putDataToStoreAndOpenModal(contract) {
    let _contract = Object.assign({}, { ...emptyContract });
    for (let param in _contract) {
      if (param !== "paymentParts") {
        _contract[param] = contract[param] || "";
      } else {
        _contract[param] = Object.assign({ ..._contract[param] }, { ...contract[param] });
      }
    }
    _contract._id = contract._id;
    sessionStorage.setItem("newContractDraft", JSON.stringify(_contract));

    // props.setSubModalContent(contract);
    openEditModal(contract);
  }

  function removeLinkToItem(from, contrRow, subTableRow) {
    dispatch(removeContractReference(from, contrRow.original, subTableRow));
  }

  function saveContract() {
    dispatch(setLoading(true));
    let _contract = JSON.parse(sessionStorage.getItem("newContractDraft"));

    dispatch(editContract(_contract)).then((res) => {
      dispatch(getContracts()).then(
        (res) => {
          // let contracts = res;
          setShowEditModal(false);
          // this.setState({
          //   contracts,
          // });
          dispatch(setLoading(false));
        },
        (err) => {
          console.log(err);
          dispatch(setLoading(false));
        }
      );
    });
  }

  function handleDeleteContract(contract) {
    if (window.confirm("Are you sure you want to delete item?")) {
      dispatch(deleteContract(contract._id));
    }
  }

  function cloneContract(contract, actionType) {
    dispatch(setLoading("cloning"));

    let clonedContract = Object.assign({}, emptyContract);
    for (let item in clonedContract) {
      clonedContract[item] = contract[item] ? contract[item] : clonedContract[item];
    }
    clonedContract.createdDate = Date.now();
    clonedContract.contractPhotos = [];

    if (actionType !== "prolong") {
      clonedContract.contractNum += "_cloned";
      clonedContract.objects = [];
      clonedContract.customers = [];
      clonedContract.settlements = [];
    } else {
      clonedContract.contractNum += "_prolonged";
      changeDatesToNewContract(clonedContract);
    }

    dispatch(cloneProlongContract(clonedContract)).then((res) => {
      if (actionType === "prolong") {
        addNewContractToCustomer(clonedContract, res);
        addNewContractToObjects(clonedContract, res);
        changeStatusToOldContract(contract);
      }

      dispatch(getContracts()).then(
        (res) => {
          dispatch(setLoading(false));
        },
        (err) => {
          dispatch(setLoading(false));
          console.log(err);
        }
      );
    });
  }

  function addNewContractToCustomer(clonedContract, res) {
    clonedContract.customers.forEach((custId) => {
      let customerToUpdate = customers.filter((cust) => cust._id === custId);
      customerToUpdate.forEach((customer) => {
        customer.contracts.push(res._id);
        dispatch(editCustomer(customer));
      });
    });
  }

  function addNewContractToObjects(clonedContract, res) {
    clonedContract.objects.forEach((objId) => {
      let objectToUpdate = objects.filter((obj) => obj._id === objId);
      objectToUpdate.forEach((object) => {
        object.contracts.push(res._id);
        dispatch(editObject(object));
      });
    });
  }

  function changeStatusToOldContract(oldContract) {
    oldContract.contractStatus = "пролонгований";
    sessionStorage.setItem("newContractDraft", JSON.stringify(oldContract));
    saveContract();
  }

  function changeDatesToNewContract(prolongedContract) {
    var endDay = Number(prolongedContract.contractEndDate.substring(0, 2));
    var endMon = Number(prolongedContract.contractEndDate.substring(2, 4));
    var endYear = Number(prolongedContract.contractEndDate.substring(4));
    var newStartDate = new Date(endYear, endMon - 1, endDay + 1);
    var stringifiedNewStartDate =
      "" +
      (newStartDate.getDate() < 9 ? "0" + newStartDate.getDate() : newStartDate.getDate()) +
      (newStartDate.getMonth() < 9 ? "0" + (newStartDate.getMonth() + 1) : newStartDate.getMonth() + 1) +
      newStartDate.getFullYear();

    var newEndDate = new Date(newStartDate.getFullYear() + 1, newStartDate.getMonth(), newStartDate.getDate() - 1);
    var newEndDay = newEndDate.getDate() < 10 ? "0" + newEndDate.getDate() : "" + newEndDate.getDate();
    var newEndMonth = newEndDate.getMonth() < 9 ? "0" + (newEndDate.getMonth() + 1) : "" + (newEndDate.getMonth() + 1);
    var newEndYear = newEndDate.getFullYear();

    prolongedContract.contractEndDate = newEndDay + newEndMonth + newEndYear;
    prolongedContract.contractStartDate = stringifiedNewStartDate;
  }

  function findCustomerName(customers, contract) {
    let foundCustomer = customers.find((ctmr) => ctmr._id === contract.customers[0]);
    return foundCustomer ? foundCustomer.name : "! NOT FOUND";
  }

  function passFilteredContracts() {
    getFiltered(selectTable.current.getResolvedState().sortedData);
  }

  function handleAddedImage(row) {
    setRow(row);
  }

  const sortings = {
    dateSort: (a, b, desc) => {
      if (desc) {
        return new Date(Number(a.substr(4)), Number(a.substr(2, 2)) - 1, Number(a.substr(0, 2))) <
          new Date(Number(b.substr(4)), Number(b.substr(2, 2)) - 1, Number(b.substr(0, 2)))
          ? -1
          : 1;
      }
      return new Date(Number(a.substr(4)), Number(a.substr(2, 2)) - 1, Number(a.substr(0, 2))) >
        new Date(Number(b.substr(4)), Number(b.substr(2, 2)) - 1, Number(b.substr(0, 2)))
        ? 1
        : -1;
    },
  };

  const filters = {
    startEnd: (filter, row) => row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
    includes: (filter, row) => {
      return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
    },
    matchSrtr: (filter, rows) => matchSorter(rows, filter.value, { keys: ["firstName"] }),
    fromStart: (filter, row) => {
      if (filter.value.length >= 10) {
        return (
          new Date(
            row._original[filter.id].substring(4),
            Number(row._original[filter.id].substring(2, 4)) - 1,
            row._original[filter.id].substring(0, 2)
          ) >= new Date(filter.value.substring(6), Number(filter.value.substring(3, 5)) - 1, filter.value.substring(0, 2))
        );
      } else if (filter.value.length === 0) {
        // test + merge with first if
        return true;
      } else {
        return true;
      }
    },
    tillEnd: (filter, row) => {
      if (filter.value.length >= 10) {
        return (
          new Date(
            row._original[filter.id].substr(4),
            Number(row._original[filter.id].substr(2, 2)) - 1,
            row._original[filter.id].substr(0, 2)
          ) <= new Date(filter.value.substr(6, 4), Number(filter.value.substr(3, 2)) - 1, filter.value.substr(0, 2))
        );
      } else {
        return true;
      }
    },
    dateRange: (filter, row) => {
      if (filter.value.length === 0) {
        return true;
      }

      let noSpacesDateString = filter.value.replace(/\s+/g, "");
      if (noSpacesDateString.length === 10 || noSpacesDateString.length === 21) {
        var startY = filter.value.substr(6, 4);
        var startM = Number(filter.value.substr(3, 2)) - 1;
        var startD = filter.value.substr(0, 2);

        var endY = filter.value.length === 10 ? 2200 : noSpacesDateString.substr(17, 4);
        var endM = filter.value.length === 10 ? 11 : Number(noSpacesDateString.substr(14, 2)) - 1;
        var endD = filter.value.length === 10 ? 30 : noSpacesDateString.substr(11, 2);
        var dateFromContract = new Date(
          row._original[filter.id].substr(4),
          Number(row._original[filter.id].substr(2, 2)) - 1,
          row._original[filter.id].substr(0, 2)
        );

        sessionStorage.setItem("filterDateRangeStartDate", new Date(startY, startM, startD));
        sessionStorage.setItem("filterDateRangeEndDate", new Date(endY, endM, endD));

        return dateFromContract >= new Date(startY, startM, startD) && dateFromContract <= new Date(endY, endM, endD);
      } else {
        return true;
      }
    },
  };

  contracts.sort((a, b) => b.createdDate - a.createdDate).forEach((contract) => (contract.name = findCustomerName(customers, contract)));

  return (
    <>
      <EditContractModal
        isShown={showEditModal}
        saveContent={saveContract}
        closeEditModal={closeEditModal}
        openEditModal={openEditModal}
      ></EditContractModal>
      <AttachItemModal
        fromRow={row}
        contentType={attachItemModalViewType}
        saveContent={addItems}
        closeModal={closeAttachItemModal}
        showAttachItemModal={showAttachItemModal}
      ></AttachItemModal>

      <ReactTable
        data={contracts}
        collapseOnDataChange={false}
        ref={selectTable}
        onFilteredChange={() => {
          passFilteredContracts();
          setFiltered(selectTable.current.getResolvedState().sortedData.length);
        }}
        filterable
        defaultFilterMethod={filters.includes}
        columns={[
          {
            Header: filtered + "",
            width: 40,
            filterable: false,
            Cell: (row) => {
              return row.index + 1 + ".";
            },
          },
          {
            Header: t("actions"),
            width: 175,
            accessor: "action",
            filterable: false,
            Cell: (row) => (
              <ButtonGroup size="small" variant="outlined" color="primary" aria-label="small outlined button group">
                <Tooltip title="Edit" placement="top-start">
                  <Button disabled={auth.user.role === "client"} onClick={putDataToStoreAndOpenModal.bind(this, row.original)}>
                    <i className="far fa-edit fa-lg"></i>
                  </Button>
                </Tooltip>
                <Tooltip title="Clone" placement="top-start">
                  <Button disabled={auth.user.role === "client"} onClick={cloneContract.bind(this, row.original)}>
                    <i className="far fa-copy fa-lg"></i>
                  </Button>
                </Tooltip>
                <Tooltip title="Prolong" placement="top-start">
                  <Button
                    disabled={auth.user.role === "client"}
                    style={{ color: "green" }}
                    onClick={cloneContract.bind(this, row.original, "prolong")}
                  >
                    <i className="fa fa-redo fa-lg"></i>
                  </Button>
                </Tooltip>
                <Tooltip title="Delete" placement="top-start">
                  <Button disabled={auth.user.role === "client"} color="secondary" onClick={handleDeleteContract.bind(this, row.original)}>
                    <i className="far fa-trash-alt fa-lg"></i>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            ),
          },

          {
            Header: t("contract.type"),
            accessor: "insType",
          },
          {
            Header: t("contract.contractDate"),
            accessor: "contractDate",
            id: "contractDate",
            width: 130,
            filterMethod: filters.dateRange,
            sortMethod: sortings.dateSort,
            Cell: (props) => (
              <span>
                {props.original.contractDate
                  ? props.original.contractDate.substr(0, 2) +
                    "/" +
                    props.original.contractDate.substr(2, 2) +
                    "/" +
                    props.original.contractDate.substr(4)
                  : null}
              </span>
            ),
          },
          {
            Header: t("contract.company"),
            width: 270,
            id: "insCompany",
            accessor: (d) => d.insCompany,
          },
          {
            Header: t("contract.contractNum"),
            width: 150,
            id: "contractNum",
            accessor: (d) => d.contractNum,
          },
          {
            Header: t("contract.contractStartDate"),
            accessor: "contractStartDate",
            width: 130,
            filterMethod: filters.dateRange,
            sortMethod: sortings.dateSort,
            Cell: (props) => (
              <span>
                {props.original.contractStartDate
                  ? props.original.contractStartDate.substr(0, 2) +
                    "/" +
                    props.original.contractStartDate.substr(2, 2) +
                    "/" +
                    props.original.contractStartDate.substr(4)
                  : null}
              </span>
            ),
          },
          {
            Header: t("contract.contractEndDate"),
            accessor: "contractEndDate",
            width: 130,
            filterMethod: filters.tillEnd,
            sortMethod: sortings.dateSort,
            Cell: (props) => (
              <span>
                {props.original.contractEndDate
                  ? props.original.contractEndDate.substr(0, 2) +
                    "/" +
                    props.original.contractEndDate.substr(2, 2) +
                    "/" +
                    props.original.contractEndDate.substr(4)
                  : null}
              </span>
            ),
          },
          {
            Header: t("contract.name"),
            id: "name",
            width: 300,
            accessor: (d) => d.name,
          },

          {
            Header: t("contract.insAmount"),
            id: "insAmount",
            width: 150,
            accessor: (d) => d.insAmount,
          },
          {
            Header: t("contract.insPremiya"),
            id: "insPremiya",
            width: 150,
            accessor: (d) => d.insPremiya,
          },
          {
            Header: t("contract.currency"),
            id: "currency",
            accessor: (d) => d.currency,
          },
          {
            Header: t("contract.insTariff"),
            id: "insTariff",
            width: 150,
            accessor: (d) => d.insTariff,
          },
          {
            Header: t("contract.insFranchise"),
            id: "insFranchise",
            accessor: (d) => d.insFranchise,
          },
          {
            Header: t("contract.insFranchiseType"),
            id: "insFranchiseType",
            width: 150,
            accessor: (d) => d.insFranchiseType, //% or UAH
          },
          {
            Header: t("contract.paymentScheme"), //1,2,3,4, doplata
            id: "paymentScheme",
            width: 150,
            accessor: (d) => d.paymentScheme,
          },
          {
            Header: t("contract.paymentAmount"),
            id: "paymentParts",
            width: 150,
            show: auth.user.role !== "client",
            accessor: (d) => d.paymentParts,
            Cell: (props) => (
              <div>
                <span>
                  {Object.keys(props.original.paymentParts).reduce((acc, value, ind, arr) => {
                    if (props.original.paymentParts[value].paid) {
                      acc = acc + props.original.paymentParts[value].amount;
                    }
                    return acc;
                  }, 0)}
                </span>
              </div>
            ),
          },
          {
            Header: t("contract.commissionPercentage"),
            id: "paymentParts",
            width: 80,
            style: { textAlign: "center" },
            show: auth.user.role !== "client",
            accessor: (d) => d.paymentParts,
            Cell: (props) => (
              <div>
                <span>{props.original.paymentParts.first.commissionP}</span>
              </div>
            ),
          },
          {
            Header: t("contract.commissionCurrency"),
            id: "paymentParts",
            width: 110,
            show: auth.user.role !== "client",
            accessor: (d) => d.paymentParts,
            Cell: (props) => (
              <div>
                <span>
                  {Object.keys(props.original.paymentParts).reduce((acc, value, ind, arr) => {
                    if (props.original.paymentParts[value].paid) {
                      acc = acc + props.original.paymentParts[value].commissionC;
                    }
                    return acc;
                  }, 0)}
                </span>
              </div>
            ),
          },
          {
            Header: t("contract.contractManager"),
            id: "contractManager",
            width: 120,
            accessor: (d) => d.contractManager,
          },
          {
            Header: t("contract.contractAgent"),
            id: "contractAgent",
            accessor: (d) => d.contractAgent,
          },
          {
            Header: t("contract.contractSubagent"),
            id: "contractSubagent",
            accessor: (d) => d.contractSubagent,
          },
          {
            Header: t("contract.contractType"),
            id: "contractType",
            width: 50,
            accessor: (d) => d.contractType,
          },
          {
            Header: t("contract.contractStatus"),
            width: 150,
            id: "contractStatus",
            accessor: (d) => d.contractStatus,
          },
        ]}
        defaultPageSize={20}
        className="-striped -highlight"
        SubComponent={(row) => {
          return (
            <div className="subtable-block">
              <Tab.Container id="vertical-nav" defaultActiveKey="first">
                <Row style={{ height: "100%" }}>
                  <Col style={{ width: 300, flex: "none" }}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">{t("contract.objects")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">{t("contract.Settlements")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">{t("contract.Customers")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">{t("contract.Documents")}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col className="subtable-right-panel">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <ObjectsSubTable
                          objects={objects.filter((el) => row.original.objects.some((el2) => el2 === el._id))}
                          removeObject={removeLinkToItem.bind(this, "objects", row)}
                          row={row}
                        ></ObjectsSubTable>
                        {auth.user.role !== "client" && (
                          <div>
                            <Button variant="contained" onClick={handleAddNewClick.bind(this, "objects", row)} style={buttonStyle}>
                              <i className="fas fa-plus-square"></i>
                              {t("Add new")}
                            </Button>
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <SettlementsSubTable
                          settlements={settlements.filter((el) => row.original.settlements.some((el2) => el2 === el._id))}
                          removeObject={removeLinkToItem.bind(this, "settlements", row)}
                          row={row}
                        ></SettlementsSubTable>
                        {auth.user.role !== "client" && (
                          <div>
                            <Button variant="contained" onClick={handleAddNewClick.bind(this, "settlements")} style={buttonStyle}>
                              <i className="fas fa-plus-square"></i>
                              {t("Add new")}
                            </Button>
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <CustomersSubTable
                          customers={customers.filter((el) => row.original.customers.some((el2) => el2 === el._id))}
                          removeCustomer={removeLinkToItem.bind(this, "customers", row)}
                          row={row}
                        ></CustomersSubTable>
                        {auth.user.role !== "client" && (
                          <div>
                            <Button variant="contained" onClick={handleAddNewClick.bind(this, "customers", row)} style={buttonStyle}>
                              <i className="fas fa-plus-square"></i>
                              {t("Add new")}
                            </Button>
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <div style={{ position: "relative" }}>
                          <div
                            className="photos-container"
                            style={{
                              textAlign: "center",
                              display: "inline-block",
                              position: "absolute",
                              width: 400,
                              top: 0,
                            }}
                          >
                            <FilesDragNDrop
                              objectData={{
                                pref: row.original.contractNum,
                                suf: row.original.insType,
                                entity: { contract: row.original },
                              }}
                              docFolder={"contract"}
                              handleAddedImage={handleAddedImage}
                            ></FilesDragNDrop>
                          </div>
                          <div
                            style={{
                              position: "relative",
                              left: 400,
                            }}
                          >
                            <div style={{ display: "inline-block", position: "relative" }}>
                              {row.original.contractPhotos.sort().map((fileName, ind) => {
                                let dir = {
                                  folder:
                                    "contract/" +
                                    row.original.contractNum
                                      .split("")
                                      .filter((el) => ["'", '"', "+", "/", ".", "*"].every((badS) => badS !== el))
                                      .join("") +
                                    "_" +
                                    row.original.insType,
                                  fileName: fileName,
                                };
                                return (
                                  <div
                                    key={ind}
                                    className="filesUploaded"
                                    style={{
                                      margin: "10px 5px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: 150,
                                        height: 150,
                                        border: "1px solid lightgray",
                                        borderRadius: 9,
                                        textAlign: "right",
                                        position: "relative",
                                      }}
                                    >
                                      <div style={{ height: 25 }}>
                                        <p
                                          style={{
                                            overflowWrap: "anywhere",
                                            fontSize: "12px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            paddingLeft: 5,
                                            width: 120,
                                          }}
                                        >
                                          <a
                                            style={{
                                              color: "#033362",
                                              overflowWrap: "anywhere",
                                              fontSize: "12px",
                                            }}
                                            href={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {dir.fileName}
                                          </a>
                                        </p>
                                        <CancelIcon
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            color: "#dc5a5a",
                                          }}
                                          onClick={deleteFile.bind(this, row.original, fileName)}
                                        />
                                      </div>
                                      {dir.fileName.substr(dir.fileName.length - 4) !== ".pdf" && (
                                        <div style={{ height: 100, textAlign: "center", background: "#e1e1e1", padding: "2px 0px" }}>
                                          <div
                                            style={{
                                              backgroundImage: `url("${URL}/static/${dir.folder}/${dir.fileName}")`,
                                              height: 110,
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                            }}
                                          ></div>
                                        </div>
                                      )}
                                      {dir.fileName.substr(dir.fileName.length - 4) === ".pdf" && (
                                        <div style={{ position: "absolute", height: 125, overflow: "hidden" }}>
                                          <embed
                                            name="plugin"
                                            width="100%"
                                            src={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                            type="application/pdf"
                                          ></embed>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          );
        }}
      />
    </>
  );
}

export default ContractsTable;
