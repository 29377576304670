import axios from "axios";
import { GET_ERRORS, GET_ALL_COMPANIES, DELETE_COMPANY, EDIT_COMPANY, ADD_COMPANY } from "./types";
import { URL } from "../CONSTANTS";

export const addCompany = (company) => (dispatch) => {
  return axios
    .post(URL + "/api/company/addcompany", company)
    .then((res) => {
      dispatch({
        type: ADD_COMPANY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getAllCompanies = () => (dispatch) => {
  return axios
    .get(URL + "/api/company/getcompanies")
    .then((res) => {
      dispatch({
        type: GET_ALL_COMPANIES,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const editCompany = (company) => (dispatch) => {
  return axios
    .put(URL + "/api/company/editcompany", { params: company })
    .then((res) => {
      dispatch({
        type: EDIT_COMPANY,
        payload: company,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const deleteCompany = (id) => (dispatch) => {
  return axios
    .delete(URL + "/api/company/delete", { params: { id: id } })
    .then((res) => {
      dispatch({
        type: DELETE_COMPANY,
        payload: { id },
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};
