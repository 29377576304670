import {  GET_FILE, UPLOAD_FILE, DELETE_FILE } from '../actions/types';

const initialState = {
    fileList: []
}

export default function(state = initialState, action ) {
    switch(action.type) {
        
        case GET_FILE:
            return {
                ...state,
                fileList: action.payload
            }
        case UPLOAD_FILE:
            return {
                ...state,
                fileList: action.payload
            }
        case DELETE_FILE:
            return {
                ...state,
                fileList: action.payload
            }
        default: 
            return state;
    }
}