import { GET_EXRATE, LOADING } from '../actions/types';

const initialState = {
    rates: null,
    loading: false
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case GET_EXRATE:
            return {
                ...state,
                rates: {usd: action.payload[27], eur: action.payload[34]}
            }
        case LOADING:
            return {
                ...state,
                loading: action.payload
            }
        default: 
            return state;
    }
}