//USERS
export const GET_ERRORS = "GET_ERRORS";
export const ADD_USER = "ADD_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

//COMPANIES
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";

//CUSTOMERS
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

//HELPERS
export const GET_EXRATE = "GET_EXRATE";
export const LOADING = "LOADING";
export const LOGGING = "LOGGING";

//OBJECTS
export const GET_ALL_OBJECTS = "GET_ALL_OBJECTS";
export const EDIT_OBJECT = "EDIT_OBJECT";
export const DELETE_OBJECT = "DELETE_OBJECT";
export const ADD_OBJECT = "ADD_OBJECT";

//TASKS
export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const EDIT_TASK = "EDIT_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const ADD_TASK = "ADD_TASK";

// FILES
export const GET_FILE = "GET_FILE";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const DELETE_FILE = "DELETE_FILE";

//SETTLEMENTS
export const GET_ALL_SETTLEMENTS = "GET_ALL_SETTLEMENTS";
export const EDIT_SETTLEMENT = "EDIT_SETTLEMENT";
export const DELETE_SETTLEMENT = "DELETE_SETTLEMENT";
export const ADD_SETTLEMENT = "ADD_SETTLEMENT";

//CONTRACTS
export const GET_ALL_CONTRACTS = "GET_ALL_CONTRACTS";
export const EDIT_CONTRACT = "EDIT_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const ADD_CONTRACT = "ADD_CONTRACT";
export const GET_SALES_REPORT = "GET_SALES_REPORT";
export const GET_ALL_CONTRACTS_RD = "GET_ALL_CONTRACTS_RD";
export const CLONE_CONTRACT = "CLONE_CONTRACT";

export const SET_MODAL_CONTENT = "SET_MODAL_CONTENT";
