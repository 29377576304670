import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import FormData from "form-data";
// import Chip from '@material-ui/core/Chip';
import { editCustomer } from "../../actions/customers";
import { editContract } from "../../actions/contracts";
import { getObjects, editObject } from "../../actions/objects";
import { editSettlement } from "../../actions/settlements";
import { uploadFile } from "../../actions/files";
import { setLoading } from "../../actions/API_helpers";

class FilesDragNDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop = (files) => {
    this.props.setLoading("drag");
    try {
      var fd = new FormData();
      fd.append("file", files[0], files[0].name);
    } catch (err) {
      this.props.setLoading(false);
      alert("Ooops. Failed to load this file. Try another file or file format");
    }

    let obj = { pref: this.props.objectData.pref, suf: this.props.objectData.suf, folder: this.props.docFolder };

    this.props.uploadFile(obj, fd).then(
      (res) => {
        let entity = this.props.objectData.entity[this.props.docFolder];

        // REFACTOR
        // Quick fix: if settlementsPhotos does not exist, then file is uploaded from Settlements
        if (this.props.docFolder !== "settlement") {
          if (this.props.docFolder === "object") {
            entity.objectPhotos.push(res);
            this.props.editObject(entity).then(
              (res1) => {
                this.props.setLoading(false);
                //TODO: remove it as it is done for Contracts (once objectsTable switched to func component)
                this.props.handleAddedImage(res, "oDocs");
              },
              (err) => {
                console.log("Doc NOT added", err);
                this.props.setLoading(false);
              }
            );
          } else if (this.props.docFolder === "customer") {
            entity.customerPhotos.push(res);
            this.props.editCustomer(entity).then(
              (res2) => {
                this.props.setLoading(false);
                //TODO: remove it as it is done for Contracts (once CustomerTable switched to func component)
                this.props.handleAddedImage(res, "cuDocs");
              },
              (err) => {
                console.log("Doc NOT added", err);
                this.props.setLoading(false);
              }
            );
          } else {
            entity.contractPhotos.push(res);
            this.props.editContract(entity).then(
              (res3) => {
                this.props.setLoading(false);
                this.props.handleAddedImage(res, "coDocs");
              },
              (err) => {
                console.log("Doc NOT added", err);
                this.props.setLoading(false);
              }
            );
          }
        } else {
          // REFACTOR: do like above (via entity)
          entity.settlementsPhotos.push(res);
          this.props.editSettlement(entity).then(
            (res) => {
              console.log("Doc added", res);
              this.props.setLoading(false);
              this.props.handleAddedImage(res);
            },
            (err) => {
              console.log("Doc NOT added", err);
              this.props.setLoading(false);
            }
          );
        }
      },
      (err) => {
        console.log("Doc NOT added 2", err);
        this.props.setLoading(false);
      }
    );
  };

  render() {
    // const files = this.state.files.map(file => (
    //     <Chip size='small' key={file.name} onDelete={console.log("a")} label={file.name} style={{ margin: 2 }} />
    // ));

    return (
      <Dropzone onDrop={this.onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input name="file" {...getInputProps()} />
            <p style={{ height: 80 }}>Drop files here or click to select files</p>
          </div>
        )}
      </Dropzone>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.helpers.loading,
  customers: state.customers.customersList,
  contracts: state.contracts.contractsList,
  objects: state.objects.objectsList,
  objectPhotos: state.objects.fileList,
});

export default connect(mapStateToProps, {
  getObjects,
  editObject,
  editSettlement,
  editCustomer,
  editContract,
  uploadFile,
  setLoading,
})(FilesDragNDrop);
