import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import SettlementsModalContent from "./SettlementsModalContent";
import Button from "@material-ui/core/Button";
import { emptyContract } from "../Common/emptyContract";

export class SettlementsModal extends React.Component {
  constructor(props) {
    super(props);
    const content = Object.keys(this.props.modalContent).length ? this.props.modalContent : emptyContract;
    this.state = {
      isSaveButtonDisabled: true,
      currentContract: content,
    };
    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleInputChanges(updatedFields) {
    this.setState({
      isSaveButtonDisabled: updatedFields.contractNum === "",
      currentContract: updatedFields,
    });

    console.log(this.state.currentContract);
  }

  saveAndClose(data, isEditMode, notCloning, event) {
    // remove object description (it was added manually)
    delete data.object;

    if (isEditMode) {
      this.props.updateContent(data);
    } else {
      this.props.saveContent(data);
    }

    this.setState({
      isSaveButtonDisabled: true,
      currentContract: emptyContract,
    });
  }

  closeModal() {
    this.setState({
      isSaveButtonDisabled: true,
      currentContract: emptyContract,
    });
    this.props.closeModal();
  }

  updateState() {
    if (this.props.modalContent.objectAddress) {
      let tempObj = {};
      for (let key in this.state.currentContract) {
        tempObj[key] = this.props.modalContent[key];
      }

      this.setState({
        currentContract: tempObj,
      });
    }
  }

  render() {
    const isEditing = Object.keys(this.props.modalContent).length ? true : false;
    const notCloning = true;

    return (
      <>
        <Modal
          show={this.props.isShow}
          onEnter={this.updateState.bind(this)}
          onHide={this.closeModal}
          size="lg"
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? "Edit settlement" : "Add new settlement"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SettlementsModalContent contract={this.state.currentContract} setData={this.handleInputChanges} />
          </Modal.Body>
          {
            <Modal.Footer>
              <Button
                variant="contained"
                classes={{
                  root: "buttonStyle",
                  disabled: "disabledButtonStyle",
                }}
                onClick={this.closeModal}
              >
                Close
              </Button>
              <Button
                variant="contained"
                classes={{
                  root: "buttonStyle",
                  disabled: "disabledButtonStyle",
                }}
                disabled={this.state.isSaveButtonDisabled}
                onClick={this.saveAndClose.bind(this, this.state.currentContract, isEditing, notCloning)}
              >
                {isEditing ? "Save changes" : "Add"}
              </Button>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modalContent: state.modals.modalContent,
});

export default connect(mapStateToProps, {})(SettlementsModal);
