import { GET_ALL_SETTLEMENTS, DELETE_SETTLEMENT, EDIT_SETTLEMENT, ADD_SETTLEMENT } from "../actions/types";

const initialState = {
  settlementsList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SETTLEMENTS:
      return {
        ...state,
        settlementsList: action.payload,
      };
    case EDIT_SETTLEMENT:
      return {
        ...state,
        settlementsList: state.settlementsList.map((obj) => {
          if (obj._id === action.payload._id) {
            return { ...obj, ...action.payload };
          }
          return obj;
        }),
      };
    case DELETE_SETTLEMENT:
      return {
        ...state,
        settlementsList: state.settlementsList.filter((obj) => obj._id !== action.payload.id),
      };
    case ADD_SETTLEMENT:
      return {
        ...state,
        settlementsList: state.settlementsList.concat(action.payload),
      };
    default:
      return state;
  }
}
