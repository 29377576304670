import React from "react";
import { connect } from "react-redux";
import { getContracts } from "../../actions/contracts";
import { getObjects } from "../../actions/objects";
import { getCustomers } from "../../actions/customers";
import { getSettlements } from "../../actions/settlements";

class Analitics extends React.Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    this.props.getContracts();
    this.props.getCustomers();
    this.props.getObjects();
    this.props.getSettlements();
  }

  render() {
    const endsSoon = this.props.contracts
      .sort((a, b) => {
        return b.contractEndDate - a.contractEndDate;
      })
      .slice(0, 5)
      .map((contract) => (
        <li key={contract._id}>{"Contract number: " + contract.contractNum + ", End Date: " + contract.contractEndDate}</li>
      ));

    return (
      <>
        <div className="jumbotron">
          <h3>Contracts to be ended soon:</h3>
          <ol>{endsSoon.length > 0 ? endsSoon : "No contracts ending soon found"}</ol>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  contracts: state.contracts.contractsList,
});

export default connect(mapStateToProps, { getContracts, getObjects, getCustomers, getSettlements })(Analitics);
