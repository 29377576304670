import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import matchSorter from "match-sorter";
import "react-table-6/react-table.css";
import FoldableTableHOC from "react-table-6/lib/hoc/foldableTable";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import ContractsSubTable from "../Subtables/ContractsSubTable";
import CustomersSubTable from "../Subtables/CustomersSubTable";
import SettlementsSubTable from "../Subtables/SettlementsSubTable";
// import { fromDBDate } from '../../helpers/dataTransformations'
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FilesDragNDrop from "../Common/DragAndDrop";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import { getObjects, editObject, deleteObject, addObject, removeObjectReference } from "../../actions/objects";
import { deleteFile } from "../../actions/files";
import { editContract, getContracts, removeContractReference } from "../../actions/contracts";
import { editCustomer, getCustomers, removeCustomerReference } from "../../actions/customers";
import { getSettlements } from "../../actions/settlements";
import { setLoading } from "../../actions/API_helpers";
import { URL } from "../../CONSTANTS";
import ObjectsModal from "./ObjectsModal";
import { setSubModalContent } from "../../actions/modals";
import { withTranslation } from "react-i18next";
import { buttonStyle } from "../Common/Styles";
import { ClipLoader } from "react-spinners";
import AttachItemModal from "../Subtables/AttachItemModal";
import { addSlashesToString } from "../../helpers/dateTransformations";

const FoldableTable = FoldableTableHOC(ReactTable);

export class ObjectsTable extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showSubModal: false,
      row: null,
      expanded: {},
      folded: { col_1: true },
      filtered: props.objects.length,
      showModal: false,
    };

    this.selectTable = React.createRef();

    this.updateContent = this.updateContent.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeAttachItemModal = this.closeAttachItemModal.bind(this);
    this.addItems = this.addItems.bind(this);
  }

  componentDidMount() {
    this.props.getCustomers();
    this.props.getContracts();
    this.props.getSettlements();
  }

  updateContent(data) {
    this.props.setLoading(true);
    if (!data._id) {
      data._id = this.state.row._id;
    }

    this.props.editObject(data).then(
      (res) => {
        console.log("SUCCESS!", res);
        this.props.getObjects();
        this.props.setLoading(false);
      },
      (err) => {
        console.log("SUCCESS!", err);
        this.props.setLoading(false);
      }
    );

    this.setState({
      showModal: false,
    });

    this.props.setSubModalContent({});
  }

  handleAddNewClick = function (target, row, context) {
    this.setState({
      showAttachItemModal: !this.state.showAttachItemModal,
      attachItemModalViewType: target,
      row,
    });
  };
  closeAttachItemModal() {
    this.setState({
      showAttachItemModal: !this.state.showAttachItemModal,
      attachItemModalViewType: null,
    });
  }

  addItems(data) {
    this.setState({ row: Object.assign({}, { ...data }) });
    this.updateContent(data);

    //AGREED WITH VITALIY TO REMOVE FUNCTIONALITY BELOW
    // let contractsToIterate = this.props.contracts.filter((contr) => data.contracts.some((el) => el === contr._id));
    // contractsToIterate.forEach((contract) => {
    //   contract.customers.push(data._id);
    //   this.props.editContract(contract).then(
    //     (res) => {
    //       console.log("SUCCESS!", res);
    //     },
    //     (err) => {
    //       console.log("NO SUCCESS! Object Table", err);
    //     }
    //   );
    // });
  }

  handleAddedImage(row) {
    this.setState({
      expanded: { [row.index]: true },
    });
  }

  closeModal() {
    this.props.setSubModalContent({});
    this.setState({
      showModal: false,
    });
  }

  openModal(row) {
    this.setState({
      showModal: true,
      row,
    });
  }

  deleteObject(data) {
    if (window.confirm("Are you sure you want to delete item?")) {
      this.props.deleteObject(data._id);
    }
  }

  downloadFile(dir) {
    window.open(URL + "/static/" + dir.folder + "/" + dir.fileName, "Window Title", "width=500, height=450");
  }

  deleteFile(obj, ind) {
    obj.objectPhotos.splice(ind, 1);
    this.props.deleteFile(obj).then((res) => {
      console.log("file deleted: " + res);
    });
  }

  putCustomerDataToStoreAndOpenModal(object) {
    this.props.setSubModalContent(object);
    this.openModal(object);
  }

  cloneObject = function (object) {
    let clonedObject = Object.assign({}, { ...object });
    this.addClonedLabelToProperties(clonedObject);
    this.deleteNotNeededProperties(clonedObject);

    this.props.addObject(clonedObject).then((res) => {
      this.addNewObjectToCustomer(clonedObject, res);
      this.addNewObjectToContract(clonedObject, res);
      this.props.getObjects();
    });
  };

  addClonedLabelToProperties(clonedObject) {
    clonedObject.owner = "cloned_" + clonedObject.owner;
    clonedObject.objectVIN = "cloned_" + clonedObject.objectVIN;
    clonedObject.createdDate = new Date().getTime();
  }

  deleteNotNeededProperties(clonedObject) {
    //  delete clonedObject.contracts; AGREED WITH VITALIY TO KEEP IT
    //  delete clonedObject.customers; AGREED WITH VITALIY TO KEEP IT
    delete clonedObject.settlements;
    delete clonedObject.objectPhotos;
    delete clonedObject._id;
    delete clonedObject.__v;
  }

  addNewObjectToContract(clonedObject, res) {
    clonedObject.contracts.forEach((contractId) => {
      let contractToUpdate = this.props.contracts.filter((contr) => contr._id === contractId);
      contractToUpdate.forEach((contract) => {
        contract.objects.push(res._id);
        this.props.editContract(contract);
      });
    });
  }

  addNewObjectToCustomer(clonedObject, res) {
    clonedObject.customers.forEach((custId) => {
      let customerToUpdate = this.props.customers.filter((cust) => cust._id === custId);
      customerToUpdate.forEach((customer) => {
        customer.objects.push(res._id);
        this.props.editCustomer(customer);
      });
    });
  }

  removeReference = function (from, objRow, subTableRow) {
    this.props.removeObjectReference(from, objRow.original, subTableRow);
  };

  render() {
    const sortings = {
      dateSort: (a, b, desc) => {
        if (desc) {
          return new Date(Number(a.substr(4)), Number(a.substr(2, 2)) - 1, Number(a.substr(0, 2))) <
            new Date(Number(b.substr(4)), Number(b.substr(2, 2)) - 1, Number(b.substr(0, 2)))
            ? -1
            : 1;
        }
        return new Date(Number(a.substr(4)), Number(a.substr(2, 2)) - 1, Number(a.substr(0, 2))) >
          new Date(Number(b.substr(4)), Number(b.substr(2, 2)) - 1, Number(b.substr(0, 2)))
          ? 1
          : -1;
      },
    };
    const filters = {
      startEnd: (filter, row) => row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
      includes: (filter, row) => {
        return String(row._original[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
      },
      dateIncludes: (filter, row) => {
        return String(row._original[filter.id]).includes(filter.value.replace(/\D/gm, ""));
      },
      matchSrtr: (filter, rows) => matchSorter(rows, filter.value, { keys: ["firstName"] }),
    };

    return (
      <>
        {this.props.loading && (
          <div className="loading">
            <ClipLoader color="#00BFFF" size={150} />
          </div>
        )}
        <AttachItemModal
          fromRow={this.state.row}
          contentType={this.state.attachItemModalViewType}
          saveContent={this.addItems}
          closeModal={this.closeAttachItemModal}
          showAttachItemModal={this.state.showAttachItemModal}
        />
        <ObjectsModal isShow={this.state.showModal} closeModal={this.closeModal} openModal={this.openModal} />
        <FoldableTable
          data={this.props.objects.sort((a, b) => {
            return b.createdDate - a.createdDate;
          })}
          onFoldChange={(newFolded) =>
            this.setState((p) => {
              return { folded: newFolded };
            })
          }
          folded={this.state.folded}
          filterable
          collapseOnDataChange={false}
          ref={this.selectTable}
          onFilteredChange={() => {
            this.setState({ filtered: this.selectTable.current.wrappedInstance.getResolvedState().sortedData.length });
          }}
          defaultFilterMethod={filters.includes}
          columns={[
            {
              columns: [
                {
                  Header: this.state.filtered,
                  width: 40,
                  filterable: false,
                  Cell: (row) => {
                    return row.index + 1 + ".";
                  },
                },
                {
                  Header: this.props.t("actions"),
                  width: 200,
                  filterable: false,
                  accessor: "action",
                  Cell: (row) => (
                    <ButtonGroup size="small" variant="outlined" color="primary" aria-label="small outlined button group">
                      <Button
                        disabled={this.props.auth.user.role === "client"}
                        onClick={this.putCustomerDataToStoreAndOpenModal.bind(this, row.original)}
                      >
                        <i className="far fa-edit fa-lg"></i>
                      </Button>
                      <Button disabled={this.props.auth.user.role === "client"} onClick={this.cloneObject.bind(this, row.original)}>
                        <i className="far fa-copy fa-lg"></i>
                      </Button>
                      <Button
                        disabled={this.props.auth.user.role === "client"}
                        color="secondary"
                        onClick={this.deleteObject.bind(this, row.original)}
                      >
                        <i className="far fa-trash-alt fa-lg"></i>
                      </Button>
                    </ButtonGroup>
                  ),
                },
                {
                  Header: this.props.t("objectsView.objDate"),
                  accessor: "objDate",
                  filterMethod: filters.dateIncludes,
                  sortMethod: sortings.dateSort,
                  Cell: (props) => <span>{props.original.objDate ? addSlashesToString(props.original.objDate) : null}</span>,
                },
                {
                  Header: this.props.t("objectsView.owner"),
                  width: 320,
                  accessor: "owner",
                },
                {
                  Header: this.props.t("objectsView.objectBrand"),
                  width: 150,
                  accessor: "objectBrand",
                },
                {
                  Header: this.props.t("objectsView.objectModel"),
                  accessor: "objectModel",
                },
                {
                  Header: this.props.t("objectsView.objectPlates"),
                  accessor: "objectPlates",
                },
                {
                  Header: this.props.t("objectsView.objectVIN"),
                  width: 200,
                  accessor: "objectVIN",
                },
                {
                  Header: this.props.t("objectsView.insurer"),
                  width: 250,
                  accessor: "insurer",
                },
                {
                  Header: this.props.t("objectsView.insured"),
                  accessor: "insured",
                },
                {
                  Header: this.props.t("objectsView.status"),
                  accessor: "status",
                },
              ],
            },
            {
              Header: this.props.t("objectsView.additionalInfo"),
              foldable: true,
              columns: [
                {
                  Header: this.props.t("objectsView.objectDocType"),
                  accessor: "objectDocType",
                },
                {
                  Header: this.props.t("objectsView.objectDocNumber"),
                  accessor: "objectDocNumber",
                },
                {
                  Header: this.props.t("objectsView.objectDocIssuer"),
                  accessor: "objectDocIssuer",
                },
                {
                  Header: this.props.t("objectsView.objectDocIssueDate"),
                  accessor: "objectDocIssueDate",
                  filterMethod: filters.dateIncludes,
                  sortMethod: sortings.dateSort,
                  Cell: (props) => (
                    <span>{props.original.objectDocIssueDate ? addSlashesToString(props.original.objectDocIssueDate) : null}</span>
                  ),
                },
                {
                  Header: this.props.t("objectsView.objectDescription"),
                  accessor: "objectDescription",
                },
                {
                  Header: this.props.t("objectsView.objectYom"),
                  accessor: "objectYom",
                },
                {
                  Header: this.props.t("objectsView.objectFullWeight"),
                  accessor: "objectFullWeight",
                },
                {
                  Header: this.props.t("objectsView.objectEmptyWeight"),
                  accessor: "objectEmptyWeight",
                },
                {
                  Header: this.props.t("objectsView.objectEngineVolume"),
                  accessor: "objectEngineVolume",
                },
              ],
            },
          ]}
          defaultPageSize={20}
          className="-striped -highlight"
          SubComponent={(row) => {
            return (
              <div className="subtable-block">
                <Tab.Container id="vertical-nav" defaultActiveKey="first">
                  <Row style={{ height: "100%" }}>
                    <Col style={{ maxWidth: 300 }}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="first">{this.props.t("contract.Customers")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">{this.props.t("contract.Settlements")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">{this.props.t("contract.Contracts")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">{this.props.t("contract.Documents")}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col className="subtable-right-panel">
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <CustomersSubTable
                            customers={this.props.customers.filter((el) => row.original.customers.some((el2) => el2 === el._id))}
                            removeCustomer={this.removeReference.bind(this, "customers", row)}
                            single={true}
                            row={row}
                          />
                          {this.props.auth.user.role !== "client" && (
                            <div>
                              <Button
                                disabled={this.props.auth.user.role === "client"}
                                variant="contained"
                                onClick={this.handleAddNewClick.bind(this, "customers", row)}
                                style={buttonStyle}
                              >
                                <i className="fas fa-plus-square"></i>
                                {this.props.t("Add new")}
                              </Button>
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <SettlementsSubTable
                            settlements={this.props.settlements.filter((el) => row.original.settlements.some((el2) => el2 === el._id))}
                            row={row}
                          ></SettlementsSubTable>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <ContractsSubTable
                            contracts={this.props.contracts.filter((el) => row.original.contracts.some((el2) => el2 === el._id))}
                            row={row}
                            removeContract={this.removeReference.bind(this, "contracts", row)}
                          ></ContractsSubTable>
                          {this.props.auth.user.role !== "client" && (
                            <div>
                              <Button
                                disabled={this.props.auth.user.role === "client"}
                                variant="contained"
                                onClick={this.handleAddNewClick.bind(this, "contracts", row)}
                                style={buttonStyle}
                              >
                                <i className="fas fa-plus-square"></i>
                                {this.props.t("Add new")}
                              </Button>
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          <div style={{ position: "relative" }}>
                            <div
                              className="photos-container"
                              style={{
                                textAlign: "center",
                                display: "inline-block",
                                position: "absolute",
                                width: 400,
                                top: 0,
                              }}
                            >
                              <FilesDragNDrop
                                objectData={{
                                  pref: row.original.owner,
                                  suf: row.original.objDate,
                                  entity: { object: row.original },
                                }}
                                docFolder={"object"}
                                handleAddedImage={this.handleAddedImage.bind(this, row)}
                              ></FilesDragNDrop>
                            </div>
                            <div
                              style={{
                                position: "relative",
                                left: 400,
                              }}
                            >
                              <div style={{ display: "inline-block", position: "relative" }}>
                                {row.original.objectPhotos.sort().map((fileName, ind) => {
                                  let dir = {
                                    folder:
                                      "object/" +
                                      row.original.owner
                                        .split("")
                                        .filter((el) => ["'", '"', "+", "/", ".", "*"].every((badS) => badS !== el))
                                        .join("") +
                                      "_" +
                                      row.original.objDate,
                                    fileName: fileName,
                                  };
                                  return (
                                    <div
                                      key={ind}
                                      className="filesUploaded"
                                      style={{
                                        margin: "10px 5px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: 150,
                                          height: 150,
                                          border: "1px solid lightgray",
                                          borderRadius: 9,
                                          textAlign: "right",
                                          position: "relative",
                                        }}
                                      >
                                        <div style={{ height: 25 }}>
                                          <p
                                            style={{
                                              overflowWrap: "anywhere",
                                              fontSize: "12px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              paddingLeft: 5,
                                              width: 120,
                                            }}
                                          >
                                            <a
                                              style={{
                                                color: "#033362",
                                                overflowWrap: "anywhere",
                                                fontSize: "12px",
                                              }}
                                              href={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {dir.fileName}
                                            </a>
                                          </p>
                                          <CancelIcon
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              right: 0,
                                              color: "#dc5a5a",
                                            }}
                                            onClick={this.deleteFile.bind(this, row.original, fileName)}
                                          />
                                        </div>
                                        {dir.fileName.substr(dir.fileName.length - 4) !== ".pdf" && (
                                          <div style={{ height: 100, textAlign: "center", background: "#e1e1e1", padding: "2px 0px" }}>
                                            <div
                                              style={{
                                                backgroundImage: 'url("' + URL + "/static/" + dir.folder + "/" + dir.fileName + '")',
                                                height: 110,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                              }}
                                            ></div>
                                          </div>
                                        )}
                                        {dir.fileName.substr(dir.fileName.length - 4) === ".pdf" && (
                                          <div style={{ position: "absolute", height: 125, overflow: "hidden" }}>
                                            <embed
                                              name="plugin"
                                              width="100%"
                                              src={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                              type="application/pdf"
                                            ></embed>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.helpers.loading,
  customers: state.customers.customersList,
  contracts: state.contracts.contractsList,
  objects: state.objects.objectsList,
  settlements: state.settlements.settlementsList,
  fileList: state.files.fileList,
});

export default connect(mapStateToProps, {
  getObjects,
  editObject,
  deleteObject,
  addObject,
  deleteFile,
  setSubModalContent,
  getSettlements,
  getContracts,
  editContract,
  getCustomers,
  removeContractReference,
  removeCustomerReference,
  removeObjectReference,
  editCustomer,
  setLoading,
})(withTranslation()(ObjectsTable));
