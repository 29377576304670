import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import ClientInfoStep from "./Stepper/clientInfoStep";
import DocumentsUploadStep from "./Stepper/documentsUploadStep";
import ObjectInfoStep from "./Stepper/objectInfoStep";
import ContractInfoStep from "./Stepper/contractInfoStep";
import { Modal } from "react-bootstrap";
import { emptyContract } from "../Common/emptyContract";
import { withTranslation, Trans } from "react-i18next";
import { CLIENTDETAILS, CONTRACTDETAILS, OBJECTDETAILS } from "../../CONSTANTS";
import { setLoading } from "../../actions/API_helpers";
import { useStore } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    background: "#033365",
    color: "white",
    "&.Mui-disabled": {
      background: "lightgray",
    },
    "&:hover": {
      background: "rgba(4, 51, 98, 0.8)",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function HorizontalLinearStepper(props) {
  const [newContract, setContract] = React.useState(emptyContract);
  const [entities, setEntities] = React.useState({});
  const [nextDisabled, setDisabled] = React.useState(true);
  const store = useStore();

  if (Object.keys(newContract).length === 0) {
    setContract(emptyContract);
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const stepTitles = getStepsArray().map((el) => el.title);

  function getStepsArray() {
    return [
      {
        title: <Trans i18nKey="stepperCustomerInfo">Customer Info</Trans>,
        component: <ClientInfoStep isNextDisabled={isDisabled} />,
      },
      {
        title: <Trans i18nKey="stepperInsuranceDetails">Insurance Details</Trans>,
        component: <ContractInfoStep isNextDisabled={isDisabled} />,
      },
      {
        title: <Trans i18nKey="stepperObjectDetails">Object Details</Trans>,
        component: <ObjectInfoStep isNextDisabled={isDisabled} />,
      },
      {
        title: <Trans i18nKey="documentsUpload">Documents Upload</Trans>,
        component: <DocumentsUploadStep entities={entities} />,
      },
    ];
  }

  function getStepContent(step) {
    if (step < 0 || step > 3) {
      return "Unknown step";
    }
    let stepComponent = getStepsArray()[step].component;
    return stepComponent;
  }

  function changeStep() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }



  function clickSave() {
    setLoading("saving");
    let _contract = JSON.parse(sessionStorage.getItem("newContractDraft"));
    props.saveContent(_contract).then((res) => {
      setLoading(false);
      setEntities(res);

      setContract(emptyContract);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    });
  }

  function onBackButtonClick() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function onResetButtonClick() {
    sessionStorage.setItem("newContractDraft", JSON.stringify(emptyContract));
    setActiveStep(0);
    // setContract(emptyContract);
  }

  function closeModal() {
    setActiveStep(0);
    props.closeModal();
  }

  function isDisabled() {
    // return false;

    const storeState = store.getState();

    let draftContract = JSON.parse(sessionStorage.getItem("newContractDraft"));

    if (
      storeState.contracts.contractsList.some((el) => el.contractNum === draftContract.contractNum && el.insType === draftContract.insType)
    ) {
      setDisabled(true);
      return;
    }

    const stepFields = [
      CLIENTDETAILS.map((el) => Object.assign({}, { ...el })),
      CONTRACTDETAILS.map((el) => Object.assign({}, { ...el })),
      OBJECTDETAILS.map((el) => Object.assign({}, { ...el })),
    ];
    stepFields[activeStep].forEach((f) => {
      f.mandatory = typeof f.mandatory === "function" ? f.mandatory(draftContract.insType, draftContract.type) : f.mandatory;
      return f;
    });
    let fieldsToCheck = Object.keys(draftContract).filter((el) => stepFields[activeStep].some((sfel) => sfel.id === el && sfel.mandatory));
    setDisabled(fieldsToCheck.some((key) => draftContract[key] === ""));
  }

  let currentCustomer = JSON.parse(sessionStorage.getItem("newContractDraft"));
  return (
    <>
      <Modal show={props.isShow} onHide={closeModal} backdrop={false} size="lg" dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Add New Contract {currentCustomer ? ` - ${currentCustomer.name}` : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Stepper activeStep={activeStep}>
              {stepTitles.map((label, ind) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={ind} {...stepProps}>
                    <StepLabel classes={{ active: "active-step-style" }} {...labelProps}>
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          {<div> {getStepContent(activeStep)} </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onResetButtonClick} className={classes.button} disabled={activeStep === 0 || activeStep === 3}>
            <Trans i18nKey="stepperReset">Reset</Trans>
          </Button>
          <Button onClick={onBackButtonClick} className={classes.button} disabled={activeStep === 0 || activeStep === 3}>
            <Trans i18nKey="stepperBack">Back</Trans>
          </Button>
          {activeStep === stepTitles.length - 1 && (
            <Button onClick={closeModal} className={classes.button}>
              <Trans i18nKey="stepperClose">Close</Trans>
            </Button>
          )}
          {activeStep < stepTitles.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              disabled={nextDisabled}
              onClick={activeStep === stepTitles.length - 2 ? clickSave : changeStep}
              className={classes.button}
            >
              {getButtonText()}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );

  function getButtonText() {
    return activeStep === stepTitles.length - 1 ? <Trans i18nKey="stepperSave">Save</Trans> : <Trans i18nKey="stepperNext">Next</Trans>;
  }
}

export default withTranslation()(HorizontalLinearStepper);
