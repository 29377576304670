import axios from "axios";
import { GET_ERRORS, GET_ALL_OBJECTS, DELETE_OBJECT, ADD_OBJECT, EDIT_OBJECT } from "./types";
import { URL } from "../CONSTANTS";

export const getObjects = (user) => (dispatch) => {
  return axios
    .get(URL + "/api/obj/getobjects", user)
    .then((res) => {
      dispatch({
        type: GET_ALL_OBJECTS,
        payload: res.data,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const editObject = (obj) => (dispatch) => {
  return axios
    .put(URL + "/api/obj/update", { params: obj })
    .then((res) => {
      dispatch({
        type: EDIT_OBJECT,
        payload: obj,
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const deleteObject = (id) => (dispatch) => {
  return axios
    .delete(URL + "/api/obj/delete", { params: { id: id } })
    .then((res) => {
      dispatch({
        type: DELETE_OBJECT,
        payload: { id },
      });

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

export const addObject = (obj) => (dispatch) => {
  return axios
    .post(URL + "/api/obj/addobject", obj)
    .then((res) => {
      dispatch({
        type: ADD_OBJECT,
        payload: obj,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeObjectReference = (from, objRow, subTableRow) => (dispatch) => {
  return axios
    .put(URL + "/api/obj/removereference", {
      params: {
        from,
        id: objRow._id,
        subId: subTableRow._id,
      },
    })
    .then((res) => {
      dispatch({
        type: EDIT_OBJECT,
        payload: objRow,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};
