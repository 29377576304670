import { GET_ALL_CUSTOMERS, ADD_CUSTOMER, EDIT_CUSTOMER, DELETE_CUSTOMER } from "../actions/types";
// import isEmpty from '../helpers/is-empty';

const initialState = {
  customersList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CUSTOMERS:
      return {
        ...state,
        customersList: action.payload,
      };
    case ADD_CUSTOMER:
      let exist = state.customersList.find((el) => el._id === action.payload._id);
      return {
        ...state,
        customersList: exist ? state.customersList : state.customersList.concat(action.payload),
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        customersList: state.customersList.filter((cstr) => cstr._id !== action.payload.id),
      };
    case EDIT_CUSTOMER:
      return {
        ...state,
        customersList: state.customersList.map((obj) => {
          if (obj._id === action.payload._id) {
            return { ...obj, ...action.payload };
          }
          return obj;
        }),
      };
    default:
      return state;
  }
}
