import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authentication";
import { withRouter } from "react-router-dom";
//import { getExRate } from "../../actions/API_helpers";
import i18n from "../../i18n";
import { withTranslation, Trans } from "react-i18next";
import Badge from "@material-ui/core/Badge";

// import data from "../../2017";
// import readAndSplitData from "../../xlsImport";

const exRateStyles = {
  fontSize: 12,
  position: "absolute",
  top: 60,
  right: 5,
  fontWeight: "bold",
};
const navBarStyle = {
  background: "rgb(3, 51, 98)",
  padding: 0,
};
class Header extends Component {
  constructor(props) {
    super();
    this.state = {
      current: "home",
    };
    // this.changeLanguage = lng => {
    //     i18n.changeLanguage(lng);
    // };
  }

  onLogout(e) {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
  }

  componentDidMount() {
    //if (localStorage.getItem("rates") === "undefined" || localStorage.getItem("ratesDate") !== String(new Date().getDate())) {
      //this.props.getExRate().then((res) => {
      //  localStorage.setItem("rates", JSON.stringify(res));
      //  localStorage.setItem("ratesDate", new Date().getDate());
      //});
    //}
  }

  render() {
    let current = this.props.location.pathname;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (
      <Navbar style={navBarStyle}>
        <Navbar.Brand style={{ width: 145, padding: 0 }}>
          <Link to="/home">
            <img src="FINALE.svg" className="App-logo" alt="logo" />
          </Link>
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Link className={"nav-link " + (current === "/home" ? "active" : "")} to="/home">
            <Trans i18nKey="home">Home</Trans>
          </Link>
          <Link className={"nav-link " + (current === "/contracts" ? "active" : "")} to="/contracts">
            <Trans i18nKey="contracts">contracts</Trans>
          </Link>
          <Link className={"nav-link " + (current === "/customers" ? "active" : "")} to="/customers">
            <Trans i18nKey="customers">Customers</Trans>
          </Link>
          <Link className={"nav-link " + (current === "/objects" ? "active" : "")} to="/objects">
            <Trans i18nKey="objects">Objects</Trans>
          </Link>
          <Link className={"nav-link " + (current === "/settlements" ? "active" : "")} to="/settlements">
            <Trans i18nKey="settlements">Settlements</Trans>
          </Link>
          <Link className={"nav-link " + (current === "/analytics" ? "active" : "")} to="/analytics">
            <Trans i18nKey="analytics">Analytics</Trans>
          </Link>
          <Badge badgeContent={this.props.tasks.filter((t) => !t.isDone).length} color="secondary">
            <Link className="nav-link" to="/tasks">
              <Trans i18nKey="tasks">Tasks</Trans>
            </Link>
          </Badge>
        </Nav>

        <Nav>
          <div style={exRateStyles}>
            {
              <>
                <span style={{ margin: "0 5px" }}>{this.props.auth.user.name} </span> |
                <span style={{ margin: "0 5px" }}>
                  {new Date().getDate() + "/" + (new Date().getMonth() + 1) + "/" + new Date().getFullYear()}
                </span>
                |
                <span style={{ margin: "0 5px" }}>
                  USD: {this.getCurrencyRate("usd")} EUR: {this.getCurrencyRate("eur")}
                </span>
              </>
            }
          </div>
          <div className="nav-link" style={{ color: "white" }}>
            {/* <span className="pointer" onClick={() => readAndSplitData(data)}>
              (ImportFromXLS)
            </span> */}
            <span
              style={this.props.i18n.language === "en" ? { fontWeight: 700 } : {}}
              className="pointer"
              onClick={() => changeLanguage("en")}
            >
              EN{" "}
            </span>
            |
            <span
              style={this.props.i18n.language === "ua" ? { fontWeight: 700 } : {}}
              className="pointer"
              onClick={() => changeLanguage("ua")}
            >
              {" "}
              UA
            </span>
          </div>
          {this.props.auth.user.role !== "client" && (
            <Link className="nav-link" style={{ color: "white" }} to="/settings">
              Settings
            </Link>
          )}
          <Link className="nav-link" style={{ color: "white" }} onClick={this.onLogout.bind(this)} to="/logout">
            Logout <i className="fas fa-sign-out-alt"></i>
          </Link>
        </Nav>
      </Navbar>
    );
  }

  getCurrencyRate(currency) {
    if (localStorage.getItem("rates") !== null && localStorage.getItem("rates") !== "undefined") {
      return JSON.parse(localStorage.getItem("rates")).find((el) => el.cc === currency.toUpperCase()).rate;
    }
    return "----";
  }
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  tasks: state.tasks.tasksList,
//  rates: state.helpers.rates,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(withTranslation()(Header)));
