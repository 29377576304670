import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { addLog } from "../../actions/API_helpers";
import { buttonStyle } from "../Common/Styles";
// import { emptyContract } from '../../Common/emptyContract'
import Autosuggester from "../../helpers/autoSuggester";
import Chip from "@material-ui/core/Chip";

const blockLabel = {
  marginBottom: 0,
  width: "100%",
  fontWeight: "bold",
};
const chipStyle = {
  width: "100%",
};

const displayText = {
  contracts: "contractNum",
  objects: "owner",
  customers: "name",
  settlements: "caseNumber",
};

export class AttachItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsToAdd: [],
      searchList: [],
    };
    this.setAutosuggestedData = this.setAutosuggestedData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
  }

  setAutosuggestedData(data) {
    let searchList = this.props[this.props.contentType]?.length
      ? this.props[this.props.contentType]
      : // .filter(el => this.state.itemsToAdd.every(item => item.id !== el.id))
        // .filter(item => this.props.fromRow.original[this.props.contentType].every(items => items !== item.id))
        [];

    if (this.state.itemsToAdd.length === 0) {
      searchList = this.props[this.props.contentType].filter((item) =>
        this.props.fromRow.original[this.props.contentType].every((items) => items !== item._id)
      );
    }

    this.setState({
      itemsToAdd: [...this.state.itemsToAdd, data],
      searchList,
    });
  }

  closeModal() {
    this.setState({
      itemsToAdd: [],
    });
    this.props.closeModal();
  }

  saveAndClose() {
    let idsToAdd = [];
    this.state.itemsToAdd.forEach((el) => idsToAdd.push(el._id));
    this.props.fromRow.original[this.props.contentType] = this.props.fromRow.original[this.props.contentType].concat(idsToAdd);

    this.props.saveContent(this.props.fromRow.original);
    this.closeModal();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.contentType && this.props.contentType !== prevProps.contentType) {
      this.setState({
        searchList: this.getListWithoutAlreadyAddedItems(),
      });
    }
  }

  getListWithoutAlreadyAddedItems() {
    return this.props[this.props.contentType].filter((itemFromProps) =>
      this.props.fromRow.original[this.props.contentType].every((itemIdFromRow) => itemIdFromRow !== itemFromProps._id)
    );
  }

  render() {
    console.log("AttachItemModal rendered");
    return (
      <>
        <Modal show={this.props.showAttachItemModal} onHide={this.closeModal} size="lg" dialogClassName="modal-90w">
          <Modal.Header closeButton>
            <Modal.Title> Add new {this.props.contentType}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={blockLabel}>
              <Chip label="Select from existing" style={chipStyle} />
            </div>
            <Autosuggester searchList={this.state.searchList} title={this.props.contentType} onSelection={this.setAutosuggestedData} />
            <br />
            {this.state.itemsToAdd.length ? "You are going to add:" : ""}
            {this.state.itemsToAdd.map((el, ind) => {
              return <div key={ind}>{ind + 1 + ". " + el[displayText[this.props.contentType]]}</div>;
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" style={buttonStyle} onClick={this.closeModal}>
              Close
            </Button>
            <Button variant="contained" style={buttonStyle} onClick={this.saveAndClose}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>{" "}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  contracts: state.contracts.contractsList,
  objects: state.objects.objectsList,
  customers: state.customers.customersList,
  settlements: state.settlements.settlementsList,
});

export default connect(mapStateToProps, { addLog })(AttachItemModal);
