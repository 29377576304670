import React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import SettlementsTable from "./SettlementsTable";
// import { mockedData } from '../customers'
import { addSettlement, getSettlements, editSettlement, deleteSettlement } from "../../actions/settlements";
import { editObject } from "../../actions/objects";
import { editCustomer } from "../../actions/customers";
import { editContract } from "../../actions/contracts";
import SettlementsModal from "./SettlementsModal";
import { actionButtonsContainer, containerStyle, buttonStyle } from "../Common/Styles";
import ClipLoader from "react-spinners/ClipLoader";
import i18n from "../../i18n";
const { REACT_APP_ENV } = process.env;

export class SettlementsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubModal: false,
      subModalViewType: null,
      showModal: false,
    };
    this.props.getSettlements();
    this.saveSettlement = this.saveSettlement.bind(this);
    this.handleSubModalClick = this.handleSubModalClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  openModal = function () {
    this.setState({
      showModal: true,
    });
  };

  closeModal = function () {
    this.setState({
      showModal: false,
    });
  };

  handleAddNewClick = function (target, context) {
    this.setState({
      showSubModal: !this.state.showSubModal,
      subModalViewType: target,
    });
  };

  handleSubModalClick = function () {
    this.setState({
      showSubModal: !this.state.showSubModal,
      subModalViewType: null,
    });
  };

  saveSettlement(data) {
    console.log(data);

    this.props.addSettlement(data).then((res) => {
      if (!res) return;
      //update contract with new settlement
      let contractToUpdate = this.props.contracts.find((contr) => contr._id === data.contractId);
      contractToUpdate.settlements.push(res._id);
      this.props.editContract(contractToUpdate).then(
        (resp) => {
          console.log("contract updated with new settlement");
        },
        (err) => {
          console.log("contract NOT updated with new settlement", err);
        }
      );

      //update object with new settlement
      let objectToUpdate = this.props.objects.find((obj) => obj._id === data.objectId);
      objectToUpdate.settlements.push(res._id);
      this.props.editObject(objectToUpdate).then(
        (resp) => {
          console.log("object updated with new settlement");
        },
        (err) => {
          console.log("object NOT updated with new settlement", err);
        }
      );

      //update customer with new settlement
      let customerToUpdate = this.props.customers.find((cust) => cust._id === data.customerId);
      customerToUpdate.settlements.push(res._id);
      this.props.editCustomer(customerToUpdate).then(
        (resp) => {
          console.log("customer updated with new settlement");
        },
        (err) => {
          console.log("customer NOT updated with new settlement", err);
        }
      );

      this.setState({
        showModal: false,
      });
      this.props.getSettlements();
    });
  }

  componentDidMount() {
    console.log(REACT_APP_ENV);
    console.log(process.env.NODE_ENV);
  }

  render() {
    return (
      <>
        {this.props.loading && (
          <div className="sweet-loading">
            <ClipLoader size={150} color={"#123abc"} loading={Boolean(this.props.loading)} />
          </div>
        )}
        <div>
          <SettlementsModal
            isShow={this.state.showModal}
            saveContent={this.saveSettlement}
            closeModal={this.closeModal}
            openModal={this.openModal}
          />
          <div style={containerStyle}>
            {this.props.auth.user.role !== "client" && (
              <div style={actionButtonsContainer}>
                <Button variant="contained" style={buttonStyle} id="addObjectButton" onClick={this.openModal}>
                  <i className="fas fa-plus-square"></i> {i18n.t("addNew")}
                </Button>
                {/* <Button variant="contained" style={buttonStyle} onClick={this.handleCollapse}><i className={'fas fa-caret-square-' + (Object.keys(this.state.folded).length === 0 ? "left" : "right")}></i> {Object.keys(this.state.folded).length === 0 ? "Collapse" : "Expand" }</Button>                         */}
                {/* <Button variant="contained" style={buttonStyle} ><i className="fas fa-eraser"></i> Clear filters</Button> */}
                <Button variant="contained" style={buttonStyle} onClick={this.exportToExcel}>
                  <i className="fas fa-file-excel"></i> {i18n.t("exportToExcel")}
                </Button>
              </div>
            )}
            <SettlementsTable />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  customers: state.customers.customersList,
  contracts: state.contracts.contractsList,
  objects: state.objects.objectsList,
  settlements: state.settlements.settlementsList,
  loading: state.helpers.loading,
});

export default connect(mapStateToProps, {
  addSettlement,
  getSettlements,
  editSettlement,
  deleteSettlement,
  editObject,
  editContract,
  editCustomer,
})(SettlementsView);
