import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Users } from "./UsersTable";
import { getAllUsers, deleteUser, editUser, registerUser } from "../../../actions/users";
import UsersModal from "./UsersModal";
import { actionButtonsContainer, buttonStyle } from "../../Common/Styles";

class UsersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      showModal: false,
      currentUser: null,
    };

    this.deleteUsers = this.deleteUsers.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.editUser = this.editUser.bind(this);
  }

  handleClick = function () {
    this.setState({
      showModal: !this.state.showModal,
      currentUser: null,
    });
  };

  handleSave = function (isEdit, newUser) {
    if (isEdit) {
      // handle user edit
      var noUsernameAndHash = { ...newUser };
      // delete noUsernameAndHash.username;

      //delete password so that empty string
      //is not saved instead of password
      if (!noUsernameAndHash.hash) {
        delete noUsernameAndHash.hash;
      }
      //adding _id
      noUsernameAndHash._id = this.state.currentUser._id;

      this.props.editUser(noUsernameAndHash).then((suc) => {
        try {
          this.props.getAllUsers().then((res) => {
            this.setState({
              users: res.map((user) => {
                user.createdDate = new Date(user.createdDate).toGMTString();
                return user;
              }),
              showModal: !this.state.showModal,
            });
          });
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      //handle user save
      const user = {
        username: newUser.username,
        email: newUser.email,
        hash: newUser.hash,
        mobilePhone: newUser.mobilePhone,
        legalName: newUser.legalName,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        customerId: newUser.customerId,
        role: newUser.role,
        createdDate: Date.now(),
      };
      this.props.registerUser(user).then((res) => {
        this.setState({
          users: this.props.users.map((user) => {
            user.createdDate = new Date(user.createdDate).toGMTString();
            return user;
          }),
          showModal: !this.state.showModal,
        });
      });
    }
  };

  deleteUsers = function (params) {
    this.props.deleteUser(params).then((res) => {
      this.setState({
        users: this.state.users.filter((user) => user._id !== params),
      });
    });
  };

  editUser = function (currentUser) {
    this.setState({ currentUser, showModal: true });
  };

  componentDidMount() {
    this.props.getAllUsers().then((res) => {
      this.setState({
        users: res.map((user) => {
          user.createdDate = new Date(user.createdDate).toGMTString();
          return user;
        }),
      });
    });
  }

  render() {
    return (
      <div className="users-view">
        <div style={actionButtonsContainer}>
          <Button style={buttonStyle} variant="contained" onClick={this.handleClick}>
            Add User
          </Button>
          <Button style={buttonStyle} onClick={this.exportToExcel} variant="contained">
            Export to Excel
          </Button>
          <Users users={this.state.users} editUser={this.editUser} deleteUser={this.deleteUsers} />
          <UsersModal fromState={this.state} showModal={this.state.showModal} handleSave={this.handleSave} handleClick={this.handleClick} />
        </div>
      </div>
    );
  }
}

UsersView.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users.usersList,
});

export default connect(mapStateToProps, { getAllUsers, deleteUser, editUser, registerUser })(UsersView);
