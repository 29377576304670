import React from "react";
import ReactTable from "react-table-6";
import { OBJECTDETAILS } from "../../CONSTANTS";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import i18n from "../../i18n";
import ObjectsModal from "../Objects/ObjectsModal";
import { setSubModalContent } from "../../actions/modals";
import { connect } from "react-redux";
import { addSlashesToString } from "../../helpers/dateTransformations";

class ObjectsSubTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      filteredCount: this.props.objects.length,
    };
  }

  removeObject = (objData) => {
    this.props.row.original.objects = this.props.row.original.objects.filter((el) => el !== objData._id);
    this.props.removeObject(objData);
  };

  setModalContentAndOpenForEdit = (fromRow) => {
    this.props.setSubModalContent(fromRow);

    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    let cols = [];
    OBJECTDETAILS.map((el) => cols.push({ Header: el.title, accessor: el.id }));
    OBJECTDETAILS.forEach((el, ind) => {
      if (el.type === "date") {
        cols[ind].Cell = function (props) {
          if (props.original[el.id]) {
            return addSlashesToString(props.original[el.id]);
          }
        };
      }
    });

    cols.unshift({
      Header: i18n.t("actions"),
      accessor: "action",
      filterable: false,
      Cell: (row) => (
        <ButtonGroup size="small" variant="outlined" color="primary" aria-label="small outlined button group">
          <Button color="primary" onClick={this.setModalContentAndOpenForEdit.bind(this, row.original)}>
            <i className="far fa-edit fa-lg"></i>
          </Button>
          <Button color="secondary" onClick={this.removeObject.bind(this, row.original)}>
            <i className="far fa-trash-alt fa-lg"></i>
          </Button>
        </ButtonGroup>
      ),
    });

    cols.unshift({
      Header: this.state.filteredCount,
      accessor: "number",
      width: 70,
      filterable: false,
      Cell: (row) => {
        return row.index + 1 + ".";
      },
    });

    return (
      <>
        <ObjectsModal isShow={this.state.showModal} closeModal={this.closeModal}></ObjectsModal>
        <ReactTable
          // data={props.objects.filter(obj => { return props.row.original.objects.some(fromContract => fromContract === obj.id) })}
          data={this.props.objects}
          filterable
          columns={cols}
          ref={(r) => {
            this.selectTable = r;
          }}
          onFilteredChange={(f, c) => {
            this.setState({
              filteredCount: this.selectTable.getResolvedState().sortedData.length,
            });
          }}
          defaultPageSize={5}
          className="-striped -highlight"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modalContent: state.modals.modalContent,
});

export default connect(mapStateToProps, { setSubModalContent })(ObjectsSubTable);
