import { stringToDate, addSlashesToString } from "../helpers/dateTransformations";

export const excelSaver = function (contractsArr, name) {
  if (!contractsArr || contractsArr.length === 0) {
    return;
  }

  let prepared = getPreparedContracts(contractsArr);

  let header = [];
  let tab_text = "<table><thead><tr>";
  for (let key in prepared[0]) {
    header.push(key);
  }

  for (let i = 0; i < header.length - 1; i++) {
    // processing only "paymentParts" and not other objects/arrays (e.g. ContractPhotos)
    if (prepared[0][header[i]] && typeof prepared[0][header[i]] === "object") {
      // if (header[i] !== "paymentParts") {
      //   tab_text = tab_text + "<th>1</th>";
      // } else {
      for (let v in prepared[0][header[i]]) {
        for (let t in prepared[0][header[i]][v]) {
          tab_text = tab_text + "<th>" + v + "_" + t + "</th>";
        }
      }
      // }
    } else {
      tab_text = tab_text + "<th>" + header[i] + "</th>";
    }
  }
  tab_text = tab_text + "</tr></thead><tbody>";

  for (let i = 0; i < prepared.length; i++) {
    tab_text = tab_text + "<tr>";
    for (let y = 0; y < header.length - 1; y++) {
      if (prepared[i][header[y]] && typeof prepared[i][header[y]] === "object") {
        // if (header[y] !== "paymentParts") {
        //   tab_text = tab_text + "<td>1</td>";
        // } else {
        for (let v in prepared[i][header[y]]) {
          for (let t in prepared[i][header[y]][v]) {
            tab_text = tab_text + "<td>" + prepared[i][header[y]][v][t] + "</td>";
          }
        }
        // }
      } else {
        tab_text = tab_text + "<td>" + prepared[i][header[y]] + "</td>";
      }
    }
    tab_text = tab_text + "</tr>";
  }
  tab_text = tab_text + "</tbody></table>";

  var link = document.createElement("a");
  link.href = "data:application/vnd.ms-excel," + encodeURIComponent(tab_text);
  link.download = name ? name : "Report.xls";
  link.click();
};

function getPreparedContracts(rawContracts) {
  var prepared = [];
  let EXCEL_FIELDS = {
    insType: null,
    contractDate: null,
    insCompany: null,
    contractNum: null,
    contractStartDate: null,
    contractEndDate: null,
    name: null,
    insAmount: null,
    insPremiya: null,
    currency: null,
    insTariff: null,
    insFranchise: null,
    insFranchiseType: null,
    paymentScheme: null,
    paymentAmount: null,
    commissionPercentage: null,
    commissionCurrency: null,
    contractManager: null,
    contractAgent: null,
    contractSubagent: null,
    contractType: null,
    contractStatus: null,
  };

  rawContracts.forEach((el) => {
    let valueFromOriginalObject = el["_original"] ? el["_original"] : el;
    EXCEL_FIELDS.commissionPercentage = valueFromOriginalObject.paymentParts.first.commissionP;
    function sumUpPayments(objectToIterate, param) {
      return Object.keys(objectToIterate.paymentParts).reduce((acc, value, ind, arr) => {
        let isPaid = objectToIterate.paymentParts[value].paid;
        let inFilteredDateRangeFrom = true;
        // let inFilteredDateRangeFrom = stringToDate(objectToIterate.paymentParts[value].date) >= new Date(sessionStorage.getItem("filterDateRangeStartDate"));
        let inFilteredDateRangeTo =
          stringToDate(objectToIterate.paymentParts[value].date) <= new Date(sessionStorage.getItem("filterDateRangeEndDate"));

        if (isPaid && inFilteredDateRangeFrom && inFilteredDateRangeTo) {
          acc = acc + objectToIterate.paymentParts[value][param];
        }
        return acc;
      }, 0);
    }

    EXCEL_FIELDS.paymentAmount = sumUpPayments(valueFromOriginalObject, "amount");
    EXCEL_FIELDS.commissionCurrency = sumUpPayments(valueFromOriginalObject, "commissionC");

    for (let param in EXCEL_FIELDS) {
      EXCEL_FIELDS[param] = valueFromOriginalObject[param] ? valueFromOriginalObject[param] : EXCEL_FIELDS[param];
      if (param.includes("Date")) {
        EXCEL_FIELDS[param] = addSlashesToString(EXCEL_FIELDS[param]);
      }

      if (param === "insAmount" || param === "insPremiya" || param === "paymentAmount" || param === "commissionCurrency") {
        EXCEL_FIELDS[param] = String(EXCEL_FIELDS[param]).replace(".", ",");
      }
    }
    prepared.push(Object.assign({}, { ...EXCEL_FIELDS }));
  });

  return prepared;
}
