import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Autosuggester from "../../../helpers/autoSuggester";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
// import { fromDBDate, isDate, dateToManipulate } from '../../../helpers/dataTransformations';
import { withTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { CLIENTDETAILS } from "../../../CONSTANTS";

const inputStyles = {
  marginRight: 5,
  marginLeft: 5,
  minWidth: 150,
  width: "calc(92% / 5)",
};

const blockLabel = {
  marginBottom: 0,
  width: "100%",
  fontWeight: "bold",
};
const chipStyle = {
  width: "100%",
  background: "#033365",
  color: "white",
  borderRadius: 4,
};

class ClientInfoStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientFields: this.getClientFields(),
      customers: props.initCustomers,
    };
    this.setAutosuggestedData = this.setAutosuggestedData.bind(this);
  }

  getClientFields() {
    let draftContract = JSON.parse(sessionStorage.getItem("newContractDraft"));
    return CLIENTDETAILS.map((param) => {
      let val = Object.assign({}, { ...param });
      val.value = draftContract ? draftContract[param.id] : "";
      return val;
    });
  }

  validateAndSave(field, event) {
    // TODO: add validation to input
    if (this.isNotValid(field.type, event)) {
      return;
    }
    this.updateState(field, event);
    this.saveData();
    this.props.isNextDisabled();
  }

  isNotValid(type, event) {
    if (type === "number" && event.target.value) {
      return !/^\d*(\.|,){0,1}\d{0,2}$/g.test(event.target.value);
    }

    return false;
  }

  saveData() {
    let preparedData = {};
    this.state.clientFields.forEach((e) => (preparedData[e.id] = e.value));
    let storageData = JSON.parse(sessionStorage.getItem("newContractDraft"));
    sessionStorage.setItem("newContractDraft", JSON.stringify({ ...storageData, ...preparedData }));
  }

  updateState(field, event) {
    let stateCopy = [...this.state.clientFields];
    stateCopy[this.findElementIndexByID(stateCopy, field.id)].value =
      field.type === "number" ? event.target.value.replace(",", ".") : event.target.value;
    this.setState({
      clientFields: stateCopy,
    });
  }

  findElementIndexByID(arr, fieldId) {
    return arr.indexOf(arr.find((el) => el.id === fieldId));
  }

  getStyle = (item) => {
    if (this.state.clientFields.find((f) => f.id === "type").value === "фізична особа") {
      if (item.id === "edrpou" || item.id === "bankName" || item.id === "mfo" || item.id === "accountNumber" || item.id === "director") {
        return { display: "none" };
      }
    }
    if (this.state.clientFields.find((f) => f.id === "type").value === "фізична особа підприємець" && item.id === "director") {
      return { display: "none" };
    }
    if (this.state.clientFields.find((f) => f.id === "type").value === "юридична особа") {
      if (
        item.id === "gender" ||
        item.id === "documentType" ||
        item.id === "documentNumber" ||
        item.id === "documentIssuer" ||
        item.id === "documentIssueDate" ||
        item.id === "documentExpiryDate" ||
        item.id === "citizenship"
      ) {
        return { display: "none" };
      }
    }
    return inputStyles;
  };

  setAutosuggestedData(clientData) {
    this.setState({
      clientFields: this.state.clientFields.map((item) => {
        item.value = clientData[item.id];
        return item;
      }),
    });
    this.saveData();
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        allowLeadingZeros={true}
        format="##/##/####"
        placeholder="DD/MM/YYYY"
        mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
      />
    );
  }

  render() {
    return (
      <>
        <div style={blockLabel}>
          <Chip label={this.props.t("Select from existing")} style={chipStyle} />
        </div>
        <Autosuggester searchList={this.state.customers} title={this.props.t("customer")} onSelection={this.setAutosuggestedData} />
        <br />
        <div style={blockLabel}>
          <Chip label={this.props.t("Add new")} style={chipStyle} />
        </div>
        {this.state.clientFields.map((headerItem, ind) => {
          if (headerItem.type === "date") {
            return (
              <TextField
                label={headerItem.title}
                style={this.getStyle(headerItem)}
                variant="outlined"
                margin="normal"
                key={ind}
                value={headerItem.value}
                onChange={this.validateAndSave.bind(this, headerItem)}
                InputProps={{
                  inputComponent: this.NumberFormatCustom,
                }}
              />
            );
          } else if (headerItem.type === "select") {
            return (
              <TextField
                label={headerItem.title}
                select
                style={this.getStyle(headerItem)}
                key={ind}
                rowsMax="4"
                variant="outlined"
                value={headerItem.value}
                onChange={this.validateAndSave.bind(this, headerItem)}
                margin="normal"
              >
                {headerItem.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            );
          } else {
            return (
              <TextField
                label={headerItem.title}
                multiline
                style={this.getStyle(headerItem)}
                key={ind}
                rowsMax="4"
                variant="outlined"
                value={headerItem.value}
                onChange={this.validateAndSave.bind(this, headerItem)}
                margin="normal"
              />
            );
          }
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  initCustomers: state.customers.customersList,
  modals: state.modals.modalContent,
});

export default connect(mapStateToProps, {})(withTranslation()(ClientInfoStep));
