import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactTable from "react-table-6";
import matchSorter from "match-sorter";
import "react-table-6/react-table.css";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import CustomersSubTable from "../Subtables/CustomersSubTable";
import ObjectsSubTable from "../Subtables/ObjectsSubTable";
import SettlementsSubTable from "../Subtables/SettlementsSubTable";
import { buttonStyle } from "../Common/Styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { getContractsReport } from "../../actions/contracts";
import { getContracts, addContract, deleteContract, editContract } from "../../actions/contracts";
import { addCustomer, editCustomer } from "../../actions/customers";
import { addObject, deleteObject, editObject } from "../../actions/objects";
import { setLoading, addLog } from "../../actions/API_helpers";
import EditContractModal from "../Contracts/EditContractModal";
import AttachItemModal from "../Subtables/AttachItemModal";
// import { dateToPresent } from '../../helpers/dataTransformations'
import { setSubModalContent } from "../../actions/modals";
import { withTranslation } from "react-i18next";
import { URL, CONTRACTDETAILS } from "../../CONSTANTS";
import FilesDragNDrop from "../Common/DragAndDrop";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import { emptyContract } from "../Common/emptyContract";
import { addSlashesToString } from "../../helpers/dateTransformations";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

class ContractsTableForHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showEditModal: false,
      expanded: {},
      showAttachItemModal: false,
      attachItemModalViewType: null,
      statusSelectValue: "",
      contracts: [],
    };

    this.selectTable = React.createRef();

    this.saveContract = this.saveContract.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.deleteContract = this.deleteContract.bind(this);
    this.closeAttachItemModal = this.closeAttachItemModal.bind(this);
    this.addItems = this.addItems.bind(this);
    this.updateObjects = this.updateObjects.bind(this);
    this.removeAllReferences = this.removeAllReferences.bind(this);
    this.handleRowExpanded = this.handleRowExpanded.bind(this);
  }

  componentDidMount() {
    let { customers, contracts } = this.props;
    contracts = this.props.contractsReportData[JSON.parse(sessionStorage.getItem("isPayment"))][sessionStorage.getItem("key")];
    contracts.sort((a, b) => b.createdDate - a.createdDate).forEach((contract) => (contract.name = findCustomerName(customers, contract)));

    this.setState({
      contracts,
    });
  }

  handleRowExpanded(newExpanded, index, event) {
    if (this.state.expanded[index]) {
      this.setState({
        expanded: { [index]: false },
      });
    } else {
      this.setState({
        expanded: { [index]: true },
      });
    }
  }

  deleteFile = (contract, ind) => {
    contract.contractPhotos.splice(ind, 1);
    this.props.editContract(contract).then((res) => {
      console.log("file deleted: " + res);
    });
  };

  handleAddedImage(row) {
    this.setState({
      expanded: { [row.index]: true },
    });
  }

  updateContract(data) {
    this.props.setLoading(true);

    this.props.editContract(data).then(
      (res) => {
        this.props.getContracts();
      },
      (err) => {
        console.log("SUCCESS!", err);
      }
    );

    this.setState({
      showAttachItemModal: false,
    });

    this.props.setSubModalContent({});
  }

  updateObjects(data) {
    let objectsToIterate = this.props.objects.filter((obj) => data.objects.some((obj2) => obj2 === obj._id));

    objectsToIterate.forEach((obj) => {
      //add contractId to objects if object.contracts does not contain id of current contract
      if (!obj.contracts.some((obj) => obj === data._id)) {
        obj.contracts.push(data._id);
        this.props.editObject(obj).then(
          (res) => {
            console.log("Object added", res);
          },
          (err) => {
            console.log("Object not added", err);
          }
        );
      }
    });
  }

  addItems(data) {
    this.updateContract(data);

    this.updateObjects(data);
    // updateSettlements()
  }

  handleAddNewClick = function (target, row, event) {
    this.setState({
      showAttachItemModal: !this.state.showAttachItemModal,
      attachItemModalViewType: target,
      row,
    });
  };

  closeAttachItemModal = function () {
    this.setState({
      showAttachItemModal: !this.state.showAttachItemModal,
      attachItemModalViewType: null,
    });
  };

  closeSubModal = function () {
    sessionStorage.removeItem("newContractDraft");
    this.setState({
      showEditModal: false,
    });
  };

  openEditModal = function (row) {
    this.setState({
      showEditModal: true,
      row,
    });
  };

  putDataToStoreAndOpenModal(contract) {
    let _contract = Object.assign({}, { ...emptyContract });
    for (let param in _contract) {
      if (param !== "paymentParts") {
        _contract[param] = contract[param] || "";
      } else {
        _contract[param] = Object.assign({ ..._contract[param] }, { ...contract[param] });
      }
    }
    sessionStorage.setItem("newContractDraft", JSON.stringify(_contract));

    // this.props.setSubModalContent(contract);
    this.openEditModal(contract);
  }

  removeItem(itemType, contract) {
    this.saveContract(contract);

    // Remove ids from
    this.removeAllReferences(contract, itemType);
  }

  removeAllReferences(contract, itemType) {
    let objectsToIterate = this.props[itemType].filter((obj) => obj.contracts.some((contr) => contr === contract.id));
    objectsToIterate.forEach((obj) => {
      //add contractId to objects if object.contracts does not contain id of current contract
      obj.contracts = obj.contracts.filter((contrct) => contrct !== contract._id);

      if (itemType === "objects") {
        this.props.editObject(obj).then(
          (res) => {
            console.log("contractId also removed from Object", res);
          },
          (err) => {
            console.log("Failed to remove contractId from Object", err);
          }
        );
      } else if (itemType === "customers") {
        this.props.editCustomer(obj).then(
          (res) => {
            console.log("contractId also removed from Customer", res);
          },
          (err) => {
            console.log("Failed to remove contractId from Customer", err);
          }
        );
      }
      // else if (itemType === 'settlements') {
      //   this.props.editSettlement(obj).then(res => {
      //     console.log('contractId also removed from Settlements', res);
      //   }, err => {
      //     console.log('Failed to remove contractId from Settlements', err);
      //   });
      // }
    });
  }

  handleStatusChange(props, event) {
    var contractTerminationReason = "";
    let _contracts = Object.assign([], [...this.state.contracts]);

    if (event.target.value === "недіючий" || event.target.value === "розторгнутий") {
      contractTerminationReason = window.prompt("Будь ласка, вкажіть причину/Please add reason");
      _contracts[props.index].comment += contractTerminationReason;
    }
    _contracts[props.index].contractStatus = event.target.value;

    let changedContract = _contracts[props.index];
    this.setState({
      contracts: _contracts,
    });

    let _contract = Object.assign({}, { ...emptyContract });
    for (let param in _contract) {
      if (param !== "paymentParts") {
        _contract[param] = changedContract[param] || "";
      } else {
        _contract[param] = Object.assign({ ..._contract[param] }, { ...changedContract[param] });
      }
    }
    _contract._id = changedContract._id;
    sessionStorage.setItem("newContractDraft", JSON.stringify(_contract));
    // this.saveContract();

    this.props.editContract(JSON.parse(sessionStorage.getItem("newContractDraft")));
    this.setState({ contracts: this.state.contracts.filter((con) => con._id !== _contract._id) });
    this.props.setSubModalContent({});
  }

  saveContract = function () {
    this.props.setLoading(true);
    let _contract = JSON.parse(sessionStorage.getItem("newContractDraft"));

    this.props.editContract(_contract).then((res) => {
      // setting a key to let HOME page know if to reload data from BE
      sessionStorage.setItem("updateRequired", true);
      this.props.setSubModalContent({});
      this.props.getContractsReport(this.props.auth.user.name).then(
        (res) => {
          this.setState({
            contracts: this.props.contractsReportData[JSON.parse(sessionStorage.getItem("isPayment"))][sessionStorage.getItem("key")].map(
              (contract) => {
                contract.name = findCustomerName(this.props.customers, contract);
                return contract;
              }
            ),
            showEditModal: false,
          });
          this.props.setLoading(false);
        },
        (err) => {
          console.log(err);
          this.props.setLoading(false);
        }
      );
    });
  };

  deleteContract = function (contract) {
    if (window.confirm("Are you sure you want to delete item?")) {
      this.props.deleteContract(contract._id).then((res) => {
        //deleteFromAllObjects()
        //deleteFromAllCustomers()
        //deleteFromAllSettlements()

        this.props.getContracts().then(
          (res) => {
            let contracts = res;

            this.setState({
              contracts,
            });
          },
          (err) => {
            console.log(err);
          }
        );
      });
    }
  };

  prolongContract = (contract) => {
    this.cloneContract(contract, "prolong");
  };

  cloneContract = function (contract, actionType) {
    let clonedContract = Object.assign({}, contract);

    clonedContract.contractNum += "_cloned";
    clonedContract.createdDate = new Date().getTime();
    delete clonedContract._id;
    delete clonedContract.__v;
    clonedContract.contractPhotos = [];

    if (actionType !== "prolong") {
      clonedContract.objects = [];
      clonedContract.customers = [];
      clonedContract.settlements = [];
    } else {
      this.changeDatesToNewContract(clonedContract);
    }

    this.props.addContract(clonedContract).then((res) => {
      if (actionType === "prolong") {
        this.addNewContractToCustomer(clonedContract, res);
        this.addNewContractToObjects(clonedContract, res);
        this.changeStatusToOldContractAndSaveIt(contract);
      }

      this.props.getContracts().then(
        (res) => {
          let filtered = res.filter((contr) => {
            let cYear = Number(contr.contractEndDate.substr(4));
            let cMonth = Number(contr.contractEndDate.substr(2, 2)) - 1;
            let cDay = Number(contr.contractEndDate.substr(0, 2));
            let tillDate = this.props.filter.to ? Date.now() < new Date(cYear, cMonth, cDay - this.props.filter.to) : true;

            return (
              Date.now() > new Date(cYear, cMonth, cDay - this.props.filter.from) &&
              tillDate &&
              contr.contractStatus !== "пролонгований" &&
              contr.contractStatus !== "недіючий"
            );
          });
          this.setState({
            contracts: filtered.map((contract) => {
              contract.name = findCustomerName(this.props.customers, contract);
              return contract;
            }),
          });
        },
        (err) => {
          console.log(err);
        }
      );
    });
  };

  addNewContractToCustomer(clonedContract, res) {
    clonedContract.customers.forEach((custId) => {
      let customerToUpdate = this.props.customers.filter((cust) => cust._id === custId);
      customerToUpdate.forEach((customer) => {
        customer.contracts.push(res._id);
        this.props.editCustomer(customer);
      });
    });
  }
  addNewContractToObjects(clonedContract, res) {
    clonedContract.objects.forEach((objId) => {
      let objectToUpdate = this.props.objects.filter((obj) => obj._id === objId);
      objectToUpdate.forEach((object) => {
        object.contracts.push(res._id);
        this.props.editObject(object);
      });
    });
  }

  changeStatusToOldContractAndSaveIt(oldContract) {
    oldContract.contractStatus = "пролонгований";
    this.saveContract(oldContract, "prolong");
  }

  changeDatesToNewContract(prolongedContract) {
    prolongedContract.contractEndDate =
      prolongedContract.contractEndDate.substring(0, 6) + (Number(prolongedContract.contractEndDate.substring(6)) + 1);
    prolongedContract.contractStartDate =
      prolongedContract.contractStartDate.substring(0, 6) + (Number(prolongedContract.contractStartDate.substring(6)) + 1);
  }

  render() {
    const filters = {
      startEnd: (filter, row) => row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
      includes: (filter, row) => {
        return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
      },
      matchSrtr: (filter, rows) => matchSorter(rows, filter.value, { keys: ["firstName"] }),
      fromStart: (filter, row) => {
        if (filter.value.length >= 10) {
          return (
            new Date(
              row._original[filter.id].substring(4),
              Number(row._original[filter.id].substring(2, 4)) - 1,
              row._original[filter.id].substring(0, 2)
            ) >= new Date(filter.value.substring(6), Number(filter.value.substring(3, 5)) - 1, filter.value.substring(0, 2))
          );
        } else {
          return true;
        }
      },
      tillEnd: (filter, row) => {
        if (filter.value.length >= 10) {
          return (
            new Date(
              row._original[filter.id].substr(4),
              Number(row._original[filter.id].substr(2, 2)) - 1,
              row._original[filter.id].substr(0, 2)
            ) <= new Date(filter.value.substr(6, 4), Number(filter.value.substr(3, 2)) - 1, filter.value.substr(0, 2))
          );
        } else {
          return true;
        }
      },
    };

    return (
      <>
        <EditContractModal
          isShown={this.state.showEditModal}
          saveContent={this.saveContract}
          closeEditModal={this.closeSubModal.bind(this)}
          closeSubModal={this.closeSubModal.bind(this)}
          openEditModal={this.openEditModal}
        ></EditContractModal>
        <AttachItemModal
          fromRow={this.state.row}
          contentType={this.state.attachItemModalViewType}
          saveContent={this.addItems}
          closeModal={this.closeAttachItemModal}
          showAttachItemModal={this.state.showAttachItemModal}
        ></AttachItemModal>
        <ReactTable
          data={this.state.contracts}
          filterable
          ref={this.selectTable}
          onFilteredChange={() => {
            this.setState({ filtered: this.selectTable.current.getResolvedState().sortedData.length });
          }}
          defaultFilterMethod={filters.includes}
          columns={[
            {
              Header: this.state.filtered,
              width: 40,
              filterable: false,
              Cell: (row) => {
                return row.index + 1 + ".";
              },
            },
            {
              Header: this.props.t("actions"),
              width: 175,
              accessor: "action",
              filterable: false,
              Cell: (row) => (
                <ButtonGroup size="small" variant="outlined" color="primary" aria-label="small outlined button group">
                  <Tooltip title="Edit" placement="top-start">
                    <Button
                      disabled={this.props.auth.user.role === "client"}
                      onClick={this.putDataToStoreAndOpenModal.bind(this, row.original)}
                    >
                      <i className="far fa-edit fa-lg"></i>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Clone" placement="top-start">
                    <Button disabled={this.props.auth.user.role === "client"} onClick={this.cloneContract.bind(this, row.original)}>
                      <i className="far fa-copy fa-lg"></i>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Prolong" placement="top-start">
                    <Button
                      disabled={this.props.auth.user.role === "client"}
                      style={{ color: "green" }}
                      onClick={this.prolongContract.bind(this, row.original)}
                    >
                      <i className="fa fa-redo fa-lg"></i>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top-start">
                    <Button
                      disabled={this.props.auth.user.role === "client"}
                      color="secondary"
                      onClick={this.deleteContract.bind(this, row.original)}
                    >
                      <i className="far fa-trash-alt fa-lg"></i>
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              ),
            },
            {
              Header: this.props.t("contract.contractStatus"),
              width: 200,
              id: "contractStatus",
              accessor: (d) => d.contractStatus,
              Cell: (props) => (
                <Select
                  value={props.row.contractStatus}
                  style={{ width: "100%" }}
                  onChange={this.handleStatusChange.bind(this, props)}
                  id={props.index + ""}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {CONTRACTDETAILS.find((contrDet) => contrDet.id === "contractStatus").options.map((contractStatusOption, ind) => (
                    <MenuItem key={ind} value={contractStatusOption}>
                      {contractStatusOption}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              Header: this.props.t("contract.type"),
              accessor: "insType",
            },
            {
              Header: this.props.t("contract.company"),
              width: 270,
              id: "insCompany",
              accessor: (d) => d.insCompany,
            },
            {
              Header: this.props.t("contract.contractNum"),
              width: 150,
              id: "contractNum",
              accessor: (d) => d.contractNum,
            },
            {
              Header: this.props.t("contract.contractStartDate"),
              id: "contractStartDate",
              width: 130,
              filterMethod: filters.fromStart,
              Cell: (props) => (
                <span>{props.original.contractStartDate ? addSlashesToString(props.original.contractStartDate) : null}</span>
              ),
            },
            {
              Header: this.props.t("contract.contractEndDate"),
              id: "contractEndDate",
              width: 130,
              filterMethod: filters.tillEnd,
              Cell: (props) => <span>{props.original.contractEndDate ? addSlashesToString(props.original.contractEndDate) : null}</span>,
            },
            {
              Header: this.props.t("contract.name"),
              id: "name",
              width: 300,
              accessor: (d) => d.name,
            },

            {
              Header: this.props.t("contract.insAmount"),
              id: "insAmount",
              width: 150,
              accessor: (d) => d.insAmount,
            },
            {
              Header: this.props.t("contract.insPremiya"),
              id: "insPremiya",
              width: 150,
              accessor: (d) => d.insPremiya,
            },
            {
              Header: this.props.t("contract.currency"),
              id: "currency",
              accessor: (d) => d.currency,
            },
            {
              Header: this.props.t("contract.insTariff"),
              id: "insTariff",
              width: 150,
              accessor: (d) => d.insTariff,
            },
            {
              Header: this.props.t("contract.insFranchise"),
              id: "insFranchise",
              accessor: (d) => d.insFranchise,
            },
            {
              Header: this.props.t("contract.insFranchiseType"),
              id: "insFranchiseType",
              width: 150,
              accessor: (d) => d.insFranchiseType, //% or UAH
            },
            {
              Header: this.props.t("contract.paymentScheme"), //1,2,3,4, doplata
              id: "paymentScheme",
              width: 150,
              accessor: (d) => d.paymentScheme,
            },
            {
              Header: this.props.t("contract.paymentAmount"),
              id: "paymentParts",
              width: 150,
              show: this.props.auth.user.role !== "client",
              accessor: (d) => d.paymentParts,
              Cell: (props) => (
                <div>
                  <span>
                    {Object.keys(props.original.paymentParts).reduce((acc, value, ind, arr) => {
                      if (props.original.paymentParts[value].paid) {
                        acc = acc + props.original.paymentParts[value].amount;
                      }
                      return acc;
                    }, 0)}
                  </span>
                </div>
              ),
            },
            {
              Header: this.props.t("contract.commissionPercentage"),
              id: "paymentParts",
              width: 150,
              show: this.props.auth.user.role !== "client",
              accessor: (d) => d.paymentParts,
              Cell: (props) => (
                <div>
                  <span>{props.original.paymentParts.first.commissionP}</span>
                </div>
              ),
            },
            {
              Header: this.props.t("contract.commissionCurrency"),
              id: "paymentParts",
              width: 150,
              show: this.props.auth.user.role !== "client",
              accessor: (d) => d.paymentParts,
              Cell: (props) => (
                <div>
                  <span>
                    {Object.keys(props.original.paymentParts).reduce((acc, value, ind, arr) => {
                      if (props.original.paymentParts[value].paid) {
                        acc = acc + props.original.paymentParts[value].commissionC;
                      }
                      return acc;
                    }, 0)}
                  </span>
                </div>
              ),
            },
            {
              Header: this.props.t("contract.contractManager"),
              id: "contractManager",
              width: 200,
              accessor: (d) => d.contractManager,
            },
            {
              Header: this.props.t("contract.contractAgent"),
              id: "contractAgent",
              accessor: (d) => d.contractAgent,
            },
            {
              Header: this.props.t("contract.contractSubagent"),
              id: "contractSubagent",
              accessor: (d) => d.contractSubagent,
            },
            {
              Header: this.props.t("contract.contractType"),
              id: "contractType",
              width: 200,
              accessor: (d) => d.contractType,
            },
          ]}
          defaultPageSize={20}
          className="-striped -highlight home-table"
          SubComponent={(row) => {
            return (
              <div className="subtable-block">
                <Tab.Container id="vertical-nav" defaultActiveKey="first">
                  <Row style={{ height: "100%" }}>
                    <Col style={{ width: 300, flex: "none" }}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="first">{this.props.t("contract.objects")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">{this.props.t("contract.Settlements")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">{this.props.t("contract.Customers")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">{this.props.t("contract.Documents")}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col className="subtable-right-panel">
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <ObjectsSubTable
                            objects={this.props.objects.filter((el) => row.original.objects.some((el2) => el2 === el._id))}
                            removeObject={this.removeItem.bind(this, "objects")}
                            row={row}
                          ></ObjectsSubTable>
                          {this.props.auth.user.role !== "client" && (
                            <div>
                              <Button variant="contained" onClick={this.handleAddNewClick.bind(this, "objects", row)} style={buttonStyle}>
                                <i className="fas fa-plus-square"></i>
                                {this.props.t("Add new")}
                              </Button>
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <SettlementsSubTable
                            settlements={this.props.settlements.filter((el) => row.original.settlements.some((el2) => el2 === el._id))}
                            removeObject={this.removeItem.bind(this, "settlements")}
                            row={row}
                          ></SettlementsSubTable>
                          {this.props.auth.user.role !== "client" && (
                            <div>
                              <Button variant="contained" onClick={this.handleAddNewClick.bind(this, "settlements")} style={buttonStyle}>
                                <i className="fas fa-plus-square"></i>
                                {this.props.t("Add new")}
                              </Button>
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <CustomersSubTable
                            customers={this.props.customers.filter((el) => row.original.customers.some((el2) => el2 === el._id))}
                            removeCustomer={this.removeItem.bind(this, "customers")}
                            row={row}
                          ></CustomersSubTable>
                          {this.props.auth.user.role !== "client" && (
                            <div>
                              <Button variant="contained" onClick={this.handleAddNewClick.bind(this, "customers", row)} style={buttonStyle}>
                                <i className="fas fa-plus-square"></i>
                                {this.props.t("Add new")}
                              </Button>
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          <div style={{ position: "relative" }}>
                            <div
                              className="photos-container"
                              style={{
                                textAlign: "center",
                                display: "inline-block",
                                position: "absolute",
                                width: 400,
                                top: 0,
                              }}
                            >
                              <FilesDragNDrop
                                objectData={{
                                  pref: row.original.contractNum,
                                  suf: row.original.insType,
                                  entity: { contract: row.original },
                                }}
                                docFolder={"contract"}
                                handleAddedImage={this.handleAddedImage.bind(this, row)}
                              ></FilesDragNDrop>
                            </div>
                            <div style={{ display: "inline-block", position: "relative", left: 400 }}>
                              {row.original.contractPhotos.map((fileName, ind) => {
                                let dir = {
                                  folder:
                                    "contract/" +
                                    row.original.contractNum
                                      .split("")
                                      .filter((el) => ["'", '"', "+", "/", ".", "*"].every((badS) => badS !== el))
                                      .join("") +
                                    "_" +
                                    row.original.insType,
                                  fileName: fileName,
                                };
                                return (
                                  <div
                                    key={ind}
                                    className="filesUploaded"
                                    style={{
                                      margin: "10px 0",
                                    }}
                                  >
                                    <a
                                      style={{
                                        color: "#033362",
                                        padding: "5px 30px 5px 5px",
                                        borderRadius: 10,
                                        border: "1px solid lightgray",
                                      }}
                                      href={URL + "/static/" + dir.folder + "/" + dir.fileName}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {dir.fileName}
                                    </a>
                                    <CancelIcon
                                      style={{
                                        position: "relative",
                                        top: -2,
                                        width: 20,
                                        right: 25,
                                        color: "#dc5a5a",
                                      }}
                                      onClick={this.deleteFile.bind(this, row.original, ind)}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            );
          }}
        />
      </>
    );
  }
}

ContractsTableForHome.propTypes = {
  contracts: PropTypes.array,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.loading,
  contracts: state.contracts.contractsList,
  contractsReportData: state.contracts.contractsReportData,
  objects: state.objects.objectsList,
  customers: state.customers.customersList,
  settlements: state.settlements.settlementsList,
});

export default connect(mapStateToProps, {
  getContractsReport,
  getContracts,
  addContract,
  deleteContract,
  setSubModalContent,
  editContract,
  addCustomer,
  addObject,
  setLoading,
  editObject,
  deleteObject,
  editCustomer,
  addLog,
})(withTranslation()(ContractsTableForHome));

function findCustomerName(customers, contract) {
  let foundCustomer = customers.find((ctmr) => ctmr._id === contract.customers[0]);
  return foundCustomer ? foundCustomer.name : "! NOT FOUND";
}
